import { useMemo } from "react";
import { API, toMovie } from "../imports/utils";
import { Json } from "../imports/statics";
import useAuth from "./auth-hook";

const args = {
  enabled: false,
  includeCategories: [],
};

function useTags(slug, props = { ...args }) {
  props = { ...args, ...props };
  const categorised = [];
  const { data: auth } = useAuth();

  const { data = [], isLoading } = API.Get(
    {
      path: `static/movie-tags?type=${slug}`,
      key: ["tags", slug],
    },
    props.enabled
  );

  const tags = useMemo(() => {
    if (!data?.map) {
      return [];
    }

    return data.map((tag) => ({
      ...tag,
      movies: tag.movies?.map((m) => toMovie(m)),
    }));
  }, [data]);

  if (props.includeCategories.length > 0) {
    Json.Categories.forEach((category) => {
      const included = props.includeCategories.includes(category.id);
      const { data: res = [] } = API.Get(
        {
          path: "movies",
          param: "type_id",
          value: category.id,
          key: `category-${category.id}`,
        },
        tags?.length > 0 && included && props.enabled
      );
      const exists = categorised?.find((cat) => cat.id === category.id);

      if (!exists && included) {
        categorised?.push({
          ...category,
          movies: res.map((movie) => toMovie(movie)),
          title: category.title.replaceAll("profile", auth?.profile?.name),
        });
      }
    });
  }

  return {
    fetching: props.includeCategories
      ? isLoading && categorised.length > 0
      : isLoading,
    tags: [...categorised, ...tags],
  };
}

export default useTags;
