import { useMutation, useQueryClient } from "react-query";
import { API, storage } from "../imports/utils";
import { useAuth, useModalContext, useToggle } from "../imports/hooks";

export default function useSignOut() {
  const client = useQueryClient();
  const { dispatch } = useAuth();
  const { dispatch: showProcess } = useModalContext();
  const { state, toggle } = useToggle();
  const { mutate, isLoading: signingOut } = useMutation(API.Post, {
    onSuccess: async (res) => {
      if (res.success) {
        storage.unSetData("memcid", storage.path.local);
        storage.unSetData("ctrl", storage.path.session);
        if (res.redirect?.indexOf("profile") < 0) {
          dispatch({
            type: "UNSET",
            payload: null,
          });
        }

        client.invalidateQueries("auth").finally(() => {
          showProgress(res);
        });
      } else {
        showProgress(res);
        toggle(res, true);
      }
    },
    onError: (err) => {
      toggle(err, true);
    },
  });

  function showProgress(content) {
    showProcess({
      payload: content?.success
        ? null
        : {
            content,
            slug: "Processing",
          },
      type: content?.success ? "HIDE" : "SHOW",
    });
  }

  function signOut(e) {
    if (e) {
      e.preventDefault();
    }

    showProgress(null);

    mutate({
      data: null,
      path: "accounts/logout",
    });
  }

  function signOutProfile(e) {
    e.preventDefault();

    showProgress(null);

    mutate({
      data: null,
      path: "accounts/logout-profile",
    });
  }

  return {
    state,
    signOut,
    signingOut,
    signOutProfile,
  };
}
