import React, { useEffect, useRef, useState, useMemo } from "react";
import { useModalContext } from "../../imports/hooks";
import Player from "@gumlet/react-hls-player";
import { useNavigate } from "react-router-dom";
import { PlayMovieButton } from "../../imports/components";

const args = {
  movie: {},
  children: null,
  className: "",
  isWatching: false,
  showTitle: false,
  defaultClick: true,
  maxWidth: "[196px]",
};

const MovieCard = (props = { ...args }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const { dispatch } = useModalContext();
  const [autoPlay, setAutoPlay] = useState(false);
  const {
    children,
    movie,
    isWatching,
    className,
    showTitle,
    defaultClick,
    maxWidth,
  } = {
    ...args,
    ...props,
  };

  const watched = useMemo(() => {
    const percent = (movie?.watched / ref.current?.duration) * 100;

    return percent > 0 ? percent : 0;
  }, [ref, movie?.watched]);

  function handleMovieClicked() {
    if (!defaultClick) {
      return;
    } else if (isWatching) {
      navigate(`/watch?tkn=${movie?.id}&ref=${Date.now()}`);
    } else {
      dispatch({
        type: "SHOW",
        payload: {
          reference: ref,
          content: movie,
          slug: "movie-info",
        },
      });
    }
  }

  function handleHover({ target }, entered = true) {
    if (!isWatching) {
      const video = document.querySelector("video");

      if (video && ref.current) {
        if (entered) {
          ref.current.src = movie?.trailers?.[0];
          setTimer(
            setTimeout(() => {
              video.pause();
              setAutoPlay(true);
              ref.current.play();
            }, 800)
          );
        } else {
          if (!video.paused) {
            video.play();
          }
          setAutoPlay(false);
          ref.current.load();
          ref.current.pause();
          ref.current.src = "";
          clearTimeout(timer);
        }
      }
    }
  }

  useEffect(() => {
    if (movie?.watched && ref.current) {
      ref.current.currentTime = movie.watched;
    }
  }, [movie]);

  return (
    <div
      onMouseEnter={handleHover}
      onClick={handleMovieClicked}
      onMouseLeave={(e) => handleHover(e, false)}
      className={`relative border border-gray-900 rounded max-w-${maxWidth} group/card transition-all duration-500 min-w-fit cursor-pointer ${className}`}
    >
      <Player
        muted={true}
        preload="none"
        playerRef={ref}
        autoPlay={autoPlay}
        poster={movie?.poster}
        className="object-cover max-h-full min-w-full rounded"
        src={isWatching ? movie?.content?.link : movie?.trailers?.[0]}
      />

      {isWatching && (
        <div className="absolute top-0 left-0 w-full px-2 min-h-full h-full">
          <div className="flex flex-col h-full py-2 items-center justify-between">
            <div className="flex flex-1 opacity-0 transition duration-500 group-hover/card:opacity-100 items-center space-x-2">
              <PlayMovieButton movieId={movie?.id} className="px-2 py-1">
                Resume
              </PlayMovieButton>
            </div>
            <div className="space-x-1 h-1 bg-white w-full rounded-full">
              <div
                style={{ maxWidth: `${watched}%` }}
                className={`bg-primary h-full rounded-full`}
              ></div>
            </div>
          </div>
        </div>
      )}

      <div
        className="absolute w-full h-full left-0 bottom-0"
        onClick={handleMovieClicked}
      >
        {showTitle && (
          <div className="flex z-20 items-end px-3 text-shadow-md font-semibold text-md">
            {movie?.title?.text}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default MovieCard;
