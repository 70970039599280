import React, { useMemo } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";

import { useAuth, useGenres } from "../../imports/hooks";
import { LinkButton } from "../../imports/components";
import { BiAlignLeft, BiGridAlt } from "react-icons/bi";
import Statics from "../../imports/statics";

const args = {
  title: "Movies",
  tag: "movie",
  onSuccess: () => {},
  setMovies: () => {},
  setCategories: () => {},
};

const FilterNav = (props = { ...args }) => {
  props = { ...args, ...props };
  const { params } = useAuth();
  const { genres } = useGenres({ type: props.tag });
  const homeUrl = useMemo(
    () => "/" + props.title.toLocaleLowerCase().replaceAll(" ", "-"),
    [props.title]
  );
  const selected = useMemo(() => {
    if (params?.has("rfn")) {
      return genres?.find((c) => c.slug === params.get("rfn"));
    }

    return null;
  }, [params, genres]);

  const sort = useMemo(() => {
    if (params.has("so")) {
      return Statics.Json.Sorts.find((s) => s.slug === params.get("so"));
    }

    return false;
  }, [params]);

  return (
    <div className="flex gap-2 flex-wrap items-center justify-between">
      <div
        className={`flex items-center justify-center sm:justify-start ${
          selected ? "gap-1 sm:gap-2" : "gap-4"
        }`}
      >
        {selected ? (
          <>
            <LinkButton
              href={homeUrl}
              className="text-sm text-shadow-sm sm:text-base text-gray-300"
            >
              {props.title}
            </LinkButton>
            <ChevronRightIcon className="w-4 text-gray-300" />
            <h3 className="text-xl sm:text-3xl font-bold text-shadow-sm">
              {selected.title ?? "Genre"}
            </h3>
          </>
        ) : (
          <>
            <h3 className="text-xl sm:text-3xl font-bold">{props.title}</h3>
            <Menu as="div" className="relative flex-">
              <Menu.Button className="bg-secondary/60 w-full flex gap-3 justify-between items-center border border-zinc-400 px-2 py-[.15rem] max-h-12">
                <span>Genre</span>
                <ChevronDownIcon className="h-4" />
              </Menu.Button>
              <Menu.Items className="absolute left-0 z-50 bg-secondary min-w-full sm:min-w-max">
                <div className="grid sm:grid-cols-3 gap-3 p-2">
                  {genres?.map((genre) => (
                    <LinkButton
                      key={genre.id}
                      replace={true}
                      href={`/movies?rfn=${genre.slug}`}
                      className="text-white text-start text-sm"
                    >
                      {genre.title}
                    </LinkButton>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </>
        )}
      </div>

      <div className="flex justify-center sm:justify-end col-span-1">
        <div className="flex h-fit max-h-min items-center bg-secondary/60 border">
          <LinkButton
            className="border-r px-3 py-1"
            href={`${homeUrl}${selected?.slug ? "?rfn=" + selected?.slug : ""}`}
          >
            <BiAlignLeft
              className={`text-base ${sort ? "text-zinc-400" : "text-white"}`}
            />
          </LinkButton>

          <div className="flex w-full items-center">
            <LinkButton
              replace={true}
              href={`${homeUrl}?${
                selected?.slug ? "rfn=" + selected?.slug + "&" : ""
              }so=yr`}
              className="px-3 py-1"
              data-bs-target="#filter"
            >
              <BiGridAlt
                className={`text-lg ${sort ? "text-white" : "text-zinc-400"}`}
              />
            </LinkButton>

            <Menu
              as="div"
              className={`collapse relative ${sort ? "show" : "hide"}`}
            >
              {({ close }) => (
                <>
                  <Menu.Button className="flex items-center gap-4 pr-3">
                    <small className="text-sm">{sort?.title}</small>
                    <ChevronDownIcon className="h-3" />
                  </Menu.Button>
                  <Menu.Items className="absolute space-y-1 right-0 z-50 min-w-fit bg-secondary px-2 py-1 text-sm border w-full">
                    {Statics.Json.Sorts.map((item, i) => (
                      <LinkButton
                        replace={true}
                        onClick={close}
                        href={`${homeUrl}?${
                          selected?.slug ? "rfn=" + selected?.slug + "&" : ""
                        }so=${item.slug}`}
                        key={`menu_${i}`}
                        className="truncate block text-zinc-300 hover:underline hover:text-white"
                      >
                        {item.title}
                      </LinkButton>
                    ))}
                  </Menu.Items>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterNav;
