import React from "react";
import { API } from "../../imports/utils";
import { usePost } from "../../imports/hooks";
import { Alert, Form, SubmitButton } from "../../imports/components";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { toFormData } from "axios";

const args = {
  enabled: false,
  setDialog: () => {},
  setResponse: () => {},
};

const AddCard = (props = { ...args }) => {
  props = { ...args, ...props };
  const { data = {}, isLoading } = API.Get(
    {
      key: "init-card",
      path: `subscriptions/card/init-card`,
    },
    props.enabled
  );

  return (
    !isLoading &&
    props.enabled && (
      <div className="px-4 pt-2 pb-4 text-black">
        <div className="mb-4 flex justify-between items-center">
          <h5 className="text-lg font-semibold">Add Card</h5>

          <button className="close btn-close">
            <span></span>
          </button>
        </div>
        <Elements
          stripe={loadStripe(data.key)}
          options={{
            appearance: {
              theme: "stripe",
              variables: {
                borderRadius: "0.15rem",
                focusBoxShadow: "none",
                colorPrimary: "#00862d",
              },
            },
          }}
        >
          <AddCardForm
            setDialog={props.setDialog}
            setResponse={props.setResponse}
          />
        </Elements>
      </div>
    )
  );
};

function AddCardForm({ setDialog = () => {}, setResponse = () => {} }) {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const {
    post,
    state,
    processing: posting,
    toggle,
  } = usePost("subscriptions/card/add", {
    toggleOnSuccess: false,
    clientKey: "payment-methods",
    onSuccess: (res) => onSuccess(res),
  });

  const options = {
    classes: {
      focus: "border-primary/80",
      base: "border p-2 border-secondary/80",
    },
    style: {
      base: {
        color: "#000",
        "::placeholder": {
          color: "#a0aec0",
        },
      },
    },
  };

  function onSuccess(res) {
    setDialog();
    setResponse(res);
  }

  async function handleSubmit(e) {
    if (!stripe || !elements) return;
    setProcessing(true);
    const { error, paymentMethod } = await stripe
      .createPaymentMethod({
        type: "card",
        billing_details: {
          name: e.target.name.value,
        },
        card: elements.getElement(CardNumberElement),
        card: elements.getElement(CardExpiryElement),
        card: elements.getElement(CardCvcElement),
      })
      .finally(() => setProcessing(false));

    if (error) {
      toggle({ message: error.message, success: false }, true, 6000);
      return;
    } else {
      post(
        null,
        toFormData({
          token: paymentMethod.id,
        })
      );
    }
  }

  return (
    <Form
      noValidate={true}
      onSubmit={handleSubmit}
      className="flex-col flex gap-4 text-black"
    >
      <fieldset className="flex-col flex">
        <label htmlFor="name" className="text-zinc-800">
          Name on Card
        </label>
        <input
          type="text"
          name="name"
          id="name"
          required={true}
          autoFocus={true}
          placeholder="Idrissa Barry"
          className="border outline-none px-2 py-1 text-[1rem] border-secondary/80 focus:border-primary/80 placeholder-[#a0aec0]"
        />
        <small className="feedback hidden text-rose-500">
          Name on card is required
        </small>
      </fieldset>

      <fieldset className="flex flex-col">
        <label htmlFor="name" className="text-zinc-800">
          Card Number
        </label>
        <CardNumberElement options={{ showIcon: true, ...options }} />
      </fieldset>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <fieldset className="flex flex-col">
          <label htmlFor="name" className="text-zinc-800">
            Expiration Date
          </label>
          <CardExpiryElement options={options} />
        </fieldset>

        <fieldset className="flex flex-col">
          <label htmlFor="name" className="text-zinc-800">
            CVC
          </label>
          <CardCvcElement options={{ ...options, placeholder: "000" }} />
        </fieldset>
      </div>

      {state && (
        <Alert color={state.success ? "blue" : "rose"}>{state.message}</Alert>
      )}

      <SubmitButton
        isProcessing={processing || posting}
        className="text-lg text-white mt-4"
      >
        Save Card
      </SubmitButton>
    </Form>
  );
}

export default AddCard;
