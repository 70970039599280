import React, { useState } from "react";
import { API } from "../../imports/utils";
import { usePost } from "../../imports/hooks";
import {
  CommonDialog,
  FloatingInput,
  Form,
  LinkButton,
  Loader,
  SubmitButton,
} from "../../imports/components";

const args = {
  data: {
    plan: {},
    plans: [],
    categories: [],
  },
  isSignUp: true,
  caption: "Start Membership",
};

function OrangeMoney(props = { ...args }) {
  props = { ...args, ...props };

  const { plan } = props.data;
  const [show, setShow] = useState(false);

  const { data: payment = {}, isLoading } = API.Get({
    key: ["orange-init", plan.id],
    path: `subscriptions/omoney/init?plan=${plan.id}&fallback=${
      props.isSignUp ? "signup/payments" : "account/payment"
    }`,
  });

  const { post, processing } = usePost("subscriptions/omoney", {
    redirect: false,
    toggleOnSuccess: false,
    onSuccess: paymentInit,
    clientKey: "pk_test_51J",
  });

  function paymentInit() {
    window.location.href = payment.payment_url;
  }

  return isLoading || !payment?.payment_url ? (
    <Loader />
  ) : (
    <>
      <div className="w-full max-w-xl flex flex-col justify-center items-center py-8 default-padding">
        <div className="text-start sm:text-center">
          <small>STEP {props.isSignUp ? "3 OF 3" : "2 of 2"}</small>

          <h2 className="flex text-truncate flex-wrap space-y-1 space-x-2 mb-4 text-xl sm:text-2xl lg:text-3xl items-center">
            <span>Pay with</span>{" "}
            <span className="text-orange-600 font-extrabold">Orange Money</span>
            {plan?.image && (
              <span>
                <img
                  width={50}
                  alt="orange_logo"
                  src={plan?.image}
                  className="object-fill max-h-full rounded-sm border border-orange-600 p-1"
                />
              </span>
            )}
          </h2>
        </div>

        <Form
          noValidate={true}
          onSubmit={(e) => post(e)}
          className="flex flex-col w-full space-y-5"
        >
          <fieldset className="space-y-3">
            <input type="hidden" name="plan" defaultValue={plan?.id} />
            <input
              type="hidden"
              name="action"
              defaultValue={props.isSignUp ? "create" : "renewal"}
            />
            <input
              type="hidden"
              name="ref"
              defaultValue={payment?.notif_token}
            />
            <input type="hidden" name="amount" defaultValue={payment?.amount} />

            {props.isSignUp && (
              <>
                <FloatingInput
                  id="first_name"
                  name="first_name"
                  required={true}
                  autoFocus={true}
                  placeholder="First Name"
                  feedback="First name is required"
                  inputClassName="bg-white border border-zinc-500 focus:border-primary"
                />

                <FloatingInput
                  id="last_name"
                  name="last_name"
                  required={true}
                  placeholder="Last Name"
                  feedback="Last name is required"
                  inputClassName="bg-white border border-zinc-500 focus:border-primary"
                />
              </>
            )}
          </fieldset>

          <div className="flex flex-wrap items-center justify-between space-y-3 w-full bg-zinc-100 p-4 border border-zinc-600 rounded-sm">
            <div className="">
              <span>
                {plan?.title} Plan (per {plan?.price?.period})
              </span>
              <div className="flex text-xl space-x-2 font-semibold">
                <span className="text-zinc-500">${plan?.price?.amount} </span>
                <span>&#8909;</span>
                <span>
                  SLE{Intl.NumberFormat().format(payment?.amount ?? 1)}
                </span>
              </div>
            </div>

            {props.isSignUp && (
              <LinkButton
                href="/signup/plan/choose"
                className="font-bold text-blue-500 hover:underline"
                state={{
                  ...props.data,
                  referrer: "/signup/payments/orange-money",
                }}
              >
                Change
              </LinkButton>
            )}
          </div>

          <div className="flex flex-col space-y-5">
            <button
              type="button"
              onClick={() => setShow(true)}
              className="font-bold max-w-fit text-secondary hover:text-primary hover:underline"
            >
              How to pay with Orange Money?
            </button>

            <small className="text-gray-700/80 text-justify">
              By clicking the button below, you agree to our{" "}
              <a
                href="/"
                className="text-blue-800 underline hover:text-green-600"
              >
                Terms of Use
              </a>
              ,{" "}
              <a
                href="/"
                className="text-blue-800 underline hover:text-green-600"
              >
                Privacy Statement
              </a>
              , and that you are over 18. Sierraflixx will notify you to
              continue your membership and charge the membership fee (currently
              ${plan?.price?.amount}/{plan?.price?.period?.toLowerCase()}) to
              every payment until you cancel. You may cancel at any time to
              avoid future notifications.
            </small>
          </div>

          <SubmitButton
            className="text-white uppercase"
            isProcessing={processing}
          >
            {props.caption}
          </SubmitButton>
        </Form>

        {!props.isSignUp && (
          <LinkButton
            href={-1}
            className="text-secondary font-semibold mt-3 hover:underline"
          >
            Return to previous step
          </LinkButton>
        )}
      </div>

      <CommonDialog
        show={show}
        bg="bg-black/80"
        title="How to pay with Orange Money"
        setShow={() => setShow(false)}
        className="bg-white text-black"
      >
        <small>
          Before you start, ensure that you have the stated balance in your
          Orange Money wallet then
        </small>

        <div className="sm:pl-3">
          <div className="my-2">
            <em className="text-orange-600">Using the Orange Money USSID</em>

            <ul className="list-decimal pl-3 sm:pl-8 pt-1 text-sm">
              <li className="list-item">
                Dial{" "}
                <a
                  href="tel: %23144*3*12%23"
                  className="hover:underline text-blue-400"
                >
                  #144*3*12#
                </a>{" "}
                on your phone
              </li>
              <li className="list-item">
                Reply with your<strong> Orange Money PIN</strong>
              </li>
              <li className="list-item">
                Wait for your <strong>6 digits</strong> code from Orange Money
              </li>
              <li className="list-item">
                Click the <strong>START MEMBERSHIP</strong> button below: you
                will be redirected to the orange money platform
              </li>
              <li className="list-item">
                Enter your <strong>Phone number</strong> and the{" "}
                <strong>Payment code</strong> you received in the fields
                provided
              </li>
              <li className="list-item">
                Click the <strong>Confirm</strong> button to complete your
                payment
              </li>
            </ul>
          </div>

          <div className="my-2">
            <em className="text-orange-600">Using the Orange Money App</em>
            <br />
            <small className="text-gray-600">
              Note that you need the Orange Money app to use this method. If you
              don't have it, you can download it from the App Store or Google
              Play store to continue.
            </small>
            <ul className="list-decimal pl-3 sm:pl-8 pt-1 text-sm">
              <li className="list-item">
                Click the <strong>START MEMBERSHIP</strong> button below: you
                will be redirected to the orange money platform
              </li>
              <li className="list-item">
                Click the <strong>Use QR Code</strong> option in the orange
                money platform
              </li>
              <li className="list-item">
                Start the OrangeMoney App on your device
              </li>
              <li className="list-item">
                Tap the <strong>Pay a merchant</strong> button
              </li>
              <li className="list-item">
                Select the <strong>With a QR Code</strong> option
              </li>
              <li className="list-item">
                Scan the <strong>QR Code</strong> revealed in the orange money
                platform
              </li>
            </ul>
          </div>

          <p>
            After payment completed successfully, click the{" "}
            <strong className="text-orange-600">
              To go back to merchant's website
            </strong>{" "}
            link on the top left of the orange money platform to continue with
            your account setup.
          </p>
        </div>
      </CommonDialog>
    </>
  );
}

export default OrangeMoney;
