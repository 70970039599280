import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Loader, Player } from "../../imports/components";
import { API, baseURL } from "../../imports/utils";

function Watch() {
  const [params] = useSearchParams();
  const movieId = useMemo(() => params.get("tkn"), [params]);
  const { data: watched = {} } = API.Get(
    {
      path: `profiles/get-watched?movie_id=${movieId}`,
    },
    movieId !== undefined
  );
  const { data: movie, isLoading: fetching } = API.Get({
    key: ["movie", movieId],
    path: `movies/watch?id=${movieId}`,
  });

  return fetching ? (
    <Loader />
  ) : (
    <Player
      className="animate__animated animate__fadeIn"
      movie={{
        ...movie,
        poster: baseURL + movie.poster,
        src: baseURL + movie.src,
      }}
      duration={watched?.duration ?? 1}
    />
  );
}

export default Watch;
