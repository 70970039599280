import { useContext } from "react";
import { ModalContext } from "../imports/contexts";
export default function useModalContext() {
  const context = useContext(ModalContext);

  if (!context) {
    throw Error("ModalContext can not be used outside of its provider");
  }

  return context;
}
