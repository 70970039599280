import React, { createContext, useEffect } from "react";
import { API, storage } from "../imports/utils";
import { useQueryClient } from "react-query";
import { useMemo } from "react";
import { ConnectionError } from "../imports/components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";

const args = {
  redirect: "/",
  message: "",
  uid: null,
  data: {},
  success: false,
  status_code: null,
  authenticated: false,
  authenticating: false,
  navigate: () => {},
  pathname: "",
  search: "",
  params: {},
};

export const AuthContext = createContext(args);

export default function AuthContextProvider({ children }) {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [params] = useSearchParams();
  const { pathname, search } = useLocation();
  const logged = storage.getData("mem", storage.path.local);

  const { data, isLoading } = API.Get({
    init: logged ?? args,
    key: "auth",
    path: "accounts/get-logged",
  });

  const auth = useMemo(() => {
    const user = {
      ...data,
      navigate,
      pathname,
      search,
      params,
      success: data?.message === "success",
    };

    storage.setData("mem", user, storage.path.local);

    return user;
  }, [data, navigate, pathname, search, params]);

  function dispatch() {
    client.invalidateQueries("auth");
  }

  const authenticate = useCallback(
    (interval = null) => {
      if (auth.authenticated) {
        client.invalidateQueries("auth").then(() => {
          if (!auth.authenticated) {
            if (interval) clearInterval(interval);
            navigator(auth.redirect, { replace: true });
          }
        });
      }
    },
    [auth, client]
  );

  useEffect(() => {
    let interval = null;
    const routes = ["/browse", "/account", "/watch"];

    if (routes.includes(pathname)) {
      authenticate(interval);
    }

    interval = setInterval(() => {
      authenticate(interval);
    }, 15000);

    return () => clearInterval(interval);
  }, [pathname, authenticate]);

  return (
    <AuthContext.Provider value={{ ...auth, dispatch }}>
      {data?.status === 500 ? <ConnectionError /> : !isLoading && children}
    </AuthContext.Provider>
  );
}
