import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth, usePost } from "../imports/hooks";
import {
  AccountNavigation,
  Alert,
  Footer,
  Form,
  LinkButton,
  PhoneInput,
  SubmitButton,
} from "../imports/components";
import { Images } from "../imports/statics";
import { getPhoneNumber } from "../imports/utils";

function ForgotPassword() {
  const [code, setCode] = useState("");
  const { authenticated, redirect } = useAuth();

  const { post, processing, state } = usePost("accounts/forget-password", {
    delay: 8000,
    setState: true,
    redirect: true,
    replace: false,
    toggleOnSuccess: false,
    successCodes: [201],
  });

  const handleLogin = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const uid = data.get("email_or_phone");

    if (!isNaN(uid)) {
      data.set("email_or_phone", getPhoneNumber(code, uid));
    }

    post(e, data);
  };

  return authenticated ? (
    <Navigate to={redirect} replace={true} />
  ) : (
    <div className="flex flex-col min-h-screen w-full">
      <AccountNavigation
        bg="transparent"
        className="sm:bg-gradient-top text-primary sm:!border-0"
        useLink={true}
      />

      <div className="flex flex-col min-w-full max-w-full min-h-screen relative bg-white text-black sm:text-white sm:!bg-radial-gradient">
        <img
          src={Images.ForgotPassword}
          alt="bg_image"
          className="absolute -z-10 min-h-full top-0 object-cover max-h-full min-w-full"
        />
        <div className="min-w-full max-w-full flex-1 flex flex-col justify-center items-center pt-20 pb-5">
          <div className="px-4 py-6 sm:px-14 sm:py-12 max-w-[30rem] w-full sm:bg-white text-black">
            <div className="mb-6">
              <h4 className="text-4xl font-semibold mb-2">Forgot Password</h4>
              <p className="text-lg mb-12">You can reset your password here.</p>

              <p>
                We will text you a verification code to reset your password.
                Message and data rates may apply.
              </p>
            </div>
            {state && (
              <Alert color="rose" className="mb-2">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: state.message }}
                ></p>
              </Alert>
            )}
            <Form onSubmit={handleLogin}>
              <div className="space-y-6 mb-6">
                <PhoneInput
                  autoFocus={true}
                  name="email_or_phone"
                  defaultValue=""
                  setCode={setCode}
                  required={true}
                  feedback="Please enter a valid phone number."
                />
              </div>

              <SubmitButton
                className="text-white !bg-secondary capitalize"
                isProcessing={processing}
              >
                Text Me
              </SubmitButton>

              <div className="mt-8 border-b pb-4 border-gray-400">
                <p className="text-blue-600 font-semibold">
                  You can't remember your phone number?
                </p>
                <p className="text-sm">
                  Don't worry, kindly{" "}
                  <a
                    className="text-green-600 font-semibold"
                    href="mailto: support@sierraflixx.net"
                  >
                    email
                  </a>{" "}
                  or{" "}
                  <a
                    className="text-green-600 font-semibold"
                    href="tel: +23299942156"
                  >
                    call
                  </a>{" "}
                  our customer support for instructions on how to recovery your
                  account from below:
                </p>
                <p className="text-sm text-primary mt-2">
                  <em>
                    <a href="mailto: support@sierraflixx.net">
                      support@sierraflixx.net
                    </a>
                  </em>
                  <span className="text-black mx-2 font-bold">|</span>
                  <em>
                    <a href="tel: +23299942156">+232 99 942-156</a>
                  </em>
                </p>
              </div>

              <div className="mt-4 justify-center flex gap-x-2">
                <span className="text-gray-800">New to Sierraflixx?</span>
                <LinkButton
                  href="/signup"
                  className="text-secondary font-semibold hover:text-primary"
                >
                  Sign Up Now
                </LinkButton>
              </div>
            </Form>
          </div>
        </div>
        <Footer className="mt-auto sm:bg-black-50 max-w-full" />
      </div>
    </div>
  );
}

export default ForgotPassword;
