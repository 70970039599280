import React from "react";
import { useGenres } from "../../imports/hooks";
import { Banner, Header, Loader, MovieSlider } from "../../imports/components";
import { Json } from "../../imports/statics";

function Browse() {
  const cats = Json.Categories.map((c) => c.id);
  const { genres, fetching } = useGenres({
    includeMovies: true,
    includeCategories: cats,
  });

  return (
    <>
      <Header />

      {fetching ? (
        <Loader isMovies={true} className="mt-14 default-padding" />
      ) : (
        <Banner>
          {genres.map((genre) => {
            return <MovieSlider key={genre.id} genre={genre} />;
          })}
        </Banner>
      )}
    </>
  );
}

export default Browse;
