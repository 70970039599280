import { usePost } from "../imports/hooks";
import { signUpSuccessCodes } from "../imports/utils";

function useSignIn() {
  const posted = usePost("accounts/login", {
    delay: 15000,
    redirect: true,
    onSuccess: () => {},
    successCodes: signUpSuccessCodes,
  });

  return {
    signIn: posted.post,
    signedIn: posted.state,
    toggleSignIn: posted.toggle,
    signingIn: posted.processing,
  };
}

export default useSignIn;
