import React, { useMemo, useState } from "react";
import usePost from "../../hooks/post-hook";
import {
  AccountNavigation,
  Alert,
  ChangePlan,
  Loader,
  SubmitButton,
} from "../../imports/components";
import API from "../../utils/api";
import { CreditCardIcon } from "@heroicons/react/outline";
import { toDate } from "../../imports/utils";

function RestartMembership() {
  const [newPlan, setNewPlan] = useState(null);
  const [showChangePlan, setShowChangePlan] = useState(false);

  const { data: subscription = {}, isLoading } = API.Get({
    path: "subscriptions/current",
    key: "sub-logged",
  });

  const plan = useMemo(() => {
    return subscription.plan ?? {};
  }, [subscription]);

  const method = useMemo(() => {
    return subscription.payment?.methods?.[0] ?? {};
  }, [subscription]);

  const { post, state, processing } = usePost("subscriptions/restart", {
    redirect: true,
    successCode: 200,
    clientKey: "auth",
  });

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col min-h-screen w-full bg-white text-black">
      <AccountNavigation bg="white" useLink={true} />

      <div className="flex animate__animated animate__fadeIn flex-col min-w-full max-w-full min-h-screen">
        <div className="min-w-full max-w-full flex-1 flex flex-col items-center pt-20 pb-5">
          <div className="w-full max-w-2xl flex flex-col justify-center py-8 default-padding">
            {state && (
              <Alert color="rose" className="mb-2">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: state.message }}
                ></p>
              </Alert>
            )}

            <div className="mb-4">
              <small>STEP 2 OF 2</small>
              <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl">
                Restart your Membership
              </h2>
              <p className="text-[.9rem]">
                We are glad to have you back! A lot has changed since you last.
              </p>
            </div>

            <p className="mb-1">
              Please confirm your plan and your payment method below.
            </p>

            <div className="bg-gray-200 py-2 px-3 text-bg-light justify-content-between text-start mb-4">
              <div className="grid grid-cols-1 sm:grid-cols-4 items-end justify-between gap-5 border-b border-secondary/30 py-2">
                <div className="flex flex-col sm:col-span-3">
                  <div className="flex-col flex">
                    <span className="text-zinc-500 font-semibold">
                      Current plan (
                      <span className="text-secondary">{plan?.title}</span>)
                    </span>
                    <span className="text-2xl font-semibold">
                      <span>${plan?.price?.amount}</span>
                      <small>/{plan?.price?.period?.toLowerCase()}</small>
                    </span>
                  </div>

                  {newPlan && (
                    <div className="flex flex-col border-t border-secondary/30 mt-2 pt-2">
                      <span className="text-zinc-500 font-semibold">
                        New plan (
                        <span className="text-secondary">{newPlan?.title}</span>
                        )
                      </span>
                      <span className="text-2xl font-semibold">
                        <span>${newPlan?.price?.amount}</span>
                        <small>/{newPlan?.price?.period?.toLowerCase()}</small>
                      </span>
                    </div>
                  )}
                </div>

                {subscription?.payment_type === "card" &&
                  subscription?.is_expired && (
                    <div className="flex justify-start sm:justify-end">
                      <button
                        className="border border-secondary px-3 py-1 w-full h-fit sm:w-fit bg-zinc-100 font-semibold"
                        onClick={() => setShowChangePlan(true)}
                      >
                        Change Plan
                      </button>
                    </div>
                  )}
              </div>

              <div className="flex flex-col border-b border-secondary/30 py-2">
                <span className="text-zinc-500 font-semibold">
                  Current payment method
                </span>
                <span className="text-2xl font-semibold capitalize">
                  {subscription?.payment_type?.replaceAll("-", " ")}
                </span>
                {subscription?.payment_type === "card" && (
                  <div className="flex space-x-2">
                    <CreditCardIcon className="w-6" />
                    <span>•••• •••• ••••</span>
                    <span>
                      {method?.card
                        ? method?.card?.last_four
                        : method?.last_four}
                    </span>
                  </div>
                )}
                <small className="capitalize">
                  {method.card?.brand ?? `${subscription?.payment_type} api`}
                </small>
              </div>

              <div className="flex flex-col py-2">
                <span className="text-zinc-500 font-semibold">
                  Current subscription
                </span>
                <span className="text-2xl font-semibold">
                  {subscription?.is_expired ? "Expired on" : `Active until`}{" "}
                  {toDate(subscription?.expires_at, {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </span>

                {subscription.is_expired && (
                  <small className="text-rose-500 font-semibold">
                    {subscription.payment_type === "card"
                      ? "Your subscription will be renewed automatically."
                      : `You will need to renew your subscription using the ${subscription.payment_type?.replaceAll(
                          "-",
                          " "
                        )} option.`}
                  </small>
                )}
              </div>
            </div>

            <small className="text-gray-700/80">
              By checking the button below, you agree to our{" "}
              <a
                href="/"
                className="text-secondary underline hover:text-green-600"
              >
                Terms of Use
              </a>
              ,{" "}
              <a
                href="/"
                className="text-secondary underline hover:text-green-600"
              >
                Privacy Statement
              </a>
              , and that you are over 18. Sierraflixx will automatically
              continue your membership and charge the membership fee (currently
              ${plan.price?.amount}/{plan.price?.period?.toLowerCase()}) to your
              payment method until you cancel. You may cancel at any time to
              avoid future charges.
            </small>

            <form onSubmit={post}>
              <input
                type="hidden"
                name="sid"
                defaultValue={subscription.id}
                required
              />
              {newPlan && (
                <input
                  type="hidden"
                  name="plan"
                  defaultValue={newPlan.id}
                  required
                />
              )}

              <SubmitButton
                size="lg"
                isProcessing={processing}
                className="w-100 mt-2 text-white"
              >
                START MEMBERSHIP
              </SubmitButton>
            </form>
          </div>
        </div>
      </div>

      {subscription?.payment_type === "card" && subscription?.is_expired && (
        <ChangePlan
          show={showChangePlan}
          current={subscription}
          setShow={setShowChangePlan}
          setNew={(_new) => setNewPlan(_new?.id === plan.id ? null : _new)}
        />
      )}
    </div>
  );
}

export default RestartMembership;
