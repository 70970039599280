import { useContext } from "react";
import { AuthContext } from "../imports/contexts";
export default function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("AuthContext can not be used outside of its provider");
  }

  return context;
}
