import React, { useState } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { getPhoneNumber } from "../../imports/utils";
import { usePost } from "../../imports/hooks";
import {
  AccountNavigation,
  PhoneInput,
  Form,
  SubmitButton,
  LinkButton,
  Alert,
} from "../../imports/components";

const args = {
  data: {
    auth: {},
    message: "",
  },
};

function ChangePhone(props = { ...args }) {
  const { data } = { ...args, ...props };
  const [code, setCode] = useState("");
  const { post, processing, state } = usePost("accounts/change-phone", {
    delay: 8000,
    redirect: false,
    toggleOnSuccess: true,
    successCodes: [402, 202, 406],
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    data.set("phone", getPhoneNumber(code, data.get("phone")));

    post(null, data);
  };

  return (
    <div className="animate__animated animate__fadeIn flex flex-col min-h-screen w-full bg-gray-200 justify-center">
      <AccountNavigation bg="black" showButton={false} showProfile={true} />

      <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
        <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-5 px-4">
          <div className="w-full space-y-4 max-w-lg md:px-12 pt-8">
            {state && state.success ? (
              <div className="flex flex-col items-center justify-center bg-white shadown-md py-3 rounded-md">
                <BiCheckDouble className="text-primary rounded-full border-primary border-4 p-1 text-6xl" />
                <div className="mt-6 text-center">
                  <p className="text-xl font-semibold">{state.message}</p>
                  <p className="mb-12">
                    Signing in will require your new phone number
                  </p>

                  <LinkButton
                    href="/account"
                    state={data?.uid}
                    className="text-sm uppercase border px-4 py-2 border-secondary font-semibold bg-secondary text-white rounded"
                  >
                    return to account
                  </LinkButton>
                </div>
              </div>
            ) : (
              <>
                <div className="">
                  <h4 className="font-extrabold text-2xl">Change Phone</h4>
                  <p className="text-base">
                    Enter the new phone number to replace the existing
                  </p>
                </div>

                {state && (
                  <Alert color="rose" className="mb-2">
                    <p
                      className="m-0"
                      dangerouslySetInnerHTML={{ __html: state.message }}
                    ></p>
                  </Alert>
                )}

                <Form
                  onSubmit={handleFormSubmit}
                  noValidate={true}
                  className="space-y-4"
                >
                  <input
                    type="hidden"
                    name="auth"
                    defaultValue={data.auth}
                    required={true}
                  />
                  <input
                    type="hidden"
                    name="msg"
                    defaultValue={data.message}
                    required={true}
                  />

                  <PhoneInput
                    required={true}
                    autoFocus={true}
                    placeholder="New phone number"
                    setCode={setCode}
                    feedback="Please enter a valid phone number."
                    defaultValue={state?.phone || ""}
                    inputClassName="border border-zinc-300 focus-within:border-primary"
                  />

                  <SubmitButton
                    isProcessing={processing}
                    className="text-white py-3"
                  >
                    Submit
                  </SubmitButton>
                </Form>
              </>
            )}
          </div>
          <p>
            Have a change of mind?{" "}
            <LinkButton href="/account" className="text-blue-500 font-semibold">
              Return to account
            </LinkButton>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChangePhone;
