import React, { useEffect, useRef, useState } from "react";
import ReactHLSPlayer from "@gumlet/react-hls-player";
import { useNavigate } from "react-router-dom";
import { usePost } from "../../imports/hooks";
import { isMobile } from "react-device-detect";
import { Images } from "../../imports/statics.js";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { API } from "../../imports/utils";
import { LinkButton } from "../../imports/components";

const { Spinner } = Images;

const args = {
  movie: {
    title: "",
    src: "",
    poster: "",
  },
  className: "",
  duration: 1,
};

const VideoPlayer = (props = { ...args }) => {
  const { movie, className } = { ...args, ...props };

  const navigate = useNavigate();
  const [isDelay, setIsDelay] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = React.useState(false);
  const [paused, setPaused] = React.useState(false);
  const [sliderDisplay, setSliderDisplay] = useState("none");
  const [showTitle, setShowTitle] = useState(false);

  // play & pause
  const playPauseBtnRef = useRef(null);
  // video & container
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  // timeline
  const progressBarRef = useRef(null);
  const thumbRef = useRef(null);
  const durationTimeRef = useRef(null);
  const currentTimeRef = useRef(null);
  // forward & backward time
  const forwardBtnRef = useRef(null);
  const backwardBtnRef = useRef(null);
  const fullScreenExitRef = useRef(null);
  // mode view
  const fullScreenOpenRef = useRef(null);
  // volume
  const volumeRef = useRef(null);
  const sliderRef = useRef(null);
  const rateSpeedRef = useRef(null);
  const centerIconRef = useRef(null);
  // preview
  const previewImgRef = useRef(null);
  const upTimeRef = useRef(null);
  const videoTitleRef = useRef(null);
  // information key
  const infoRef = useRef(null);

  const loaderRef = useRef(null);
  const { post } = usePost("profiles/set-watched", {
    redirect: false,
    clientKey: "watching",
    toggleOnSuccess: false,
  });

  function logWatched() {
    if (videoRef.current) {
      const data = new FormData();
      const watched = Math.round(videoRef.current.currentTime);

      data.set("movie_id", movie?.id);
      data.set("duration", watched);

      post(null, data);
    }
  }

  function togglePlay() {
    const video = videoRef.current;
    const timeout = setTimeout(() => {
      setIsDelay(true);
    }, Math.pow(60, 3));

    if (video.paused || video.ended) {
      setPlaying(true);
      if (isMobile) {
        fullScreenHandler();
      }
      video.play();
      clearTimeout(timeout);
      setIsDelay(false);
      logWatched();
    } else {
      setPlaying(false);
      video.pause();
    }
  }

  const handlePlayed = async () => {
    setPlaying(true);
    setPaused(false);
  };

  // progress bar
  function updateTime(e) {
    const video = videoRef.current;
    const thumb = thumbRef.current;

    const numPercent =
      e.nativeEvent.offsetX / progressBarRef?.current?.offsetWidth;
    const percent = numPercent * 100;
    video.currentTime = numPercent * video.duration;
    thumb?.style.setProperty("--progress", `${percent}%`);
  }

  function getTimeCurrent(e) {
    e.preventDefault();
    const progressBar = progressBarRef.current;
    const previewImg = previewImgRef.current;
    const upTime = upTimeRef.current;
    const video = videoRef.current;

    const leftTime = e.nativeEvent.offsetX;
    const length = progressBar.offsetWidth;
    const percent = e.nativeEvent.offsetX / length;
    const leftPreview = length * 0.06;
    const rightPreview = length * 0.954;
    upTime.style.setProperty("--upTime", `${leftTime}px`);
    if (percent < 0.053) {
      previewImg.style.setProperty("--upTime", `${leftPreview}px`);
    } else if (percent > 0.95) {
      previewImg.style.setProperty("--upTime", `${rightPreview}px`);
    } else {
      previewImg.style.setProperty("--upTime", `${leftTime}px`);
    }
    const nowTime = percent * video.duration;
    upTime.textContent = formatTime(nowTime);
    progressBar.style.setProperty("--preview", `${leftTime}px`);
  }

  const handleTimeUpdate = () => {
    if (currentTimeRef.current) {
      currentTimeRef.current.textContent = formatTime(
        videoRef.current.currentTime
      );
      let uptime =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      thumbRef.current.style.setProperty("--progress", `${uptime}%`);
      progressBarRef.current.style.setProperty("--progress", `${uptime}%`);
      // logWatched();
    }
  };

  // digit round time
  const addZeroToTime = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2,
  });

  // format time
  function formatTime(t) {
    const hours = Math.floor(t / 3600);
    const minutes = Math.floor(t / 60) % 60;
    const seconds = Math.floor(t % 60);
    if (hours === 0) {
      return `${addZeroToTime.format(minutes)}:${addZeroToTime.format(
        seconds
      )}`;
    } else {
      return `${hours}:${addZeroToTime.format(minutes)}:${addZeroToTime.format(
        seconds
      )}`;
    }
  }

  // Time : forward & backward
  function skipForward() {
    let video = videoRef.current;
    video.currentTime += 5;
    forwardBtnRef.current.classList.remove("hidden");
    setTimeout(() => {
      forwardBtnRef.current.classList.add("hidden");
    }, 250);
  }
  function skipBackward() {
    let video = videoRef.current;
    video.currentTime -= 5;
    backwardBtnRef.current.classList.remove("hidden");
    setTimeout(() => {
      backwardBtnRef.current.classList.add("hidden");
    }, 250);
  }

  // view : button full screen
  function fullScreenHandler() {
    if (document.fullscreenElement == null) {
      containerRef.current.requestFullscreen();
      fullScreenOpenRef.current.classList.add("hidden");
      fullScreenExitRef.current.classList.remove("hidden");
    } else {
      document.exitFullscreen();
      fullScreenOpenRef.current.classList.remove("hidden");
      fullScreenExitRef.current.classList.add("hidden");
    }
  }

  const handleLoaderWaiting = (e) => {
    console.log("waiting");
    loaderRef.current.classList.remove("hidden");
  };

  const handleProgress = (event) => {
    if (event.target.duration > 0) {
      const percent =
        (event.target.buffered.end(0) / event.target.duration) * 100;
      setProgress(percent);
    }
  };

  const handleLoaderCanPlayThrough = () => {
    // Hide loader when video can play through without buffering
    loaderRef.current.classList.add("hidden");
  };

  const handleVolumeChange = (e) => {
    videoRef.current.volume = e.target.value;
    videoRef.current.muted = e.target.value === 0;
  };

  function handleVolumeChangeVideo() {
    const valueVolume = videoRef.current.volume;
    let showVolume;
    if (videoRef.current.muted) {
      showVolume = 0;
    } else {
      showVolume = (valueVolume * 100).toFixed(0);
    }
  }
  // volume : mute
  const muteHandler = () => {
    videoRef.current.muted = !videoRef.current.muted;
    // video.muted = !video.muted;
  };

  const volumeChange = () => {
    handleVolumeChangeVideo();
    volumeRef.current.value = videoRef.current.volume;
    let volumeLevel;
    if (videoRef.current.muted || videoRef.current.volume === 0) {
      volumeRef.current.value = 0;
      volumeLevel = "muted";
    } else if (videoRef.current.volume >= 0.5) {
      volumeLevel = "high";
    } else {
      volumeLevel = "low";
    }
    containerRef.current.dataset.volumeLevel = volumeLevel;
  };

  //  change volume video with arrow up & down
  videoRef.volume = 0.5;
  function volumeUp() {
    videoRef.current.volume += 0.1;
  }
  function volumeDown() {
    videoRef.current.volume -= 0.1;
  }

  let time1;
  const hideButton = () => {
    if (videoRef.current?.paused) return;
    time1 = setTimeout(() => {
      sliderRef.current?.classList.add("opacity-0");
      videoTitleRef.current?.classList.add("opacity-0");
      // infoBtnRef.current?.classList.add("opacity-0");
      infoRef.current?.classList.add("hidden");
    }, 3000);
  };

  hideButton();

  function handleMouseMove() {
    if (sliderRef.current) sliderRef.current.classList.remove("opacity-0");
    if (videoTitleRef.current)
      videoTitleRef.current.classList.remove("opacity-0");
    clearTimeout(time1);
    hideButton();
  }

  function handleSpeedClick(event) {
    console.log("click");
    const clickedItem = event.target;
    const items = rateSpeedRef.current.querySelectorAll("li");
    items.forEach((item) => {
      item.classList.remove("active");
    });
    clickedItem.classList.add("active");
    videoRef.current.playbackRate = clickedItem.dataset.speed;
  }

  const handleClick = () => {
    console.log("click");
    // rateSpeedRef.current.classList.toggle("hidden");
    if (rateSpeedRef.current.classList.contains("hidden")) {
      console.log("clickp1");
      rateSpeedRef.current.classList.remove("hidden");
    } else {
      rateSpeedRef.current.classList.add("hidden");
    }
  };

  let showRate;

  const showRateMouseLeave = () => {
    showRate = setTimeout(() => {
      rateSpeedRef.current.classList.add("hidden");
    }, 2000);
  };

  const showRateMouseMove = () => {
    clearTimeout(showRate);
  };

  // icon center
  const handleVideoPlaying = () => {
    setTimeout(() => {
      setShowTitle(false);
    }, 3000);

    //centerIconRef.current.style.opacity = 0;
  };

  const handleVideoPause = () => {
    console.log("pause");
    //centerIconRef.current.style.opacity = 1;
    // playPauseBtnRef.current?.classList.toggle("playing");
    setPlaying(false);
    setPaused(true);
  };

  useEffect(() => {
    const logger = setInterval(() => {
      logWatched();
    }, 15 * 1000);
    if (videoRef.current) {
      videoRef.current.currentTime = props.duration;
    }
    function handleKeyPress(event) {
      if (event.key === " ") {
        // Backspace key
        if (videoRef.current.paused) {
          videoRef.current.play();
          setPlaying(true);
          setPaused(false);
        } else {
          videoRef.current.pause();
          setPlaying(false);
          setPaused(true);
        }
      }
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      clearInterval(logger);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleShowVolume = () => {
    setSliderDisplay("block");
    progressBarRef.current.classList.add("hidden");
    currentTimeRef.current.classList.add("hidden");
  };

  const handleHideVolume = () => {
    setSliderDisplay("none");
    progressBarRef.current.classList.remove("hidden");
    currentTimeRef.current.classList.remove("hidden");
  };
  function handleReturn() {
    logWatched();
    navigate(-1, { replace: true });
  }

  function handleLoadedMetadata() {
    setShowTitle(true);
  }

  return (
    <div className={`h-screen bg-black ${className}`}>
      {/*  control main*/}
      <div className="m-0 flex h-screen w-screen items-center justify-center  ">
        {/* main */}
        <div
          onMouseMove={handleMouseMove}
          ref={containerRef}
          className="container-video relative h-full w-full overflow-hidden "
          data-volume-level="high"
        >
          {!isDelay && (
            <>
              {/*Backward Button*/}

              <div className="absolute left-2 top-2 z-50 rounded-sm transition-opacity duration-300 md:left-6 md:top-5 md:rounded-xl">
                <LinkButton
                  onClick={handleReturn}
                  className="hover:scale-110 duration-200"
                >
                  <ArrowNarrowLeftIcon className="w-10" />
                </LinkButton>
              </div>

              {/* video title */}
              {showTitle && (
                <div
                  // ref={videoTitleRef}
                  style={{ marginTop: 40 }}
                  className="video-title absolute z-50 left-2 top-2 mt-40 rounded-sm transition-opacity duration-300 md:left-6 md:top-5 md:rounded-xl"
                >
                  <p className="video-title-change px-3 py-1 text-sm font-bold text-white dark:text-md md:px-7 md:py-2 md:text-lg">
                    {movie?.title?.text}
                  </p>
                </div>
              )}

              {/* loader */}
              <button
                ref={loaderRef}
                id="loader"
                className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden"
              >
                <img
                  src={Spinner}
                  className="relative inline-flex h-14 w-14 text-white opacity-75 dark:text-main-dark md:h-24 md:w-24 animate-spin"
                  alt="spinner"
                />
              </button>

              {/*Mobile view play pause button*/}
              {isMobile && (
                <div className="absolute z-50 inset-0 mx-auto w-3/4 flex items-center justify-between sm:hidden">
                  {/* backward time */}
                  <button>
                    <svg
                      onClick={() => skipBackward()}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="backward-time mx-2 lg:mx-8 text-white h-6 max-w-6 md:max-w-10 md:h-10"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.0198 2.04817C13.3222 1.8214 15.6321 2.39998 17.5557 3.68532C19.4794 4.97067 20.8978 6.88324 21.5694 9.09718C22.241 11.3111 22.1242 13.6894 21.2388 15.8269C20.3534 17.9643 18.7543 19.7286 16.714 20.8192C14.6736 21.9098 12.3182 22.2592 10.0491 21.8079C7.77999 21.3565 5.73759 20.1323 4.26989 18.3439C2.80219 16.5555 2 14.3136 2 12L0 12C-2.74181e-06 14.7763 0.962627 17.4666 2.72387 19.6127C4.48511 21.7588 6.93599 23.2278 9.65891 23.7694C12.3818 24.3111 15.2083 23.8918 17.6568 22.5831C20.1052 21.2744 22.0241 19.1572 23.0866 16.5922C24.149 14.0273 24.2892 11.1733 23.4833 8.51661C22.6774 5.85989 20.9752 3.56479 18.6668 2.02238C16.3585 0.479975 13.5867 -0.214319 10.8238 0.057802C8.71195 0.2658 6.70517 1.02859 5 2.2532V1H3V5C3 5.55229 3.44772 6 4 6H8V4H5.99999C7.45608 2.90793 9.19066 2.22833 11.0198 2.04817ZM2 4V7H5V9H1C0.447715 9 0 8.55229 0 8V4H2ZM14.125 16C13.5466 16 13.0389 15.8586 12.6018 15.5758C12.1713 15.2865 11.8385 14.8815 11.6031 14.3609C11.3677 13.8338 11.25 13.2135 11.25 12.5C11.25 11.7929 11.3677 11.1759 11.6031 10.6488C11.8385 10.1217 12.1713 9.71671 12.6018 9.43389C13.0389 9.14463 13.5466 9 14.125 9C14.7034 9 15.2077 9.14463 15.6382 9.43389C16.0753 9.71671 16.4116 10.1217 16.6469 10.6488C16.8823 11.1759 17 11.7929 17 12.5C17 13.2135 16.8823 13.8338 16.6469 14.3609C16.4116 14.8815 16.0753 15.2865 15.6382 15.5758C15.2077 15.8586 14.7034 16 14.125 16ZM14.125 14.6501C14.5151 14.6501 14.8211 14.4637 15.043 14.0909C15.2649 13.7117 15.3759 13.1814 15.3759 12.5C15.3759 11.8186 15.2649 11.2916 15.043 10.9187C14.8211 10.5395 14.5151 10.3499 14.125 10.3499C13.7349 10.3499 13.4289 10.5395 13.207 10.9187C12.9851 11.2916 12.8741 11.8186 12.8741 12.5C12.8741 13.1814 12.9851 13.7117 13.207 14.0909C13.4289 14.4637 13.7349 14.6501 14.125 14.6501ZM8.60395 15.8554V10.7163L7 11.1405V9.81956L10.1978 9.01929V15.8554H8.60395Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                  {/* forward time */}
                  <button>
                    <svg
                      onClick={() => skipForward()}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="forward-time text-white mr-2 lg:mr-8 h-6 max-w-6 md:max-w-10 md:h-10"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.4443 3.68532C8.36794 2.39998 10.6778 1.8214 12.9802 2.04817C14.8093 2.22833 16.5439 2.90793 18 4H16V6H20C20.5523 6 21 5.55228 21 5V1H19V2.2532C17.2948 1.02858 15.288 0.265799 13.1762 0.0578004C10.4133 -0.214321 7.64153 0.479973 5.33315 2.02238C3.02478 3.56479 1.32262 5.85989 0.516716 8.51661C-0.28919 11.1733 -0.148983 14.0273 0.913448 16.5922C1.97588 19.1572 3.8948 21.2744 6.34325 22.5831C8.79169 23.8918 11.6182 24.3111 14.3411 23.7694C17.064 23.2278 19.5149 21.7588 21.2761 19.6127C23.0374 17.4666 24 14.7763 24 12L22 12C22 14.3136 21.1978 16.5555 19.7301 18.3439C18.2624 20.1323 16.22 21.3565 13.9509 21.8079C11.6818 22.2592 9.32641 21.9098 7.28604 20.8192C5.24567 19.7286 3.64657 17.9643 2.76121 15.8269C1.87585 13.6894 1.75901 11.3111 2.4306 9.09717C3.10218 6.88324 4.52065 4.97066 6.4443 3.68532ZM22 4V7H19V9H23C23.5523 9 24 8.55228 24 8V4H22ZM12.6018 15.5758C13.0389 15.8586 13.5466 16 14.125 16C14.7034 16 15.2077 15.8586 15.6382 15.5758C16.0753 15.2865 16.4116 14.8815 16.6469 14.3609C16.8823 13.8338 17 13.2135 17 12.5C17 11.7929 16.8823 11.1758 16.6469 10.6488C16.4116 10.1217 16.0753 9.71671 15.6382 9.43388C15.2077 9.14463 14.7034 9 14.125 9C13.5466 9 13.0389 9.14463 12.6018 9.43388C12.1713 9.71671 11.8385 10.1217 11.6031 10.6488C11.3677 11.1758 11.25 11.7929 11.25 12.5C11.25 13.2135 11.3677 13.8338 11.6031 14.3609C11.8385 14.8815 12.1713 15.2865 12.6018 15.5758ZM15.043 14.0909C14.8211 14.4637 14.5151 14.6501 14.125 14.6501C13.7349 14.6501 13.4289 14.4637 13.207 14.0909C12.9851 13.7117 12.8741 13.1814 12.8741 12.5C12.8741 11.8186 12.9851 11.2916 13.207 10.9187C13.4289 10.5395 13.7349 10.3499 14.125 10.3499C14.5151 10.3499 14.8211 10.5395 15.043 10.9187C15.2649 11.2916 15.3759 11.8186 15.3759 12.5C15.3759 13.1814 15.2649 13.7117 15.043 14.0909ZM8.60395 10.7163V15.8554H10.1978V9.01928L7 9.81956V11.1405L8.60395 10.7163Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>
              )}

              <div className="absolute inset-0 mx-2 flex items-center justify-between">
                {/*backward */}
                <div className="z-50">
                  <div
                    ref={backwardBtnRef}
                    className="backward-btn h-4 w-5 z-50 hidden items-center justify-center rounded-2xl md:h-20 md:w-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-4 text-white md:h-12 md:w-9"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m11 18l-6-6l6-6l1.4 1.4L7.825 12l4.575 4.6Zm6.6 0l-6-6l6-6L19 7.4L14.425 12L19 16.6Z"
                      />
                    </svg>
                  </div>
                </div>
                {/*forward*/}
                <div className="z-50">
                  <div
                    ref={forwardBtnRef}
                    className="forward-btn hidden h-4 w-5 items-center justify-center rounded-2xl md:h-20 md:w-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg "
                      className="my-3 h-6 w-4 text-white md:h-12 md:w-9"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6.41 6L5 7.41L9.58 12L5 16.59L6.41 18l6-6z"
                      />
                      <path
                        fill="currentColor"
                        d="m13 6l-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* progressBar & buttons  */}
              <div
                ref={sliderRef}
                className="slider hidSpeed backBetter absolute left-[7px] bottom-[2px] right-0 z-30 mb-1 flex w-[97%] flex-col rounded-lg p-2  transition-opacity duration-300 md:bottom-[10px] md:left-[13px]"
              >
                {/* control progressBar & buttons */}
                {/* image preview */}
                <img
                  ref={previewImgRef}
                  className="preview-img absolute left-[var(--upTime)] -top-16 hidden h-[50px] w-[60px] -translate-x-[20px] transform rounded-lg transition-opacity duration-200 md:-top-32 md:h-[100px] md:w-[130px] md:-translate-x-[60px]"
                  src="src/components/custom/Playcom"
                  alt={""}
                />
                {/* progress Bar */}
                <div className="">
                  <div
                    onClick={updateTime}
                    onMouseMove={getTimeCurrent}
                    // onMouseEnter={() => showPreview()}
                    // onMouseLeave={() => hidePreview()}
                    ref={progressBarRef}
                    // style={{ maxWidth: "95%" }}
                    className="progressBar-container w-[85%] sm:w-[90%] md:w-[93%] lg:w-[95%] group z-40 mx-1 flex h-3 cursor-pointer items-center md:h-6"
                  >
                    {/* time now */}
                    <div
                      ref={upTimeRef}
                      className="upTime absolute left-[var(--upTime)] -top-3 hidden w-max -translate-x-1 rounded-[3px]  bg-black text-xs font-bold text-white group-hover:block dark:bg-black dark:text-white max-md:px-[1px] md:-top-6 md:-translate-x-3 md:text-base"
                    >
                      00:00
                    </div>
                    <div className="before:bg-yellow-00 relative z-40 h-1 w-full bg-gray before:absolute before:left-0 before:top-0 before:bottom-0 before:right-[calc(100%-var(--preview))] before:hidden before:bg-main-light/30 before:content-[''] after:absolute after:left-0 after:top-0 after:bottom-0 after:right-[calc(100%-var(--progress))] after:bg-main-light after:content-[''] before:group-hover:block after:group-hover:block dark:bg-main-dark/10 dark:before:bg-main-dark/30 dark:after:bg-main-dark">
                      {/* thumb */}
                      <div
                        ref={thumbRef}
                        className="thumb pointer-events-none absolute -top-[70%] left-[var(--progress)] -z-40 aspect-[1/1] h-[250%] translate-x-[-50%] rounded-[50%] bg-green transition-all duration-100 ease-in-out group-hover:scale-125 dark:bg-main-dark"
                      ></div>
                    </div>
                  </div>
                  <div
                    ref={currentTimeRef}
                    className="current-time absolute mx-auto top-[10%] right-0 text-[10px] lg:text-sm px-2 font-medium text-white dark:text-white sm:text-xs"
                  >
                    00:00
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row items-center justify-between mt-6">
                  {/* left */}
                  <div className="flex items-center gap-[2px] md:gap-2">
                    {/* Play Button & Pause */}
                    <button onClick={togglePlay} className="">
                      {playing ? (
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-white h-6 max-w-6 md:max-w-10 md:h-10"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.5 3C4.22386 3 4 3.22386 4 3.5V20.5C4 20.7761 4.22386 21 4.5 21H9.5C9.77614 21 10 20.7761 10 20.5V3.5C10 3.22386 9.77614 3 9.5 3H4.5ZM14.5 3C14.2239 3 14 3.22386 14 3.5V20.5C14 20.7761 14.2239 21 14.5 21H19.5C19.7761 21 20 20.7761 20 20.5V3.5C20 3.22386 19.7761 3 19.5 3H14.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-white h-6 max-w-6 md:max-w-10 md:h-10"
                        >
                          <path
                            d="M4 2.69127C4 1.93067 4.81547 1.44851 5.48192 1.81506L22.4069 11.1238C23.0977 11.5037 23.0977 12.4963 22.4069 12.8762L5.48192 22.1849C4.81546 22.5515 4 22.0693 4 21.3087V2.69127Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      )}
                    </button>

                    {/* backward time */}
                    <button>
                      <svg
                        onClick={() => skipBackward()}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="backward-time px-2 lg:px-8 text-white h-6 max-w-6 md:max-w-10 md:h-10"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.0198 2.04817C13.3222 1.8214 15.6321 2.39998 17.5557 3.68532C19.4794 4.97067 20.8978 6.88324 21.5694 9.09718C22.241 11.3111 22.1242 13.6894 21.2388 15.8269C20.3534 17.9643 18.7543 19.7286 16.714 20.8192C14.6736 21.9098 12.3182 22.2592 10.0491 21.8079C7.77999 21.3565 5.73759 20.1323 4.26989 18.3439C2.80219 16.5555 2 14.3136 2 12L0 12C-2.74181e-06 14.7763 0.962627 17.4666 2.72387 19.6127C4.48511 21.7588 6.93599 23.2278 9.65891 23.7694C12.3818 24.3111 15.2083 23.8918 17.6568 22.5831C20.1052 21.2744 22.0241 19.1572 23.0866 16.5922C24.149 14.0273 24.2892 11.1733 23.4833 8.51661C22.6774 5.85989 20.9752 3.56479 18.6668 2.02238C16.3585 0.479975 13.5867 -0.214319 10.8238 0.057802C8.71195 0.2658 6.70517 1.02859 5 2.2532V1H3V5C3 5.55229 3.44772 6 4 6H8V4H5.99999C7.45608 2.90793 9.19066 2.22833 11.0198 2.04817ZM2 4V7H5V9H1C0.447715 9 0 8.55229 0 8V4H2ZM14.125 16C13.5466 16 13.0389 15.8586 12.6018 15.5758C12.1713 15.2865 11.8385 14.8815 11.6031 14.3609C11.3677 13.8338 11.25 13.2135 11.25 12.5C11.25 11.7929 11.3677 11.1759 11.6031 10.6488C11.8385 10.1217 12.1713 9.71671 12.6018 9.43389C13.0389 9.14463 13.5466 9 14.125 9C14.7034 9 15.2077 9.14463 15.6382 9.43389C16.0753 9.71671 16.4116 10.1217 16.6469 10.6488C16.8823 11.1759 17 11.7929 17 12.5C17 13.2135 16.8823 13.8338 16.6469 14.3609C16.4116 14.8815 16.0753 15.2865 15.6382 15.5758C15.2077 15.8586 14.7034 16 14.125 16ZM14.125 14.6501C14.5151 14.6501 14.8211 14.4637 15.043 14.0909C15.2649 13.7117 15.3759 13.1814 15.3759 12.5C15.3759 11.8186 15.2649 11.2916 15.043 10.9187C14.8211 10.5395 14.5151 10.3499 14.125 10.3499C13.7349 10.3499 13.4289 10.5395 13.207 10.9187C12.9851 11.2916 12.8741 11.8186 12.8741 12.5C12.8741 13.1814 12.9851 13.7117 13.207 14.0909C13.4289 14.4637 13.7349 14.6501 14.125 14.6501ZM8.60395 15.8554V10.7163L7 11.1405V9.81956L10.1978 9.01929V15.8554H8.60395Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>

                    {/* forward time */}
                    <button>
                      <svg
                        onClick={() => skipForward()}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="forward-time text-white pr-2 lg:pr-8 h-6 max-w-6 md:max-w-10 md:h-10"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.4443 3.68532C8.36794 2.39998 10.6778 1.8214 12.9802 2.04817C14.8093 2.22833 16.5439 2.90793 18 4H16V6H20C20.5523 6 21 5.55228 21 5V1H19V2.2532C17.2948 1.02858 15.288 0.265799 13.1762 0.0578004C10.4133 -0.214321 7.64153 0.479973 5.33315 2.02238C3.02478 3.56479 1.32262 5.85989 0.516716 8.51661C-0.28919 11.1733 -0.148983 14.0273 0.913448 16.5922C1.97588 19.1572 3.8948 21.2744 6.34325 22.5831C8.79169 23.8918 11.6182 24.3111 14.3411 23.7694C17.064 23.2278 19.5149 21.7588 21.2761 19.6127C23.0374 17.4666 24 14.7763 24 12L22 12C22 14.3136 21.1978 16.5555 19.7301 18.3439C18.2624 20.1323 16.22 21.3565 13.9509 21.8079C11.6818 22.2592 9.32641 21.9098 7.28604 20.8192C5.24567 19.7286 3.64657 17.9643 2.76121 15.8269C1.87585 13.6894 1.75901 11.3111 2.4306 9.09717C3.10218 6.88324 4.52065 4.97066 6.4443 3.68532ZM22 4V7H19V9H23C23.5523 9 24 8.55228 24 8V4H22ZM12.6018 15.5758C13.0389 15.8586 13.5466 16 14.125 16C14.7034 16 15.2077 15.8586 15.6382 15.5758C16.0753 15.2865 16.4116 14.8815 16.6469 14.3609C16.8823 13.8338 17 13.2135 17 12.5C17 11.7929 16.8823 11.1758 16.6469 10.6488C16.4116 10.1217 16.0753 9.71671 15.6382 9.43388C15.2077 9.14463 14.7034 9 14.125 9C13.5466 9 13.0389 9.14463 12.6018 9.43388C12.1713 9.71671 11.8385 10.1217 11.6031 10.6488C11.3677 11.1758 11.25 11.7929 11.25 12.5C11.25 13.2135 11.3677 13.8338 11.6031 14.3609C11.8385 14.8815 12.1713 15.2865 12.6018 15.5758ZM15.043 14.0909C14.8211 14.4637 14.5151 14.6501 14.125 14.6501C13.7349 14.6501 13.4289 14.4637 13.207 14.0909C12.9851 13.7117 12.8741 13.1814 12.8741 12.5C12.8741 11.8186 12.9851 11.2916 13.207 10.9187C13.4289 10.5395 13.7349 10.3499 14.125 10.3499C14.5151 10.3499 14.8211 10.5395 15.043 10.9187C15.2649 11.2916 15.3759 11.8186 15.3759 12.5C15.3759 13.1814 15.2649 13.7117 15.043 14.0909ZM8.60395 10.7163V15.8554H10.1978V9.01928L7 9.81956V11.1405L8.60395 10.7163Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>
                    <div
                      className="relative"
                      onMouseEnter={handleShowVolume}
                      onMouseLeave={handleHideVolume}
                    >
                      {/*Volume Icon */}
                      <div onClick={muteHandler} className="volume-btn">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="high-volume text-white h-6 max-w-6 md:max-w-10 md:h-10"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M24 12C24 8.28699 22.525 4.72603 19.8995 2.10052L18.4853 3.51474C20.7357 5.76517 22 8.81742 22 12C22 15.1826 20.7357 18.2349 18.4853 20.4853L19.8995 21.8995C22.525 19.274 24 15.7131 24 12ZM11 4.00001C11 3.59555 10.7564 3.23092 10.3827 3.07613C10.009 2.92135 9.57889 3.00691 9.29289 3.29291L4.58579 8.00001H1C0.447715 8.00001 0 8.44773 0 9.00001V15C0 15.5523 0.447715 16 1 16H4.58579L9.29289 20.7071C9.57889 20.9931 10.009 21.0787 10.3827 20.9239C10.7564 20.7691 11 20.4045 11 20V4.00001ZM5.70711 9.70712L9 6.41423V17.5858L5.70711 14.2929L5.41421 14H5H2V10H5H5.41421L5.70711 9.70712ZM16.0001 12C16.0001 10.4087 15.368 8.8826 14.2428 7.75739L12.8285 9.1716C13.5787 9.92174 14.0001 10.9392 14.0001 12C14.0001 13.0609 13.5787 14.0783 12.8285 14.8285L14.2428 16.2427C15.368 15.1174 16.0001 13.5913 16.0001 12ZM17.0709 4.92896C18.9462 6.80432 19.9998 9.34786 19.9998 12C19.9998 14.6522 18.9462 17.1957 17.0709 19.0711L15.6567 17.6569C17.157 16.1566 17.9998 14.1218 17.9998 12C17.9998 9.87829 17.157 7.84346 15.6567 6.34317L17.0709 4.92896Z"
                            fill="currentColor"
                          ></path>
                        </svg>

                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="low-volume text-white pr-2 lg:pr-8 h-6 max-w-6 md:max-w-10 md:h-10"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11 4C11 3.59554 10.7564 3.2309 10.3827 3.07612C10.009 2.92134 9.57889 3.00689 9.29289 3.29289L4.58579 8H1C0.447715 8 0 8.44771 0 9V15C0 15.5523 0.447715 16 1 16H4.58579L9.29289 20.7071C9.57889 20.9931 10.009 21.0787 10.3827 20.9239C10.7564 20.7691 11 20.4045 11 20V4ZM5.70711 9.70711L9 6.41421V17.5858L5.70711 14.2929L5.41421 14H5H2V10H5H5.41421L5.70711 9.70711ZM16.0001 12C16.0001 10.4087 15.368 8.88259 14.2428 7.75737L12.8285 9.17158C13.5787 9.92173 14.0001 10.9391 14.0001 12C14.0001 13.0609 13.5787 14.0783 12.8285 14.8284L14.2428 16.2427C15.368 15.1174 16.0001 13.5913 16.0001 12Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mute-volume text-white lg:pr-8 h-6 max-w-6 md:max-w-10 md:h-10"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11 4.00003C11 3.59557 10.7564 3.23093 10.3827 3.07615C10.009 2.92137 9.57889 3.00692 9.29289 3.29292L4.58579 8.00003H1C0.447715 8.00003 0 8.44774 0 9.00003V15C0 15.5523 0.447715 16 1 16H4.58579L9.29289 20.7071C9.57889 20.9931 10.009 21.0787 10.3827 20.9239C10.7564 20.7691 11 20.4045 11 20V4.00003ZM5.70711 9.70714L9 6.41424V17.5858L5.70711 14.2929L5.41421 14H5H2V10H5H5.41421L5.70711 9.70714ZM15.2929 9.70714L17.5858 12L15.2929 14.2929L16.7071 15.7071L19 13.4142L21.2929 15.7071L22.7071 14.2929L20.4142 12L22.7071 9.70714L21.2929 8.29292L19 10.5858L16.7071 8.29292L15.2929 9.70714Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                      {/*Change Volume*/}
                      <div
                        style={{
                          width: "138px",
                          display: sliderDisplay,
                        }}
                        className={
                          "slider-rotated bg-black h-4 accent-main-light ring-0 dark:accent-main-dark"
                        }
                      >
                        <input
                          type="range"
                          min="0"
                          max="1"
                          step="any"
                          ref={volumeRef}
                          onInput={handleVolumeChange}
                          className="top-[-4px]"
                        />
                      </div>
                    </div>
                  </div>
                  {/* center */}

                  {/*bottom title*/}
                  <span className="text-white">{movie?.title?.text}</span>

                  {/* right */}
                  <div className="flex justify-center gap-[2px] md:gap-2">
                    {/* speed */}
                    <button
                      style={{ marginRight: 5 }}
                      onClick={handleClick}
                      className="speed-btn relative"
                    >
                      <svg
                        xmlns="http:www.w3.org/2000/svg"
                        className="text-white pr-2 lg:pr-4 h-6 max-w-6 md:max-w-10 md:h-10"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.6427 7.43779C14.5215 4.1874 9.47851 4.1874 6.35734 7.43779C3.21422 10.711 3.21422 16.0341 6.35734 19.3074L4.91474 20.6926C1.02842 16.6454 1.02842 10.0997 4.91474 6.05254C8.823 1.98249 15.177 1.98249 19.0853 6.05254C22.9716 10.0997 22.9716 16.6454 19.0853 20.6926L17.6427 19.3074C20.7858 16.0341 20.7858 10.711 17.6427 7.43779ZM14 14C14 15.1046 13.1046 16 12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C12.1792 12 12.3528 12.0236 12.518 12.0677L15.7929 8.79289L17.2071 10.2071L13.9323 13.482C13.9764 13.6472 14 13.8208 14 14Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>
                    {/* rate speed */}
                    <ul
                      ref={rateSpeedRef}
                      onMouseLeave={showRateMouseLeave}
                      onMouseMove={showRateMouseMove}
                      onClick={(e) => handleSpeedClick(e)}
                      className="speed-rate md: absolute -top-4 z-[60] hidden h-max w-max -translate-x-[80px] -translate-y-1/2 cursor-pointer gap-1 rounded-md bg-black p-1 font-medium text-white backdrop-blur-xl transition-opacity duration-300 ease-in-out dark:text-main-dark md:-top-[28px] md:-translate-x-2/3 md:px-2 md:py-1"
                    >
                      {[0.5, 0.75, 1, 1.75, 2].map((speed, i) => (
                        <li
                          key={speed}
                          className={`${
                            i === 2 ? "active" : ""
                          } m-[2px] rounded-[2px] px-[2px] text-[8px] hover:bg-white hover:text-red-700 md:rounded-[6px] md:p-2 md:px-2 md:text-[14px] md:active:px-2`}
                          data-speed={speed}
                        >
                          {speed}x
                        </li>
                      ))}
                    </ul>
                    {/* fullScreen */}
                    <button
                      onClick={fullScreenHandler}
                      className="full-screen pl-2 lg:pr-4 text-white h-6 max-w-6 md:max-w-10 md:h-10"
                    >
                      {/* not full */}
                      <svg
                        ref={fullScreenOpenRef}
                        xmlns="http:www.w3.org/2000/svg"
                        className="text-white h-6 max-w-6 md:max-w-10 md:h-10"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 5C0 3.89543 0.895431 3 2 3H9V5H2V9H0V5ZM22 5H15V3H22C23.1046 3 24 3.89543 24 5V9H22V5ZM2 15V19H9V21H2C0.895431 21 0 20.1046 0 19V15H2ZM22 19V15H24V19C24 20.1046 23.1046 21 22 21H15V19H22Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      {/* full */}
                      <svg
                        xmlns="http:www.w3.org/2000/svg"
                        ref={fullScreenExitRef}
                        className="full-screen-exit hidden text-white dark:text-main-dark h-6 max-w-6 md:max-w-10 md:h-10"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M10 4H8v4H4v2h6zM8 20h2v-6H4v2h4zm12-6h-6v6h2v-4h4zm0-6h-4V4h-2v6h6z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {isDelay && (
            <div className="absolute flex w-full z-50 h-full items-center justify-center">
              <button
                onClick={togglePlay}
                ref={centerIconRef}
                className="relative"
                // className="center-icon z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  className="absolute inline-flex h-full w-full animate-ping text-white opacity-75 dark:text-main-dark"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m9.5 16.5l7-4.5l-7-4.5ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                  />
                </svg>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  className="relative inline-flex h-14 w-14 text-white opacity-75 dark:text-main-dark md:h-32 md:w-32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m9.5 16.5l7-4.5l-7-4.5ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                  />
                </svg>
              </button>
            </div>
          )}

          {/* video main*/}
          <ReactHLSPlayer
            onClick={togglePlay}
            onTimeUpdate={handleTimeUpdate}
            onCanPlayThrough={handleLoaderCanPlayThrough}
            onWaiting={handleLoaderWaiting}
            onVolumeChange={volumeChange}
            onPlaying={handleVideoPlaying}
            onPause={handleVideoPause}
            onPlay={handlePlayed}
            onProgress={handleProgress}
            onLoadedMetadata={handleLoadedMetadata}
            className="video z-0 h-full w-full relative"
            poster={movie?.poster}
            src={movie?.src}
            playerRef={videoRef}
            autoPlay={true}
          ></ReactHLSPlayer>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
