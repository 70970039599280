import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer, Header, Loader } from "../imports/components";

const Browse = () => {
  const { pathname } = useLocation();
  const [processing, setProcessing] = useState(false);

  const isIsindex = useMemo(() => {
    return pathname.indexOf("browse") > -1;
  }, [pathname]);

  return processing ? (
    <Loader />
  ) : (
    <>
      <Header
        setProcessing={setProcessing}
        position={isIsindex ? "fixed" : "relative"}
      />

      <Outlet />

      <Footer className="bg-zinc-900 mt-auto" showLocation={true} />
    </>
  );
};

export default Browse;
