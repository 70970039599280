import React from 'react';
import {Images} from "../../imports/statics";

function LinksList(props) {
    return (
      <div>
        {props.links.map((link, index) => (
          <div className="flex justify-center mt-4 sm:hidden" key={link.url}>
            <div className="relative w-11/12 cursor-pointer">
              <a
                href={link.url}
                className="block w-full p-0 pl-0 pt-4 pb-0 text-md border-t text-black hover:underline"
              >
                {link.name}
                <img
                  className="text-white absolute right-2.5 bottom-2.5 text-sm -mb-2 px-4 pt-8  mt-8"
                  src={Images.GreaterThan}
                  height="50px"
                  width="50px"
                  alt=""
                  srcSet=""
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    );
}

export default LinksList;
