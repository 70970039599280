import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import Player from "@gumlet/react-hls-player";
import {
  useAuth,
  useBanner,
  useModalContext,
  useWatched,
} from "../../imports/hooks";
import { storage } from "../../imports/utils";
import { MoreInfoButton, PlayMovieButton } from "../../imports/components";
import { BiInfoCircle } from "react-icons/bi";
import {
  RefreshIcon,
  VolumeOffIcon,
  VolumeUpIcon,
} from "@heroicons/react/outline";

const args = {
  type: "any",
  genre: "all",
  filterElement: null,
};

const Banner = (props = { ...args }) => {
  const ref = useRef();
  props = { ...args, ...props };
  const { data: user } = useAuth();
  const { show } = useModalContext();
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(
    storage.getData("spr", storage.path.session) ?? true
  );
  const [played, setPlayed] = useState(
    storage.getData("plr", storage.path.session) ?? false
  );
  const { banner, fetching: isLoading } = useBanner({
    type: props.type,
    genre: props.genre,
  });
  const { watched } = useWatched({
    movieId: banner?.id,
    enabled: true,
  });

  const movie = useMemo(() => {
    return banner ? { ...banner, is_watched: watched } : {};
  }, [banner, watched]);

  const FilterElement = (
    <div className="default-padding md:pt-6">{props.filterElement}</div>
  );

  function videoEnded(e) {
    setPlaying(false);

    setTimeout(async () => {
      await e.target.load();
      setPlayed(true);
      storage.setData("plr", true, storage.path.session);
    }, 120);
  }

  async function playVideo() {
    if (ref.current) {
      if (playing) {
        storage.setData("spr", !muted, storage.path.session);
        setMuted((pre) => !pre);
      } else {
        await ref.current.play();
      }
    }
  }

  const autoPlay = useCallback(() => {
    let timer;
    if (movie && ref.current) {
      if (playing && !show) {
        ref.current.play();
      } else if (user.profile?.autoplay_browsing && !playing && !played) {
        timer = setTimeout(() => {
          if (ref.current?.play) {
            ref.current.play();
          }
        }, 8000);
      }
      const stored = storage.getData("spr", storage.path.session);
      setMuted(stored !== null ? stored : ref.current.muted);
    }

    return () => clearTimeout(timer);
  }, [user, movie, played, playing, show, ref]);

  useLayoutEffect(() => {
    autoPlay();
  }, [autoPlay]);

  return (
    !isLoading && (
      <>
        {movie?.id && (
          <div className="hidden sm:block max-h=[100vw] lg:max-h-[85vw] xl:max-h-[90vh] sm:mb-0 relative w-screen aspect-video transition delay-100 bg-gradient-bottom overflow-hidden">
            <Player
              muted={muted}
              preload="auto"
              playerRef={ref}
              onEnded={videoEnded}
              src={movie.teaser}
              poster={movie.image}
              onPlaying={() => setPlaying(true)}
              className="object-cover relative -z-50 w-full max-h-full top-0 left-0"
            />

            <div className="h-full absolute max-h-full top-0 w-full">
              {props.filterElement && (
                <div className="relative">
                  <div className="absolute w-full max-w-full top-14 mt:top-[10vh] max-h-fit">
                    {FilterElement}
                  </div>
                </div>
              )}

              <div className="flex flex-col h-full max-h-[88%] md:max-h-[85%] justify-end">
                <div className="grid grid-cols-2 items-end flex-1 w-full">
                  <div className="col-span-1 min-w-fit max-w-fit sm:space-y-5 default-padding pr-0 pb-0">
                    <div
                      className={`transition-all duration-[2.2s] ease-in-out origin-bottom-left ${
                        playing ? "w-[60%]" : "w-full"
                      }`}
                    >
                      <img
                        nonce="off"
                        src={movie?.title?.image}
                        alt={movie.title?.text}
                        className="object-contain max-h-min w-full"
                      />

                      <p
                        className="hidden transition-all duration-[2.2s] sm:block text-xs max-w-lg"
                        style={{
                          lineHeight: "1.2rem",
                          overflowY: "hidden",
                          maxHeight: playing ? "0rem" : "6rem",
                        }}
                      >
                        {movie?.extract}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 md:space-x-3 max-w-full">
                      <PlayMovieButton
                        movieId={movie?.id}
                        isWatched={movie?.is_watched}
                        key="banner_play"
                      />

                      <MoreInfoButton
                        movie={movie}
                        playerRef={ref}
                        playing={playing}
                        className="banner-button text-truncate min-w-min bg-[gray]/50"
                      >
                        <span>More Info</span>
                        <BiInfoCircle className="icon" />
                      </MoreInfoButton>
                    </div>
                  </div>

                  <div className="col-span-1 flex justify-end items-center space-x-2 lg:space-x-4 text-xs md:text-md lg:text-lg">
                    {(playing || played) && (
                      <button
                        type="button"
                        onClick={playVideo}
                        className="rounded-button hover:bg-[#ffffff22] text-sm"
                      >
                        {played && !playing ? (
                          <RefreshIcon />
                        ) : muted ? (
                          <VolumeOffIcon />
                        ) : (
                          <VolumeUpIcon />
                        )}
                      </button>
                    )}

                    <div className="bg-zinc-600 text-sm sm:text-lg bg-opacity-50 border-l-2 pl-2 pr-5 py-1 sm:pr-16 sm:pl-3">
                      {movie?.maturity?.age}+
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={`${movie?.id ? "sm:hidden" : ""} mt-14`}>
          {FilterElement}
        </div>

        {props.children && (
          <div className="default-padding">{props.children}</div>
        )}
      </>
    )
  );
};

export default Banner;
