import React from "react";
import { PlusIcon, SearchIcon } from "@heroicons/react/outline";
import { LinkButton } from "../../imports/components";

const args = {
  navigate: () => {},
  pathname: "",
  search: "",
};

const Search = (props = { ...args }) => {
  const { navigate, pathname, search } = { ...args, ...props };
  const isSearch = pathname.slice(1) === "search";
  const defaultSearch =
    search?.split("=")[1] !== "all" ? search?.split("=")[1] : "";

  function onSearch({ target: { value } }) {
    if (value !== "all") {
      navigate(`?q=${value.length > 0 ? value : "all"}`, { replace: true });
    }
  }

  function onLeft({ target: { value } }) {
    if (value === "") {
      console.log(value);
      navigate(-1, { replace: true });
    }
  }

  return isSearch ? (
    <div className="flex fixed top-0 gap-2 left-0 w-full sm:static z-50 items-center bg-secondary border border-zinc-300 px-2 py-3 sm:py-1">
      <SearchIcon className="w-5 hidden sm:block" />
      <LinkButton href={-1} className="sm:hidden">
        <PlusIcon className="w-5 rotate-45" />
      </LinkButton>

      <input
        id="search"
        type="search"
        onBlur={onLeft}
        autoFocus={true}
        onChange={onSearch}
        defaultValue={defaultSearch}
        placeholder="Search genres, title..."
        className="w-full bg-transparent outline-none"
      />
    </div>
  ) : (
    <LinkButton href="/search" className="flex items-center gap-2">
      <SearchIcon className="w-5 sm:w-6" />
    </LinkButton>
  );
};

export default Search;
