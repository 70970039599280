import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef } from "react";
import { useModalContext, usePost } from "../imports/hooks";
import {
  FloatingInput,
  SubmitButton,
  Alert,
  Form,
} from "../imports/components";
import { BiHelpCircle } from "react-icons/bi";

const AddProfileDialog = () => {
  const inputRef = useRef(null);
  const { show, slug, dispatch, reference } = useModalContext();
  const { post, state, processing } = usePost("profiles/create-single", {
    delay: 4000,
    redirect: false,
    toggleOnSuccess: false,
    successCodes: [201],
    clientKey: reference,
    onSuccess: () => {
      setOpen();
    },
  });

  const setOpen = () => {
    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  return (
    <Transition.Root show={show && slug === "add-profile"} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={inputRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-md border border-zinc-600">
                <Form onSubmit={post} className="p-5 space-y-3">
                  <p className="text-2xl">Create Profile</p>
                  <small>Add someone to watch with you in this account.</small>

                  <button
                    type="button"
                    onClick={setOpen}
                    className="absolute text-shadow-md opacity-75 hover:opacity-100 top-2 right-5 text-white text-lg"
                  >
                    <PlusIcon className="rotate-45 w-6" />
                  </button>

                  <div className="flex items-center relative bg-zinc-900 rounded">
                    <div className="w-full">
                      <FloatingInput
                        id="name"
                        autoFocus={true}
                        name="name"
                        elementRef={inputRef}
                        inputClassName="bg-zinc-900 rounded"
                        placeholder="Enter a name for this profile"
                        required={true}
                        feedback="Please provide a name for this profile"
                        feedBackClassName="px-2.5 text-orange-500"
                      />
                    </div>

                    <label
                      htmlFor="kid"
                      className="flex items-center mr-3 gap-x-1"
                    >
                      <input
                        type="checkbox"
                        defaultValue="true"
                        label="Kid?"
                        id="kid"
                        name="is_kid"
                      />
                      <span className="text-sm">Kid?</span>
                      <span title="Profile marked as kid only gets access to kids contents.">
                        <BiHelpCircle
                          data-bs-toggle="tooltip"
                          data-bs-target="#kids-help"
                          className="text-blue-500 cursor-help peer"
                        />
                      </span>
                    </label>
                  </div>

                  {state && (
                    <Alert color={state.success ? "green" : "rose"}>
                      {state.message}
                    </Alert>
                  )}

                  <SubmitButton
                    isProcessing={processing}
                    className="text-white font-normal normal-case"
                  >
                    Submit
                  </SubmitButton>
                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddProfileDialog;
