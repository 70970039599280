import React from "react";
import { LinkButton } from "../../imports/components";
import { useSignOut } from "../../imports/hooks";
import { ProfileLayout } from "../../imports/layouts";

function ChooseProfile() {
  const { signOut } = useSignOut();

  return (
    <ProfileLayout title="Who's Watching?">
      <div className="flex gap-4 mt-8">
        <LinkButton
          href="/manage-profiles"
          className="border-2 text-sm sm:text-base text-gray-400 font-semibold hover:text-white border-gray-400 px-2 sm:px-4 py-2 hover:bg-secondary"
        >
          Manage profiles
        </LinkButton>

        <LinkButton
          replaceClick={true}
          onClick={() => signOut()}
          className="border-2 text-red text-xs sm:text-base font-semibold border-red px-4 py-2 hover:bg-red hover:text-white"
        >
          Sign Out
        </LinkButton>
      </div>
    </ProfileLayout>
  );
}

export default ChooseProfile;
