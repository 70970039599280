import Login from "../layouts/Login";
import Browse from "../layouts/Browse";
import CreateAccount from "../layouts/CreateAccount";
import ManageAccount from "../layouts/ManageAccount";
import Profiles from "../layouts/Profiles";
import RouteWrapper from "../layouts/RouteWrapper";

export const ProfileLayout = Profiles;
export const ManageAccountLayout = ManageAccount;

const Layouts = {
  Login,
  Browse,
  CreateAccount,
  RouteWrapper,
};

export default Layouts;
