import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { API } from "../imports/utils";
import { useModalContext, useToggle } from "../imports/hooks";

const args = {
  delay: 4000,
  setState: false,
  autoHide: true,
  redirect: false,
  replace: true,
  successCodes: [],
  toggleOnSuccess: true,
  clientKey: "auth",
  onSuccess: () => {},
  showProcessing: false,
  processingText: "Processing",
};

export default function usePost(path, props = { ...args }) {
  props = { ...args, ...props };
  const navigate = useNavigate();
  const client = useQueryClient();
  const { state, toggle } = useToggle();
  const { dispatch } = useModalContext();

  const { mutate, isLoading: processing } = useMutation(API.Post, {
    onSuccess: (res) => {
      const success = [...props.successCodes, 200].indexOf(res?.status) > -1;

      if (success || res.success) {
        client
          .invalidateQueries(props.clientKey)
          .then(() => {
            if (props.redirect) {
              const options = { replace: props.replace };

              if (props.setState) {
                options["state"] = res;
              }

              navigate(res.redirect, options);
            } else if (props.toggleOnSuccess) {
              toggle(res, props.autoHide, props.delay);
            } else {
              props.onSuccess(res);
            }
          })
          .finally(() => {
            if (props.showProcessing) {
              showProgress(res);
            }
          });
      } else {
        if (props.showProcessing) {
          showProgress(res);
        }
        toggle(res, props.autoHide, props.delay);
      }
    },
    onError: (err) => {
      toggle(err, true, props.delay);
    },
  });

  function showProgress(content) {
    dispatch({
      payload: content?.success
        ? null
        : {
            content,
            slug: "Processing",
            reference: { text: props.processingText },
          },
      type: content?.success ? "HIDE" : "SHOW",
    });
  }

  function post(event, data) {
    if (event) {
      event.preventDefault();
      data = data ? data : new FormData(event.target);
    }

    if (props.showProcessing) {
      showProgress(null);
    }

    mutate({
      path,
      data,
    });
  }

  return {
    post,
    state,
    toggle,
    processing,
  };
}
