import { usePost } from "../imports/hooks";
import { signUpSuccessCodes } from "../imports/utils";

function useSignUp() {
  const posted = usePost("accounts/create", {
    delay: 15000,
    redirect: true,
    onSuccess: () => {},
    successCodes: signUpSuccessCodes,
  });

  return {
    signUp: posted.post,
    signedUp: posted.state,
    toggleSignUp: posted.toggle,
    signingUp: posted.processing,
  };
}

export default useSignUp;
