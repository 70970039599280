import FAQs from "../static/faqs.json";
import Sorts from "../static/sorts.json";
import Codes from "../static/country-codes.json";
import Categories from "../static/categories.json";
import Abbreviations from "../static/country-abbrv.json";

import Icon from "../static/images/icon.png";
import Icon192 from "../static/images/icon192.png";
import Icon512 from "../static/images/icon512.png";
import Loader from "../static/images/loader.gif";
import Logo from "../static/images/logo.png";
import Logo192 from "../static/images/logo192.png";
import Logo512 from "../static/images/logo512.png";
import Plus from "../static/images/plus.png";
import Profile from "../static/images/profile.png";
import SDevice from "../static/images/s-devices.png";
import Cover from "../static/images/backgrounds/home.jpg";
import NotFoundCover from "../static/images/backgrounds/not_found.webp";
import Kids from "../static/images/home/kids.png";
import Mobile from "../static/images/home/mobile.png";
import Television from "../static/images/home/tv.gif";
import Renew from "../static/images/backgrounds/renew.jpg";
import Subscribe from "../static/images/backgrounds/subscribe.jpg";
import ForgotPassword from "../static/images/backgrounds/password.jpg";

import Spinner from "../static/Player/assets/spinner.gif";

export const Countries = {
  Codes,
  Abbreviations,
};

export const Json = {
  FAQs,
  Sorts,
  Codes,
  Categories,
  Abbreviations,
};

export const Images = {
  Spinner,
  Icon,
  Icon192,
  Icon512,
  Loader,
  Logo,
  Logo192,
  Logo512,
  Plus,
  Profile,
  SDevice,
  Cover,
  NotFoundCover,
  Kids,
  Mobile,
  Renew,
  Television,
  Subscribe,
  ForgotPassword,
};

const Statics = {
  Json,
  Images,
  Countries,
};

export default Statics;
