import { useState, createContext, useReducer, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";

const args = {
  render: false,
  categorised: false,
  renderContent: [],
};
args.dispatch = {
  type: "",
  payload: args,
};

export const RenderContext = createContext();

export default function RenderProvider({ children }) {
  const [params] = useSearchParams();
  const [state, dispatch] = useReducer(Reducer, args);

  useLayoutEffect(() => {
    const has = params.keys().next().value;
    dispatch({
      type: has ? "RENDER_CONTENT" : "RENDER",
      payload: args,
    });
  }, [params]);

  return (
    <RenderContext.Provider value={{ ...state, dispatch }}>
      {children}
    </RenderContext.Provider>
  );
}

function Reducer(state, action) {
  switch (action.type) {
    case "RENDER_CONTENT":
      return {
        ...state,
        ...action.payload,
        render: true,
      };
    default:
      return args;
  }
}
