import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useMemo, useState } from "react";
import { usePost } from "../../imports/hooks";
import { FormInput, LinkButton, SubmitButton } from "../../imports/components";
import { Alert } from "@material-tailwind/react";

const CheckOutForm = ({ data, setLoaded = () => {} }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paying, setPaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [validated, setValidated] = useState(false);
  const plan = useMemo(() => data?.plan, [data]);
  const { post, state, processing, toggle } = usePost(
    "subscriptions/card/create",
    {
      redirect: true,
      successCode: 201,
      clientKey: "auth",
    }
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return false;
    }
    setPaying(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: "if_required",
    });

    if (paymentIntent && !error) {
      const data = new FormData(e.target);
      data.set("plan", plan?.id);
      data.set("token", paymentIntent.id);

      post(e, data);
    } else {
      toggle({ message: error.message }, true);
    }

    setPaying(false);
  };

  return (
    <form
      noValidate
      validated={`${validated}`}
      onSubmit={handleFormSubmit}
      className={`mt-3 ${validated ? "group" : ""}`}
    >
      <div gap={3} className="mb-3">
        {isReady && (
          <div className="flex flex-col w-full mb-3 space-y-3">
            <FormInput
              validated={validated}
              label="First name"
              id="first_name"
              name="first_name"
              autoFocus={true}
              required={true}
              feedback="Your first name is incomplete"
              placeholder="Given name(s)"
            />
            <FormInput
              validated={validated}
              label="Last name"
              id="last_name"
              name="last_name"
              required={true}
              feedback="Your last name is incomplete"
              placeholder="Surname/family name"
            />
          </div>
        )}

        <PaymentElement
          onReady={() => {
            setIsReady(true);
            setLoaded(true);
          }}
          options={{
            layout: "tabs",
            terms: { card: "never" },
            business: {
              name: "Sierraflixx",
            },
          }}
        />
      </div>

      {isReady && (
        <div className="space-y-6 relative">
          <div className="bg-gray-200 flex justify-between py-2 px-3 text-bg-light justify-content-between text-start mb-6">
            <span className="text-xl">
              <span className="font-semibold">${plan?.price?.amount}</span>
              <small>/{plan?.price?.period?.toLowerCase()}</small>
            </span>
            <LinkButton
              href="/signup/plan/choose"
              className="font-bold text-secondary hover:underline"
              state={{ ...data, referrer: "/signup/payments/card" }}
            >
              Change
            </LinkButton>
          </div>

          <small className="text-gray-700/80">
            By clicking the button below, you agree to our{" "}
            <a
              href="/"
              className="text-blue-600 underline hover:text-green-600"
            >
              Terms of Use
            </a>
            ,{" "}
            <a
              href="/"
              className="text-blue-600 underline hover:text-green-600"
            >
              Privacy Statement
            </a>
            , and that you are over 18. Sierraflixx will automatically continue
            your membership and charge the membership fee (currently $
            {plan?.price?.amount}/{plan?.price?.period?.toLowerCase()}) to your
            payment method until you cancel. You may cancel at any time to avoid
            future charges.
          </small>

          {state && (
            <Alert className="text-red bg-rose-200 rounded-[.15rem]">
              {state.message}
            </Alert>
          )}

          <SubmitButton
            isProcessing={processing || paying}
            size="lg"
            className="w-100 mt-2 text-white"
          >
            START MEMBERSHIP
          </SubmitButton>
        </div>
      )}
    </form>
  );
};

export default CheckOutForm;
