import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { useModalContext, usePost } from "../imports/hooks";

const SwitchProfileDialog = () => {
  const dialogRef = useRef(null);
  const { show, content: profile, slug, dispatch } = useModalContext();

  const { post, state, processing } = usePost("accounts/set-profile", {
    redirect: false,
    autoHide: false,
    toggleOnSuccess: false,
    successCodes: [],
    onSuccess: () => {
      setOpen();
      return false;
    },
  });

  const setOpen = () => {
    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  const doPost = useCallback(() => {
    if (slug === "switch-profile" && profile?.id) {
      const data = new FormData();
      data.set("profile", profile?.id);
      post(null, data);
    }
  }, [slug, profile]);

  useEffect(() => {
    if (slug === "switch-profile" && profile?.id) {
      const data = new FormData();
      data.set("profile", profile?.id);
      post(null, data);
    }

    return () => {};
  }, [slug, profile]);

  return (
    <Transition.Root show={show && slug === "switch-profile"} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={dialogRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-md">
                <div
                  ref={dialogRef}
                  className="flex items-center justify-center"
                >
                  {state ? (
                    <div className="text-center text-rose-700 bg-rose-200 py-3 flex flex-col justify-center items-center w-full">
                      <ExclamationIcon className="w-12" />
                      <p className="text-xl">{state.message}</p>
                      <p className="text-black text-sm sm:text-base">
                        Try another or asked the user to sign out
                      </p>

                      <button
                        onClick={setOpen}
                        className="mt-3 text-gray-200 bg-secondary px-3 py-1 rounded-[.15rem]"
                      >
                        Close & return
                      </button>
                    </div>
                  ) : (
                    processing && (
                      <div className="text-center">
                        <div
                          className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
                          role="status"
                        >
                          <span className="visually-hidden">Processing...</span>
                        </div>
                        <p className="text-xl mt-3 font-light animate-pulse">
                          Switching
                        </p>
                      </div>
                    )
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SwitchProfileDialog;
