import React from "react";
import { Outlet } from "react-router-dom";
import { AccountNavigation, Footer } from "../imports/components";

const CreateAccount = () => {
  return (
    <div className="animate__animated animate__fadeIn flex relative flex-col min-h-screen w-full bg-white text-black">
      <AccountNavigation bg="white" useLink={true} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen">
        <div className="min-w-full max-w-full flex-1 flex flex-col justify-center items-center pt-20 pb-5">
          <Outlet />
        </div>
        <Footer className="border-t mt-auto max-w-full" />
      </div>
    </div>
  );
};

export default CreateAccount;
