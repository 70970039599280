import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSignIn, useSignUp } from "../../imports/hooks";
import {
  Alert,
  FloatingInput,
  Form,
  PasswordInput,
  SubmitButton,
} from "../../imports/components";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useMemo } from "react";

function CreateVerifyPassword() {
  const navigate = useNavigate();
  const { state: account } = useLocation();
  const has_account = account.status === 409;
  const { signIn, signedIn, signingIn } = useSignIn();
  const { signUp, signedUp, signingUp } = useSignUp();

  const { post, processing, state } = useMemo(() => {
    if (has_account) {
      return {
        post: signIn,
        processing: signingIn,
        state: signedIn,
      };
    }

    return {
      post: signUp,
      processing: signingUp,
      state: signedUp,
    };
  }, [has_account, signIn, signedIn, signingIn, signUp, signedUp, signingUp]);

  return !account ? (
    <Navigate to="/signup/registration" replace={true} />
  ) : (
    <div className="max-w-md flex w-full flex-col px-4 py-4">
      {state && (
        <Alert>
          <div className="flex gap-4">
            <ExclamationCircleIcon className="w-10" />
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: state.message }}
            ></p>
          </div>
        </Alert>
      )}

      <small>STEP 1 OF 3</small>

      <h2 title={account?.email} className="font-bold text-3xl">
        {has_account
          ? "Welcome back!"
          : "Create a password to start your membership"}
      </h2>

      <p className="text-xl">
        {has_account
          ? "Enter your password and you'll be watching in no time"
          : "Just a few more steps and you're done!"}
      </p>

      <Form onSubmit={post} className="mt-8">
        <div className="mb-5 flex items-center relative">
          <FloatingInput
            name={has_account ? "email_or_phone" : "phone"}
            inputClassName="bg-zinc-200"
            placeholder="Phone"
            required={true}
            defaultValue={account?.phone}
            readOnly={true}
          />

          <button
            type="button"
            onClick={() =>
              navigate("/signup/registration", {
                state: account,
                replace: true,
              })
            }
            className="absolute right-3 text-blue-700 hover:underline"
          >
            change
          </button>
        </div>

        {account?.email && (
          <div className="mb-5">
            <input type="hidden" name="email" defaultValue={account.email} />
          </div>
        )}

        <PasswordInput
          required={true}
          autoFocus={true}
          inputClassName="bg-zinc-200"
          minLength={4}
          placeholder={has_account ? "Enter password" : "Add a password"}
        />

        <div className="mt-10">
          <SubmitButton isProcessing={processing} className="text-[#e5e5e5]">
            Next
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
}

export default CreateVerifyPassword;
