import { useMemo } from "react";
import { API } from "../imports/utils";

function usePaymentMethods() {
  const { data, isLoading } = API.Get({
    path: "static/methods",
    key: "payment_methods",
  });

  const methods = useMemo(() => {
    return data?.map ? data : [];
  }, [data]);

  return {
    methods,
    fetching: isLoading,
  };
}

export default usePaymentMethods;
