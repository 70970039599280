import React from "react";
import { baseURL } from "../../imports/utils";
import { LinkButton } from "../../imports/components";
import { usePaymentMethods } from "../../imports/hooks";
import { ChevronRightIcon } from "@heroicons/react/outline";

const args = {
  state: {},
  action: "",
  suffix: "",
  root: "/signup/payments",
};

const PaymentMethods = (props = { ...args }) => {
  props = { ...args, ...props };
  const { methods: paymentMethods } = usePaymentMethods();

  return (
    <div className="flex flex-col w-full gap-y-3">
      {paymentMethods?.map &&
        paymentMethods.map((payment) => {
          return (
            <LinkButton
              key={payment.slug}
              href={props.root + payment.url + props.suffix}
              state={{
                ...props.state,
                plan: {
                  ...props.state?.plan,
                  image: baseURL + payment?.["images"][0],
                },
              }}
              className="border p-2 text-truncate text-primary border-primary hover:text-white rounded-[.15rem] hover:bg-primary duration-200 ease-linear"
            >
              <div
                direction="horizontal"
                className="flex items-center justify-between space-x-2"
              >
                <span className="font-semibold">{payment.title}</span>
                <div className="flex items-center gap-x-2 relative">
                  {payment["images"].map((img, key) => {
                    return (
                      <div key={`img-${key}`} className="img-cover">
                        <img
                          src={`${baseURL}${img}`}
                          alt={img}
                          title={payment.title}
                          className="h-4"
                        />
                      </div>
                    );
                  })}
                </div>
                <ChevronRightIcon className="w-8" />
              </div>
            </LinkButton>
          );
        })}
    </div>
  );
};

export default PaymentMethods;
