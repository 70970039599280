import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React, { useRef, useState } from "react";
import { useModalContext } from "../../imports/hooks";
import { MovieCard } from "../../imports/components";

const args = {
  genre: {},
};

const MovieSlider = (props = { ...args }) => {
  const ref = useRef();
  const slider = useRef();
  const { dispatch } = useModalContext();
  const { genre } = { ...args, ...props };

  const [scroll, setScroll] = useState(1);

  const handleExpandClick = () => {
    dispatch({
      type: "SHOW",
      payload: {
        slug: "expand",
        reference: genre,
        content: genre.movies,
      },
    });
  };

  function handleScroll(toRight = true) {
    const { clientWidth, scrollLeft } = slider.current;
    let val = 0;

    if (scroll <= scrollLeft + 1) {
      val = scroll + clientWidth * (toRight ? 1 : -1);
    }

    slider.current.scrollTo({
      top: 0,
      left: val,
      behavior: "smooth",
    });

    setScroll(val);
  }

  return (
    genre.movies.length > 0 && (
      <div className="space-y-2 mb-8">
        <div className="flex group/title">
          <div
            onClick={handleExpandClick}
            className="cursor-pointer group/expand flex items-center relative"
          >
            <h2 className="text-sm md:text-xl lg:text-2xl transition font-semibold mb-1 text-[#e5e5e5] hover:text-white cursor-pointer">
              {genre.title}
            </h2>

            <div className="text-xs font-bold transition-all duration-500 -ml-16 -z-10 opacity-0 sm:group-hover/expand:ml-1 sm:group-hover/expand:opacity-75 sm:group-hover/expand:text-[#6d74e0] ease-in-out">
              Expand All
            </div>

            <ChevronRightIcon className="h-7 w-7 text-[#545bbb] transition-all duration-200 opacity-0 sm:group-hover/title:opacity-100 sm:group-hover/expand:h-5 sm:group-hover/expand:w-5" />
          </div>
        </div>

        <div className="relative group" ref={ref}>
          {scroll > 1 && (
            <div className="slider-arrow -left-1 bg-gradient-left">
              <ChevronLeftIcon
                onClick={() => handleScroll(false)}
                className="arrow"
              />
            </div>
          )}

          <div
            ref={slider}
            className={`flex overflow-x-auto scrollbar-hide overflow-y-hidden w-full min-h-fit gap-1`}
          >
            {genre.movies.map((movie) => {
              return (
                <div
                  className="min-w-[8rem] sm:min-w-[16rem] max-w-min sm:max-w-min"
                  key={`${genre?.title}_${movie.id}`}
                >
                  <MovieCard
                    isWatching={genre?.id === "watching"}
                    movie={movie}
                  />
                </div>
              );
            })}
          </div>

          <div className="slider-arrow -right-1 bg-gradient-right">
            <ChevronRightIcon onClick={handleScroll} className="arrow" />
          </div>
        </div>
      </div>
    )
  );
};

export default MovieSlider;
