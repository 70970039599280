import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef } from "react";
import {
  Alert,
  Form,
  LinkButton,
  PasswordInput,
  SubmitButton,
} from "../imports/components";
import { useModalContext, usePost } from "../imports/hooks";

const VerifyPinDialog = () => {
  const inputRef = useRef(null);
  const {
    show,
    content: profile,
    slug,
    reference,
    dispatch,
  } = useModalContext();
  const {
    post: login,
    state: error,
    processing: logging,
  } = usePost(`profiles/${reference}`, {
    delay: 4000,
    redirect: true,
    toggleOnSuccess: false,
    successCodes: [],
    onSuccess: () => {
      setOpen();
    },
  });
  const { post, state, processing } = usePost("profiles/verify-pin", {
    delay: 4000,
    redirect: false,
    toggleOnSuccess: false,
    successCodes: [201],
    onSuccess: () => {
      doLogin();
    },
  });

  const doLogin = (event) => {
    const data = new FormData();
    data.set("profile", profile?.id);
    login(event, data);
  };

  const handleFormSubmit = (e) => {
    const data = new FormData(e.target);
    data.set("id", profile?.id);
    post(e, data);
  };

  const setOpen = () => {
    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  return (
    <Transition.Root show={show && slug === "verify-pin"} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={inputRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-md text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-md sm:border sm:border-zinc-600">
                <Form onSubmit={handleFormSubmit} className="p-5 space-y-3">
                  <p className="text-2xl">Enter PIN</p>
                  <small>
                    This profile is protected with a PIN. Enter the PIN to
                    continue
                  </small>

                  <button
                    type="button"
                    onClick={setOpen}
                    className="absolute text-shadow-md opacity-75 hover:opacity-100 top-2 right-5 text-white text-lg"
                  >
                    <PlusIcon className="rotate-45 w-6" />
                  </button>

                  <PasswordInput
                    id="name"
                    elementRef={inputRef}
                    autoFocus={true}
                    name="pin"
                    inputClassName="bg-zinc-900 rounded"
                    placeholder="Profile PIN"
                    required={true}
                    feedback="Your PIN must be at least 4 characters"
                  />

                  {(state || error) && (
                    <Alert>{state?.message || error?.message}</Alert>
                  )}

                  <div className="flex justify-between items-center space-x-3">
                    <LinkButton
                      onClick={setOpen}
                      href="/reset-pin"
                      className="text-sm min-w-fit text-orange-400 hover:text-orange-500 hover:underline"
                    >
                      Forgot password?
                    </LinkButton>
                    <SubmitButton
                      isProcessing={processing || logging}
                      className="text-white font-normal max-w-fit px-5 normal-case"
                    >
                      Submit
                    </SubmitButton>
                  </div>
                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VerifyPinDialog;
