import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const args = {
  state: {},
  conditions: [],
  fallback: null,
  redirectIf: true,
  invertCondition: false,
};

const RouteWrapper = (props = { ...args }) => {
  props = { ...args, ...props };

  function exit(fallback) {
    return <Navigate to={fallback} replace={true} state={{ ...props.state }} />;
  }

  if (props.conditions.length > 0 && !props.redirectIf) {
    for (const condition of props.conditions) {
      if (condition.redirectIf) {
        props.redirectIf = condition.redirectIf;
        props.fallback = condition.fallback;
        break;
      }
    }
  }

  if (props.invertCondition) props.redirectIf = !props.redirectIf;

  if (props.redirectIf) return exit(props.fallback);

  return props?.children ? props.children : <Outlet />;
};

export default RouteWrapper;
