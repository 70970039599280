import React, { useMemo, useState } from "react";
import { baseURL, getAbbreviation } from "../../imports/utils";
import { useToggle } from "../../imports/hooks";
import {
  AccountNavigation,
  Alert,
  Footer,
  LinkButton,
  NextButton,
} from "../../imports/components";
import "../../styles/plan-table.css";

const args = {
  data: {
    plan: {},
    plans: [],
    categories: [],
  },
};

function ChoosePlan(props = { ...args }) {
  props = { ...args, ...props };
  const { state, toggle } = useToggle();
  const [planId, setPlanId] = useState(props?.data?.plan?.id);

  const { plans, categories } = props.data;

  const selected = useMemo(() => {
    let _selected;
    if (planId) {
      _selected = plans?.find((p) => p.id === planId);
    } else {
      _selected = plans.find((p) => p.slug === "premium");
    }

    return _selected;
  }, [plans, planId]);

  const handleNextClick = (e) => {
    if (!selected) {
      e.preventDefault();
      toggle({ message: "Please select a plan to continue" });
      return false;
    }
  };

  const getPriceData = (price) => {
    return (
      <p className="m-0 font-extralight truncate">
        <span className="lg:hidden text-sm sm:text-xl">
          ${price && price?.amount}
        </span>
        <span className="hidden lg:inline text-sm sm:text-xl">
          ${price && price?.amount}
        </span>
        /
        <small className="lg:hidden text-xs sm:text-sm">
          {getAbbreviation(price?.period?.toLowerCase())}
        </small>
        <small className="hidden lg:inline text-xs sm:text-sm">
          {price?.period?.toLowerCase()}
        </small>
      </p>
    );
  };

  const getDevice = (device) => {
    return (
      <>
        <img
          src={`${baseURL}${device?.image}`}
          className="h-6"
          alt={device?.name}
        />
        <small>{device?.name}</small>
      </>
    );
  };

  return (
    <div className="flex flex-col min-h-screen items-center justify-center w-full bg-white text-black">
      <AccountNavigation bg="white" useLink={true} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen">
        <div className="min-w-full max-w-full flex-1 flex flex-col items-center pt-20 pb-5">
          <div className="grid grid-cols-12 max-w-full">
            <div className="col-span-10 col-start-2 lg:col-span-8 lg:col-start-3 text-center">
              <small>STEP 2 OF 3</small>
              <h2 className="font-bold text-3xl">
                Choose the plan that is right for you
              </h2>

              <div className="auto-rows-auto grid">
                <div className="plan-row my-8">
                  <div className="cat-col hidden sm:inline"></div>
                  {plans &&
                    !plans?.status &&
                    plans.map((plan) => {
                      return (
                        <div
                          as="button"
                          onClick={() => setPlanId(() => plan.id)}
                          key={`header_${plan.id}`}
                          className={`col-span-1 cursor-pointer rounded-[.15rem] px-2 sm:px-5 lg:px-4 lg:py-3 bg-primary text-white ${
                            selected?.slug === plan?.slug
                              ? "active-header"
                              : "opacity-80"
                          }`}
                        >
                          <div className="plan-header py-4 text-bg-primary">
                            {plan?.title}
                            <br />

                            {getPriceData(plan.price)}
                          </div>
                        </div>
                      );
                    })}
                </div>

                {categories?.map &&
                  categories.map((category, index) => {
                    return (
                      <div
                        key={`category_${index}`}
                        className="plan-row border-b last:border-0 py-3"
                      >
                        <div className="cat-col font-semibold sm:font-normal sm:text-start text-gray-800">
                          {category.title}
                        </div>
                        {plans?.map &&
                          plans.map((plan) => {
                            return (
                              <div
                                key={`${category.id}-${plan.id}`}
                                className={`col-span-1 m-0 ${
                                  selected?.slug === plan.slug
                                    ? "text-primary font-semibold active"
                                    : ""
                                }`}
                              >
                                {category.slug === "devices"
                                  ? plan.meta?.[category.slug].map(
                                      (res, index) => {
                                        return (
                                          <div
                                            gap={1}
                                            className="flex flex-col mb-2"
                                            key={`${res}_${index}`}
                                          >
                                            {getDevice(res)}
                                          </div>
                                        );
                                      }
                                    )
                                  : category.slug === "resolution"
                                  ? `${plan.meta?.[category.slug]?.quality}(${
                                      plan.meta?.[category.slug]?.dimension
                                    })`
                                  : plan.meta[category.slug]}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>

              <div className="text-start text-[1.1rem]">
                <p className="mb-2">
                  <small>
                    SD (480p), HD and Full HD (1080p), Ultra HD (4K) and HDR
                    availability subject to your internet service and device
                    capabilities. Not all content is available in all
                    resolutions. See our{" "}
                    <LinkButton href="/" className="text-green-600 underline">
                      Terms of Use
                    </LinkButton>{" "}
                    for more details.
                  </small>
                </p>

                <p className="mb-5">
                  <small>
                    Only people who live with you may use your account. Watch on
                    4 different devices at the same time with Premium, 2 with
                    Standard, and 1 with Basic and Mobile.
                  </small>
                </p>
              </div>

              <div className="w-full space-y-3">
                {state && <Alert color="red">{state?.message}</Alert>}
                <NextButton
                  replace={true}
                  className="sm:max-w-md"
                  onClick={handleNextClick}
                  state={{ ...props.data, plan: { ...selected } }}
                  href={props?.data?.referrer ?? "/signup/payments"}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer className="border-t mt-auto max-w-full" />
      </div>
    </div>
  );
}

export default ChoosePlan;
