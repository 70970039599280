import { createContext, useReducer } from "react";

const defaultModal = {
  slug: "",
  show: false,
  content: {},
  reference: {},
  dispatch: ({
    type = "",
    payload = {
      slug: "",
      content: {},
      reference: {},
    },
  }) => {},
};
export const ModalContext = createContext(defaultModal);

export default function ModalContextProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, defaultModal);

  return (
    <ModalContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
}

function authReducer(state, action = { type: "", payload: {} }) {
  switch (action.type) {
    case "SHOW":
      return {
        show: true,
        ...action.payload,
      };
    case "HIDE":
      return { ...defaultModal, ...action.payload };
    default:
      return state;
  }
}
