import { useMemo } from "react";
import { API, toMovie } from "../imports/utils";
import useMovies from "./movies-hook";
import { Json } from "../imports/statics";
import useAuth from "./auth-hook";

const args = {
  type: "all",
  includeCategories: [],
  includeMovies: false,
};

function useGenres(props = { ...args }) {
  props = { ...args, ...props };
  const categorised = [];
  const { data: auth } = useAuth();
  const { movies } = useMovies({
    categorised: true,
    type: props.type,
    enabled: props.includeMovies,
  });

  const { data = [], isLoading } = API.Get({
    path: "static/movie-genres",
    key: "genres",
  });

  const genres = useMemo(() => {
    if (!props.includeMovies) {
      return data;
    } else if (!data?.map) {
      return [];
    }

    return data.map((genre) => {
      const moviesInCategory = movies.filter(
        (movie) => movie.genre?.id === genre.id
      );
      return {
        ...genre,
        movies: moviesInCategory,
      };
    });
  }, [data, movies, props.includeMovies]);

  if (props.includeCategories.length > 0) {
    Json.Categories.forEach((genre) => {
      const included = props.includeCategories.includes(genre.id);
      const { data: res = [] } = API.Get(
        {
          path: "movies",
          param: "genre",
          value: genre.id,
          key: [`genre-${genre.id}`, auth?.profile?.name],
        },
        genres?.length > 0 && included
      );
      const exists = categorised?.find((cat) => cat.id === genre.id);

      if (!exists && included) {
        categorised?.push({
          ...genre,
          movies: res.map((movie) => toMovie(movie)),
          title: genre.title.replaceAll("profile", auth?.profile?.name),
        });
      }
    });
  }

  return {
    fetching: props.includeCategories
      ? isLoading && categorised.length > 0
      : isLoading,
    genres: [...categorised, ...genres],
  };
}

export default useGenres;
