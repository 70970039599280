import { useMemo } from "react";
import { API, toMovie } from "../imports/utils";

const args = {
  type: "all",
  enabled: true,
};
function useMovies(props = { ...args }) {
  props = { ...args, ...props };
  const isAll = props.type === "all";

  const { data = [], isLoading } = API.Get({
    key: "movies",
    param: isAll ? null : "type",
    value: isAll ? null : props.type,
    path: `movies${isAll ? "/all" : ""}`,
  });

  const movies = useMemo(
    () => (data?.map ? data.map((movie) => toMovie(movie)) : []),
    [data]
  );

  return {
    movies,
    fetching: isLoading,
  };
}

export default useMovies;
