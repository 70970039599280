import React from "react";
import { AccountNavigation, ReturnToAccount } from "../imports/components";

const args = {
  nav: {
    bg: "black",
    showButton: false,
    showProfile: true,
    useLink: false,
  },
  returnClassName: "",
  className: "",
  mainClassName: "",
  children: null,
  flexClassName: "",
  contentClassName: "",
  showReturn: true,
};

const ManageAccount = (props = { ...args }) => {
  props = { ...args, ...props };

  return (
    <div
      className={`flex flex-col min-h-screen w-full bg-gray-200 justify-center ${props.mainClassName}`}
    >
      <AccountNavigation {...props.nav} />

      <div
        className={`animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black ${props.flexClassName}`}
      >
        <div
          className={`min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4 ${props.contentClassName}`}
        >
          <div
            className={`w-full space-y-4 max-w-5xl md:px-12 pt-8 ${props.className}`}
          >
            {props.showReturn && (
              <ReturnToAccount className={props.returnClassName} />
            )}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;
