import { PencilIcon } from "@heroicons/react/outline";
import React, { useMemo } from "react";
import { useModalContext, usePost } from "../../imports/hooks";
import { Images } from "../../imports/statics";

const ProfileCard = ({ profile, isEdit = false, clientKey = "profiles" }) => {
  const { dispatch } = useModalContext();
  const selected = useMemo(() => {
    return profile;
  }, [profile]);

  const { post } = usePost("accounts/set-profile", {
    delay: 3000,
    redirect: true,
    successCodes: [],
    showProcessing: true,
  });

  const doPost = (event) => {
    if (!isEdit) {
      const data = new FormData();
      data.set("profile", profile ? profile?.id : selected?.id);
      post(event, data);
    }
  };

  const handleProfileClicked = (e) => {
    if (profile) {
      if (!isEdit) {
        if (profile?.has_secret) {
          dispatch({
            type: "SHOW",
            payload: {
              content: profile,
              reference: "set-watching",
              slug: "verify-pin",
            },
          });
        } else {
          doPost(e);
        }
      } else {
        dispatch({
          type: "SHOW",
          payload: {
            content: profile,
            reference: profile,
            slug: "edit-profile",
          },
        });
      }
    } else {
      dispatch({
        type: "SHOW",
        payload: {
          content: profile,
          reference: clientKey,
          slug: "add-profile",
        },
      });
    }
  };

  return (
    <button
      type="button"
      onClick={handleProfileClicked}
      className="w-full group h-ful max-w-sm flex flex-col items-center"
    >
      <h5 className="text-white text-sm sm:text-base w-full text-truncate">
        {profile?.name || "Add New"}
      </h5>
      <div
        style={{ backgroundColor: profile?.avatar?.color }}
        className="group-hover:border transition-all duration-200 ease-in rounded relative"
      >
        <img
          src={profile ? Images.Profile : Images.Plus}
          alt={profile?.id}
          className="profile-img"
        />

        {isEdit && (
          <div
            className={` absolute top-0 rounded bg-black/50 hover:bg-black/75 w-full h-full flex items-center justify-center`}
          >
            <PencilIcon className="w-7" />
          </div>
        )}
      </div>
    </button>
  );
};

export default ProfileCard;
