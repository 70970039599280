import React from "react";
import { LinkButton } from "../../imports/components";
import { BsArrowLeft } from "react-icons/bs";

const ReturnToAccount = (props = { className: "" }) => {
  return (
    <LinkButton
      href="/account"
      className={`hover:underline hidden sm:flex text-blue-800 items-center space-x-1 font-semibold ${props?.className}`}
    >
      <BsArrowLeft className="text-xl" />
      <span className={`text-sm ${props?.textClassName}`}>
        {props?.children || "Back to account"}
      </span>
    </LinkButton>
  );
};

export default ReturnToAccount;
