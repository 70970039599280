import React, { useLayoutEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import usePost from "../../hooks/post-hook";
import {
  AccountNavigation,
  Alert,
  LinkButton,
  Loader,
  SubmitButton,
} from "../../imports/components";
import API from "../../utils/api";

function Review() {
  const { state: data } = useLocation();
  const [card, setCard] = useState({});

  const { data: subscription } = API.Get({
    path: "subscriptions/fetch-logged",
    key: "sub-logged",
  });

  const plan = useMemo(() => {
    return data?.plan || subscription?.plan;
  }, [subscription, data]);

  const { post, state, processing } = usePost("subscriptions/card/renew", {
    redirect: true,
    successCode: 200,
    clientKey: "auth",
  });

  async function handleFetchPlans() {
    const categories = await API.Fetch({ path: "categories", param: "all" });
    const plans = await API.Fetch({ path: "plans", param: "all" });

    return { categories, plans, plan };
  }

  useLayoutEffect(() => {
    API.Fetch({
      path: `subscriptions/card/details?ref=${subscription?.ref}`,
    }).then((data) => {
      if (!data?.error) {
        setCard(data);
      }
    });
  }, [subscription]);

  return !card?.brand ? (
    <Loader />
  ) : (
    <div className="flex flex-col min-h-screen w-full bg-white text-black">
      <AccountNavigation bg="white" useLink={true} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen">
        <div className="min-w-full max-w-full flex-1 flex flex-col items-center pt-20 pb-5">
          <div className="w-full max-w-2xl flex flex-col justify-center py-8 default-padding">
            {state && (
              <Alert color="rose" className="mb-2">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: state.message }}
                ></p>
              </Alert>
            )}

            <>
              <small>STEP 2 OF 2</small>
              <h2 className="font-bold mb-4 text-xl sm:text-2xl lg:text-3xl">
                Review your details
              </h2>
            </>

            <div className="bg-gray-200 flex justify-between py-2 px-3 text-bg-light justify-content-between text-start mb-4">
              <div className="">
                <span className="text-2xl font-semibold">
                  <span>${plan?.price?.amount}</span>
                  <small>/{plan?.price?.period?.toLowerCase()}</small>
                </span>
                <p className="m-0">{plan?.title} Plan</p>
              </div>
              <LinkButton
                onClick={handleFetchPlans}
                href="/choose-plan?referer=/account/review-payment"
                className="font-bold text-blue-600 hover:underline"
              >
                Change
              </LinkButton>
            </div>

            {subscription?.payment_type === "card" && (
              <div className="bg-gray-200 flex justify-between py-2 px-3 text-bg-light justify-content-between text-start mb-8">
                <div className="">
                  <span className="text-2xl font-semibold">
                    <span className="capitalize">{card?.brand}</span> ending
                    with {card?.last_four}
                  </span>
                  <p className="m-0">{card?.name}</p>
                </div>
              </div>
            )}

            <small className="text-gray-700/80">
              By checking the button below, you agree to our{" "}
              <a
                href="/"
                className="text-secondary underline hover:text-green-600"
              >
                Terms of Use
              </a>
              ,{" "}
              <a
                href="/"
                className="text-secondary underline hover:text-green-600"
              >
                Privacy Statement
              </a>
              , and that you are over 18. Sierraflixx will automatically
              continue your membership and charge the membership fee (currently
              ${plan?.price?.amount}/{plan?.price?.period?.toLowerCase()}) to
              your payment method until you cancel. You may cancel at any time
              to avoid future charges.
            </small>

            <form onSubmit={post}>
              <input
                type="hidden"
                name="plan"
                defaultValue={plan?.id}
                required
              />
              <input
                type="hidden"
                name="ref"
                defaultValue={subscription?.ref}
                required
              />
              <input
                type="hidden"
                name="subscription"
                defaultValue={subscription?.id}
                required
              />

              <SubmitButton
                size="lg"
                isProcessing={processing}
                className="w-100 mt-2 text-white"
              >
                START MEMBERSHIP
              </SubmitButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
