import { Dialog, Transition } from "@headlessui/react";
import { PlayIcon, PlusIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useModalContext } from "../imports/hooks";
import {
  MoreInfoButton,
  MovieCard,
  PlayMovieButton,
} from "../imports/components";
import { ChevronDownIcon } from "@heroicons/react/solid";

const ExpandDialog = () => {
  const cancelButtonRef = useRef(null);
  const [movies, setMovies] = useState([]);
  const { show, content, slug, reference, dispatch } = useModalContext();

  const setOpen = () => {
    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  useEffect(() => {
    if (slug === "expand") {
      setMovies(content);
    }
  }, [content, slug]);

  return (
    <Transition.Root show={show && slug === "expand"} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60] max-w-full"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[.6] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-6xl border border-[#161616f6]">
                <div className="flex flex-col space-y-8 py-4 default-padding">
                  <h1 className="text-2xl sm:text-5xl font-bold py-14 text-center">
                    {reference?.title}
                  </h1>

                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[0.3em]">
                    {movies?.map((movie) => {
                      return (
                        <div
                          key={`expand_${movie.id}`}
                          className="col-span-1 group"
                        >
                          <MovieCard
                            movie={movie}
                            maxWidth="full"
                            defaultClick={false}
                            isWatching={reference?.id === "watching"}
                          >
                            {reference?.id !== "watching" && (
                              <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-0 flex bg-[#161616a6] w-full h-full items-center justify-center transition duration-400 ease-linear space-x-3">
                                <PlayMovieButton
                                  movieId={movie.id}
                                  removeDefaultClass={true}
                                  className="rounded-button !h-10 !w-10 !p-0 !border-0 opacity-75 hover:opacity-100"
                                >
                                  <PlayIcon className="h-10 w-10" />
                                </PlayMovieButton>
                                <MoreInfoButton
                                  playerRef={{}}
                                  movie={movie}
                                  className="rounded-button !p-0 hover:bg-[#99999944] !h-8 !w-8"
                                >
                                  <ChevronDownIcon className="h-full w-full" />
                                </MoreInfoButton>
                              </div>
                            )}
                          </MovieCard>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <button
                  onClick={setOpen}
                  className="absolute text-shadow-md opacity-75 hover:opacity-100 top-2 right-2 rounded-button text-lg !border-0"
                >
                  <PlusIcon className="rotate-45" />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ExpandDialog;
