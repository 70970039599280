import React from "react";
import { API } from "../../imports/utils";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/outline";
import { NextButton } from "../../imports/components";

function PlanIntro() {
  const { data: plans = [], isLoading: planning } = API.Get({
    path: "static/plans/all",
  });
  const { data: categories = [], isLoading: categorising } = API.Get({
    path: "static/categories",
  });

  return (
    <div className="max-w-sm flex w-full flex-col px-4 py-4 items-center">
      <CheckCircleIcon className="w-16 text-primary" />
      <p className="mt-4 mb-0">
        <small>STEP 2 OF 3</small>
      </p>
      <h2 className="font-bold text-3xl">Choose your plan.</h2>

      <div className="mt-6 mb-14">
        <div className="flex gap-2 items-center">
          <CheckIcon className="w-8 text-primary" />
          <span className="text-xl">No commitments, cancel anytime.</span>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIcon className="w-8 text-primary" />
          <span className="text-xl">
            Everything on Sierraflixx for one low price.
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIcon className="w-8 text-primary" />
          <span className="text-xl">No ads and no extra fees. Ever!</span>
        </div>
      </div>

      <NextButton
        href="/signup/plan/choose"
        state={{ plans, categories }}
        processing={planning || categorising}
      />
    </div>
  );
}

export default PlanIntro;
