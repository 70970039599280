const storage = {
  path: {
    local: localStorage,
    session: sessionStorage,
  },
  setData: (key, data, path) => {
    data = btoa(JSON.stringify(data));

    return path?.setItem(key, data);
  },
  getData: (key, path) => {
    let data = path?.getItem(key);

    return data ? JSON.parse(atob(data)) : data;
  },
  unSetData: (key, path) => {
    return path?.removeItem(key);
  },
};

export default storage;
