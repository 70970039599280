import React, { useRef, useState } from "react";
import { useLayoutEffect } from "react";
import { CountriesDropdown, FloatingInput } from "../../imports/components";

const args = {
  required: false,
  autoFocus: false,
  id: "uid",
  name: "uid",
  feedback: "",
  className: "",
  inputClassName: "",
  defaultValue: "",
  setCode: () => {},
  setEmail: () => {},
};

const EmailPhoneInput = (props = { ...args }) => {
  props = { ...args, ...props };
  const ref = useRef();
  const [isEmail, setIsEmail] = useState(true);

  const inputChanges = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const not_number = value.length <= 0 || isNaN(value);

    props.setEmail(not_number);
    setIsEmail(not_number);
  };

  useLayoutEffect(() => {
    if (!isNaN(props.defaultValue) && props?.defaultValue?.length > 0) {
      props.setEmail(false);
      setIsEmail(false);
    }
  }, [props]);

  return (
    <div className={`flex relative flex-col w-full ${props.className}`}>
      <div className="flex items-center w-full relative">
        <FloatingInput
          elementRef={ref}
          name={props.name}
          id={props.id}
          feedback={props.feedback}
          defaultValue={props.defaultValue}
          placeholder="Email or phone number"
          inputClassName={props.inputClassName}
          labelClassName="bg-inherit text-inherit"
          autoFocus={props.autoFocus}
          required={props.required}
          onChange={inputChanges}
        />

        {!isEmail && (
          <CountriesDropdown
            elementRef={ref}
            dropdownClassName="mt-5"
            setCode={props.setCode}
          />
        )}
      </div>
    </div>
  );
};

export default EmailPhoneInput;
