import React, { useState, useMemo } from "react";
import { API } from "../../imports/utils";
import {
  AccountNavigation,
  AddCard,
  CommonDialog,
  LinkButton,
  Loader,
  Alert,
} from "../../imports/components";
import { useDelete, useUpdate } from "../../imports/hooks";
import { BiLock, BiPlus } from "react-icons/bi";
import { toFormData } from "axios";

const ManagePaymentInfo = () => {
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState({});
  const [selected, setSelected] = useState({});

  const path = "subscriptions/card";
  const { data: methods, isLoading } = API.Get({
    key: "payment-methods",
    path: `${path}/payment-methods`,
  });

  const { update, updated, toggle } = useUpdate(`${path}/set-preferred`, {
    param: "token",
    value: selected.id,
    showProcessing: true,
    clientKey: "payment-methods",
  });

  const { remove, removed } = useDelete(path, {
    param: "id",
    value: selected.id,
    showProcessing: true,
    toggleOnSuccess: true,
    clientKey: "payment-methods",
  });

  function setResponse(response) {
    toggle(response, true);
  }

  const state = useMemo(() => updated ?? removed, [updated, removed]);

  function handleClick(method, action = "delete") {
    setShow(true);
    setSelected(() => method ?? {});

    switch (action) {
      case "delete":
        setConfirm({
          action,
          content: "confirm",
          callback: () => {
            setShow(false);
            remove({
              value: method.id,
              param: "id",
            });
          },
        });
        break;
      case "default":
        setConfirm({
          action,
          content: "confirm",
          callback: () => {
            setShow(false);
            update(null, toFormData({ token: method.id }));
          },
        });
        break;
      case "create":
        setConfirm({
          action,
          content: "form",
          callback: () => setShow(false),
        });
        break;
      default:
        return false;
    }
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="relative bg-gray-50 min-w-screen min-h-screen">
        <AccountNavigation
          position="relative"
          bg="transparent"
          useLink={true}
          className="text-black"
        />

        <div className="animate__animated animate__fadeIn px-4 flex flex-col text-black items-center min-w-full justify-center py-8">
          <div className="text-center flex flex-col items-center space-y-4 mb-4">
            <BiLock className="text-4xl md:text-6xl rounded-full p-2 text-primary border-4 border-primary" />

            <h1 className="text-xl font-bold text-center">
              Manage Payment Information
            </h1>

            <span className="text-md">
              Edit your payment details, add a backup, or switch your preferred
              payment method.
            </span>
          </div>

          <div className="max-w-xl w-full my-4">
            {state?.success && (
              <Alert isSuccess={state.success}>{state.message}</Alert>
            )}

            {methods.length > 0 ? (
              methods.map((method) => {
                return (
                  <div
                    key={method.id}
                    className="py-5 grid grid-cols-4 justify-between items-center border-b border-gray-300"
                  >
                    <div className="flex flex-col col-span-2">
                      <span>
                        {method.brand.slice(0, 4)} **** **** **** {method.last4}
                      </span>
                      <small className="text-orange-400">
                        {method.is_expired
                          ? "Card has expired"
                          : "Expires " + method.expires}
                      </small>
                    </div>
                    <div className="flex justify-center">
                      {method.is_default ? (
                        <span className="uppercase font-semibold text-xs px-4 py-1 border rounded-md text-gray-600 border-gray-600">
                          preferred
                        </span>
                      ) : (
                        <button
                          onClick={() => handleClick(method, "default")}
                          className="text-primary sm:text-md hover:underline"
                        >
                          Make Preferred
                        </button>
                      )}
                    </div>
                    <div className="flex justify-end">
                      {!method.is_default && (
                        <button
                          className="text-rose-600"
                          onClick={() => handleClick(method)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col items-center justify-center border p-4 bg-orange-100">
                <span className="font-semibold text-orange-500">
                  You seem to have no card as payment method. Add one now!
                </span>
              </div>
            )}
            {methods?.length === 1 && (
              <span className="text-xs font-light text-zinc-400">
                You have only one payment method. Add another to remove this
              </span>
            )}

            <div className="flex flex-col mt-8 items-center space-y-8">
              <button
                onClick={() => handleClick(null, "create")}
                className="border border-primary text-primary px-5 py-3 rounded-sm hover:bg-gray-200"
              >
                <BiPlus className="w-4 h-4 fill-current inline" />

                <span className="ml-2">Add Payment Card</span>
              </button>

              <LinkButton
                href="/account"
                className="text-blue-500 hover:underline"
              >
                Return to Account
              </LinkButton>
            </div>
          </div>
        </div>
      </div>

      <CommonDialog
        show={show}
        bg="bg-black/50"
        className="bg-white"
        setShow={() => setShow(false)}
      >
        {confirm.content === "confirm" ? (
          <Confirm action={confirm} closeDialog={() => setShow(false)} />
        ) : (
          <AddCard
            setResponse={setResponse}
            setDialog={() => setShow(false)}
            enabled={confirm.action === "create"}
          />
        )}
      </CommonDialog>
    </>
  );
};

function Confirm({ action = {}, closeDialog = () => {} }) {
  return (
    <div className="flex flex-col">
      <h4 className="font-semibold text-lg text-secondary mb-3">
        Confirm {action.action}
      </h4>
      <p className="text-zinc-500 text-[1rem]">
        Are you sure you want to <strong>{action.action}</strong> this payment
        method?
      </p>
      <div className="flex space-x-4 mt-4 justify-end">
        <button
          onClick={action.callback}
          className="text-red border px-3 py-1 text-sm rounded border-red"
        >
          Yes
        </button>
        <button
          onClick={closeDialog}
          className="text-primary border px-3 py-1 text-sm rounded border-primary"
        >
          No, Cancel
        </button>
      </div>
    </div>
  );
}

export default ManagePaymentInfo;
