import React from "react";

const args = {
  name: "",
  id: "",
  type: "",
  className: "",
  required: false,
  autoFocus: false,
  placeholder: "",
  labelClassName: "",
  inputClassName: "",
  elementRef: null,
  defaultValue: "",
  feedBackClassName: "",
  readOnly: false,
  onChange: () => {},
  onKeyDown: () => {},
  label: "Field",
  feedback: null,
  minLength: 0,
};

const FloatingInput = (props = { ...args }) => {
  props = { ...args, ...props };

  return (
    <div className={`relative min-w-full ${props.className}`}>
      <input
        id={props.id}
        name={props.name}
        type={props.type}
        placeholder=" "
        minLength={props.minLength}
        ref={props.elementRef}
        readOnly={props.readOnly}
        required={props.required}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        defaultValue={props.defaultValue}
        className={`block px-2.5 pt-5 pb-2.5 w-full appearance-none focus:outline-none focus:ring-0 peer ${props.inputClassName}`}
      />
      <label
        htmlFor={props.id}
        className={`absolute transform duration-200 -translate-y-4 scale-[.9] opacity-50 top-[1.1rem] z-10 origin-[0] left-2.5  peer-focus:opacity-50 peer-placeholder-shown:scale-100 peer-placeholder-shown:opacity-[.9] peer-placeholder-shown:top-[.9rem] peer-placeholder-shown:translate-y-0 peer-focus:scale-[.9] peer-focus:-translate-y-4 peer-focus:top-[1.1rem] ${props.labelClassName}`}
      >
        {props.placeholder}
      </label>

      <p
        className={`feedback hidden py-0.5 text-xs text-orange-700 font-bold ${props.feedBackClassName}`}
      >
        {props.feedback ?? `${props.label} is required`}
      </p>
    </div>
  );
};

export default FloatingInput;
