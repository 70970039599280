import React, { useState } from "react";
import { usePost } from "../../../imports/hooks";
import { API } from "../../../imports/utils";
import { Alert, LinkButton, SubmitButton } from "../../../imports/components";
import { Form, Loader } from "../../../imports/components";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";

const args = {
  plan: {},
  isSignUp: true,
  caption: "Start Membership",
};

function Pay(props = { ...args }) {
  const { data = {}, isLoading } = API.Get({
    key: "init-card",
    path: `subscriptions/card/init-card`,
  });

  return isLoading ? (
    <Loader />
  ) : (
    <div className="w-full max-w-lg flex flex-col justify-center items-center py-8 default-padding">
      <div className="text-start w-full mb-6">
        <small>STEP {props.isSignUp ? "3 OF 3" : "2 of 2"}</small>

        <h2 className="flex text-truncate flex-wrap space-y-1 space-x-2 text-xl sm:text-2xl lg:text-3xl items-center">
          <span>Pay with a new</span>{" "}
          <span className="text-secondary font-extrabold">Card</span>
        </h2>
        <p className="text-[.9rem]">
          Your card will only be charged for this payment and will not be saved
          for future payments. Except you choose to save it.
        </p>
      </div>

      <Elements
        stripe={loadStripe(data.key)}
        options={{
          appearance: {
            theme: "stripe",
            variables: {
              borderRadius: "0.15rem",
              focusBoxShadow: "none",
              colorPrimary: "#00862d",
            },
          },
        }}
      >
        <CardForm props={{ ...props }} />
      </Elements>
    </div>
  );
}

function CardForm({ props = { ...args } }) {
  const { plan } = { ...args, ...props };
  const stripe = useStripe();
  const elements = useElements();
  const [save, setSave] = useState(false);
  const [preferred, setPreferred] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    post,
    state,
    processing: posting,
    toggle,
  } = usePost("subscriptions/card/pay", {
    redirect: true,
    toggleOnSuccess: false,
  });

  const options = {
    classes: {
      focus: "border-primary/80",
      base: "border p-2 border-secondary/80 w-full",
    },
    style: {
      base: {
        color: "#000",
        "::placeholder": {
          color: "#a0aec0",
        },
      },
    },
  };

  async function handleSubmit(e) {
    if (!stripe || !elements) return;
    setProcessing(true);
    const { error, paymentMethod } = await stripe
      .createPaymentMethod({
        type: "card",
        billing_details: {
          name: e.target.name.value,
        },
        card: elements.getElement(CardNumberElement),
        card: elements.getElement(CardExpiryElement),
        card: elements.getElement(CardCvcElement),
      })
      .finally(() => setProcessing(false));

    if (error) {
      toggle({ message: error.message, success: false }, true, 6000);
      return;
    } else {
      const data = new FormData(e.target);
      data.append("token", paymentMethod.id);

      post(null, data);
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col w-full bg-zinc-100 px-2 py-1 border border-zinc-600 rounded-sm">
        <span>{plan?.title} Plan</span>
        <span className="text-xl font-semibold">
          ${plan?.price?.amount}
          <small>/{plan?.price?.period?.toLowerCase()}</small>
        </span>
      </div>

      <Form
        noValidate={true}
        onSubmit={handleSubmit}
        className="flex-col flex gap-4 text-black w-full"
      >
        <fieldset className="flex-col flex">
          <label htmlFor="name" className="text-zinc-800">
            Name on Card
          </label>
          <input
            type="text"
            name="name"
            id="name"
            required={true}
            autoFocus={true}
            placeholder="Idrissa Barry"
            className="border outline-none px-2 py-1 text-[1rem] border-secondary/80 focus:border-primary/80 placeholder-[#a0aec0]"
          />
          <small className="feedback hidden text-rose-500">
            Name on card is required
          </small>
        </fieldset>

        <fieldset className="flex flex-col">
          <label htmlFor="name" className="text-zinc-800">
            Card Number
          </label>
          <CardNumberElement options={{ showIcon: true, ...options }} />
        </fieldset>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <fieldset className="flex flex-col">
            <label htmlFor="name" className="text-zinc-800">
              Expiration Date
            </label>
            <CardExpiryElement options={options} />
          </fieldset>

          <fieldset className="flex flex-col">
            <label htmlFor="name" className="text-zinc-800">
              CVC
            </label>
            <CardCvcElement options={{ ...options, placeholder: "000" }} />
          </fieldset>
        </div>

        <div className="flex flex-col text-zinc-600">
          <input type="hidden" name="plan" value={plan?.id} />
          <button
            type="button"
            onClick={() => setSave((pre) => !pre)}
            className="flex items-center"
          >
            <div className="text-4xl">
              {save ? <BiCheckboxChecked /> : <BiCheckbox />}
            </div>

            <span className="text-xs sm:text-base text-black">
              Save this card for future payments
            </span>
            <input type="hidden" name="add" value={save} />
          </button>

          {save && (
            <button
              type="button"
              onClick={() => setPreferred((pre) => !pre)}
              className="flex items-center cursor-pointer"
            >
              <div className="text-4xl">
                {preferred ? <BiCheckboxChecked /> : <BiCheckbox />}
              </div>

              <span className="text-xs sm:text-base text-black">
                Set as default payment method
              </span>

              <input
                type="hidden"
                name="is_default"
                value={save && preferred}
              />
            </button>
          )}
        </div>

        <div className="flex flex-col space-y-5">
          <small className="text-gray-700/80 text-justify">
            By clicking the button below, you agree to our{" "}
            <a
              href="/"
              className="text-blue-800 underline hover:text-green-600"
            >
              Terms of Use
            </a>
            ,{" "}
            <a
              href="/"
              className="text-blue-800 underline hover:text-green-600"
            >
              Privacy Statement
            </a>
            , and that you are over 18. Sierraflixx will notify you to continue
            your membership and charge the membership fee (currently $
            {plan?.price?.amount}/{plan?.price?.period?.toLowerCase()}) to every
            payment until you cancel. You may cancel at any time to avoid future
            notifications.
          </small>
        </div>

        {state && (
          <Alert color={state.success ? "blue" : "rose"}>{state.message}</Alert>
        )}

        <SubmitButton
          isProcessing={processing || posting}
          className="text-lg text-white mt-4"
        >
          {props.caption}
        </SubmitButton>
      </Form>

      <LinkButton
        href={-1}
        className="text-secondary font-semibold hover:underline"
      >
        Return to previous step
      </LinkButton>
    </div>
  );
}

export default Pay;
