import React, { useMemo, useState } from "react";
import usePost from "../../hooks/post-hook";
import {
  AccountNavigation,
  Alert,
  ChangePlan,
  LinkButton,
  Loader,
  NextButton,
  SubmitButton,
} from "../../imports/components";
import { CreditCardIcon } from "@heroicons/react/outline";
import { API, baseURL } from "../../imports/utils";
import { usePaymentMethods } from "../../imports/hooks";

function Subscription() {
  const [newPlan, setNewPlan] = useState(null);
  const [showChangePlan, setShowChangePlan] = useState(false);

  const { methods } = usePaymentMethods();

  const { data = {}, isLoading } = API.Get({
    path: "subscriptions/current",
    key: "sub-logged",
  });

  const subscription = useMemo(() => {
    return data && !data?.message ? data : {};
  }, [data]);

  const plan = useMemo(() => {
    return subscription.plan ?? {};
  }, [subscription]);

  const method = useMemo(() => {
    return subscription.payment?.methods?.[0] ?? {};
  }, [subscription]);

  const { post, state, processing } = usePost("subscriptions/card/renew", {
    redirect: true,
    successCode: 200,
    clientKey: "auth",
  });

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col min-h-screen w-full bg-white text-black">
      <AccountNavigation bg="white" useLink={true} />

      <div className="flex animate__animated animate__fadeIn flex-col min-w-full max-w-full min-h-screen">
        <div className="min-w-full max-w-full flex-1 flex flex-col items-center pt-20 pb-5">
          <div className="w-full max-w-xl flex flex-col justify-center py-8 default-padding">
            {state && (
              <Alert color="rose" className="mb-2">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: state.message }}
                ></p>
              </Alert>
            )}

            <div className="mb-4">
              {subscription.payment_type !== "card" && (
                <small>STEP 1 OF 2</small>
              )}
              <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl">
                Renew your Subscription
              </h2>
              <p className="text-[.9rem]">
                We encountered an issue auto renewing your subscription. Please
                renew now to continue watching...!
              </p>
            </div>

            <div className="text-bg-light justify-content-between text-start mb-4 space-y-4">
              <div className="bg-gray-200 py-2 px-3">
                <div className="grid grid-cols-1 sm:grid-cols-3 items-end justify-between gap-5 py-2">
                  <div className="flex flex-col sm:col-span-2">
                    <div className="flex-col flex">
                      <span className="text-zinc-500 font-semibold">
                        Current plan (
                        <span className="text-secondary">{plan?.title}</span>)
                      </span>
                      <span className="text-2xl font-semibold">
                        <span>${plan?.price?.amount}</span>
                        <small>/{plan?.price?.period?.toLowerCase()}</small>
                      </span>
                    </div>

                    {newPlan && (
                      <div className="flex flex-col border-t border-secondary/30 mt-2 pt-2">
                        <span className="text-zinc-500 font-semibold">
                          New plan (
                          <span className="text-secondary">
                            {newPlan?.title}
                          </span>
                          )
                        </span>
                        <span className="text-2xl font-semibold">
                          <span>${newPlan?.price?.amount}</span>
                          <small>
                            /{newPlan?.price?.period?.toLowerCase()}
                          </small>
                        </span>
                      </div>
                    )}
                  </div>

                  {subscription?.payment_type === "card" &&
                    subscription?.is_expired && (
                      <div className="flex justify-start sm:justify-end">
                        <button
                          className="border border-secondary px-3 py-1 w-full h-fit sm:w-fit bg-zinc-100 font-semibold"
                          onClick={() => setShowChangePlan(true)}
                        >
                          Change Plan
                        </button>
                      </div>
                    )}
                </div>
              </div>

              <div className="bg-gray-200 py-2 px-3 flex flex-col">
                <span className="text-zinc-500 font-semibold">
                  Current payment method
                </span>
                <span className="text-2xl font-semibold capitalize">
                  {subscription?.payment_type?.replaceAll("-", " ")}
                </span>
                {subscription?.payment_type === "card" && (
                  <div className="flex space-x-2">
                    <CreditCardIcon className="w-6" />
                    <span>•••• •••• ••••</span>
                    <span>
                      {method?.card
                        ? method?.card?.last_four
                        : method?.last_four}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {subscription?.payment_type === "card" ? (
              <form onSubmit={post}>
                <input
                  type="hidden"
                  name="sid"
                  defaultValue={subscription.id}
                  required
                />

                <input
                  type="hidden"
                  name="plan"
                  defaultValue={newPlan ? newPlan.id : plan.id}
                  required
                />

                <SubmitButton
                  size="lg"
                  isProcessing={processing}
                  className="mt-2 text-white px-4 !bg-secondary"
                >
                  RENEW SUBSCRIPTION
                </SubmitButton>
              </form>
            ) : (
              <NextButton
                href={`/account/payment/${subscription.payment_type}`}
                state={{
                  isSignUp: false,
                  plan: newPlan ?? plan,
                  caption: "RENEW SUBSCRIPTION",
                }}
                className="!bg-secondary"
              >
                {subscription.payment_type !== "card"
                  ? "RENEW SUBSCRIPTION"
                  : "CONTINUE TO PAYMENT"}
              </NextButton>
            )}

            <div className="flex flex-col space-y-4 w-full py-3 items-center">
              <div className="flex w-full justify-center items-center">
                <span className="border-b border-secondary w-10"></span>
                <span className="mx-4 font-semibold text-zinc-500">OR</span>
                <span className="border-b border-secondary w-10"></span>
              </div>

              <div className="flex justify-center gap-3">
                {methods?.filter &&
                  methods
                    .filter((m) =>
                      subscription.payment_type !== "card"
                        ? m.slug !== subscription.payment_type
                        : true
                    )
                    .map((method) => (
                      <LinkButton
                        key={method.slug}
                        state={{
                          isSignUp: false,
                          plan: newPlan ?? plan,
                          caption: "RENEW SUBSCRIPTION",
                        }}
                        href={`/account/payment${method.url}`}
                        className="border flex flex-col flex-wrap shadow-md items-center gap-2 justify-center px-3 py-2 rounded-md text-center w-full sm:w-fit text-zinc-500 transition-all duration-150 ease-linear hover:scale-[1.02] hover:bg-zinc-100 hover:text-black"
                      >
                        <div className="flex gap-3">
                          {method.images.map((image, index) => (
                            <img
                              key={`image_${method.slug}_${index}`}
                              src={baseURL + image}
                              alt={method.title}
                              width={25}
                            />
                          ))}
                        </div>

                        <span className="text-xs font-semibold ">
                          Pay with {method.slug === "card" ? "a new " : ""}
                          {method.title}
                        </span>
                      </LinkButton>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {subscription?.payment_type === "card" && subscription?.is_expired && (
        <ChangePlan
          show={showChangePlan}
          current={subscription}
          setShow={setShowChangePlan}
          setNew={(_new) => setNewPlan(_new?.id === plan.id ? null : _new)}
        />
      )}
    </div>
  );
}

export default Subscription;
