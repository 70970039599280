import React from "react";
import { FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../imports/hooks";

const args = {
  key: null,
  movieId: "",
  className: "",
  isWatched: false,
  removeDefaultClass: false,
};
const PlayMovieButton = (
  props = {
    ...args,
  }
) => {
  const navigate = useNavigate();
  const { show, dispatch } = useModalContext();
  const { key, movieId, className, removeDefaultClass } = { ...args, ...props };

  const handlePlayClicked = () => {
    if (show) {
      dispatch({
        type: "HIDE",
        payload: {},
      });
    }
    navigate(`/watch?tkn=${movieId}&ref=${Date.now()}`);
  };

  return (
    <button
      key={key}
      onClick={handlePlayClicked}
      className={
        removeDefaultClass
          ? className
          : `p-2 md:px-5 md:py-4 banner-button bg-primary ${className} transition-all duration-150 ease-in-out`
      }
    >
      {props?.children ?? (
        <>
          <FaPlay className="icon" />

          <span>{props.isWatched ? "Resume" : "Play"}</span>
        </>
      )}
    </button>
  );
};

export default PlayMovieButton;
