import React, { useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import useToggle from "../../hooks/toggle-hook";
import {
  Alert,
  FloatingInput,
  Form,
  PhoneInput,
  SubmitButton,
} from "../../imports/components";
import { API, getPhoneNumber } from "../../imports/utils";

function RegisterPhoneEmail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const { state: error, toggle } = useToggle();
  const { mutate, isLoading: processing } = useMutation(API.Post, {
    onSuccess: (res) => {
      if (res?.message) {
        toggle(res, true);
        return;
      }
      navigate("/signup/password", { state: res });
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    data.set("phone", getPhoneNumber(code, data.get("phone")));

    if (data.get("email").length <= 0) {
      data.delete("email");
    }

    mutate({
      data,
      path: "accounts/fetch",
    });
  };

  return (
    <div className="max-w-md flex w-full flex-col px-4">
      <p className="mt-4 mb-0">
        <small>STEP 1 OF 3</small>
      </p>

      <h2 className="font-bold text-3xl mb-3">
        Create an account to start your membership
      </h2>

      <p className="text-xl mb-10">
        Just a few more steps and you're done! <br />
        We hate paperwork, too.
      </p>

      {error && (
        <Alert color="rose" className="mb-2">
          <p
            className="m-0"
            dangerouslySetInnerHTML={{ __html: error.message }}
          ></p>
        </Alert>
      )}

      <Form onSubmit={handleFormSubmit}>
        <div className="space-y-4 mb-10">
          <PhoneInput
            required={true}
            autoFocus={true}
            setCode={setCode}
            feedback="Please enter a valid phone number."
            defaultValue={state?.phone?.slice(1) || ""}
          />

          <FloatingInput
            id="email"
            name="email"
            defaultValue={state?.email}
            inputClassName="bg-zinc-200"
            placeholder="Email (Optional)"
          />
        </div>

        <SubmitButton isProcessing={processing} className="text-white py-3">
          Next
        </SubmitButton>
      </Form>
    </div>
  );
}

export default RegisterPhoneEmail;
