import { useMemo } from "react";
import { API } from "../imports/utils";
import useAuth from "./auth-hook";

const args = {
  movieId: "",
  enabled: false,
};
function useWatched(props = { ...args }) {
  props = { ...args, ...props };
  const { data: user = {} } = useAuth();

  const { data = {}, isLoading } = API.Get(
    {
      key: ["watched", props.movieId, user?.profile?.name],
      path: `profiles/get-watched?movie_id=${props.movieId}`,
    },
    props.enabled
  );

  const watched = useMemo(() => {
    return data?.duration > 0;
  }, [data]);

  return {
    watched,
    fetching: isLoading,
  };
}

export default useWatched;
