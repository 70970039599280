import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { useLayoutEffect, useMemo, useState } from "react";
import Flag from "react-world-flags";
import { getCountries } from "../../imports/utils";
import { useClientLocation } from "../../imports/hooks";

const CountriesDropdown = ({
  elementRef,
  className = "",
  setCode = () => {},
  dropdownClassName = "",
}) => {
  const all = getCountries();
  const [selected, setSelected] = useState({});
  const [countries, setCountries] = useState(all);
  const { location, locating: isLoading } = useClientLocation();

  const current = useMemo(() => {
    if (!isLoading && selected?.country) {
      return selected;
    }

    return all.find((c) => c.abbreviation === location?.countryCode);
  }, [selected, all, location, isLoading]);

  function handleSearch(e) {
    const { value } = e.target;
    let filtered = all.filter(
      (c) =>
        c.abbreviation
          ?.toLocaleLowerCase()
          .indexOf(value?.toLocaleLowerCase()) > -1 ||
        c.country?.toLocaleLowerCase().indexOf(value?.toLocaleLowerCase()) >
          -1 ||
        c.calling_code?.toString().indexOf(value) > -1
    );

    setCountries(filtered);
  }

  function handleCountrySelected(country) {
    if (elementRef.current) {
      elementRef.current.focus();
    }
    setSelected(country);
  }

  useLayoutEffect(() => {
    if (current && !isLoading) {
      setCode(current.calling_code);
    }
  }, [current, isLoading, setCode]);

  return (
    <div className={`hover-drop ${className}`}>
      <button
        type="button"
        tabIndex="-1"
        className="absolute top-0 py-[.94rem] h-fit max-h-full right-0 px-3 flex items-center space-x-[.15rem]"
      >
        <Flag code={current?.abbreviation} className="w-5 object-fill" />
        <span>{current?.calling_code}</span>
        <ChevronDownIcon className="w-4 icon" />
      </button>

      <div
        className={`bg-black text-white dropdown-menu right-0 invisible absolute w-full border border-gray-800 ${dropdownClassName}`}
      >
        <div className="p-3">
          <input
            type="search"
            placeholder="Search..."
            onChange={handleSearch}
            className="w-full text-sm bg-gray-800 focus:outline-none px-3 py-2"
          />
        </div>

        <ul className="w-full max-h-[30vh] overflow-y-auto scrollbar-hide">
          {countries.map((country) => {
            return (
              <li
                onClick={() => handleCountrySelected(country)}
                key={country.country}
                as="a"
                className={`py-2 px-4 ${
                  current?.calling_code === country.calling_code
                    ? "bg-secondary"
                    : "text-gray-500 hover:text-white hover:bg-zinc-900"
                } cursor-pointer`}
              >
                <button type="button" className="w-full text-start">
                  {country.country} {country.calling_code}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CountriesDropdown;
