import React from "react";
import {
  ChevronDownIcon,
  InformationCircleIcon,
  PencilIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { LinkButton } from "../../imports/components";
import { API } from "../../imports/utils";
import { Images } from "../../imports/statics";
import { Link } from "../misc/Header";
import SwitchProfileDialog from "../SwitchProfileDialog";
import { useAuth, useSignOut, useModalContext } from "../../imports/hooks";

const ProfileDropdown = ({ showIcons = true }) => {
  const { data } = useAuth();
  const { dispatch } = useModalContext();
  const { signOut, signOutProfile } = useSignOut();

  const { data: profiles = [] } = API.Get({
    key: "account-profiles",
    path: "accounts/get-profiles",
  });

  function handleSignOut(e, isProfile = false) {
    if (!isProfile) {
      signOut(e);
    } else {
      signOutProfile(e);
    }
  }

  function switchProfile(e, profile) {
    e.preventDefault();

    dispatch({
      type: "SHOW",
      payload: {
        content: profile,
        slug: profile.has_secret ? "verify-pin" : "switch-profile",
        reference: profile.has_secret ? "verify-pin" : "switch-profile",
      },
    });
  }

  return (
    <>
      <div className="dropdown relative hover-drop group">
        <button
          className="flex items-center space-x-[.15rem] h-full"
          type="button"
          aria-expanded="false"
        >
          <div
            className="rounded"
            style={{ backgroundColor: data?.profile?.avatar?.color }}
          >
            <img
              src={Images.Profile}
              alt="profile-icon"
              width={30}
              heigh={30}
              className="profile-img object-contain"
            />
          </div>
          <ChevronDownIcon className="w-4 icon" />
        </button>

        <ul className="dropdown-menu min-w-max absolute right-0 invisible border space-y-3 border-[gray]/70 px-4 py-5 w-[256px] bg-[#000000eb] !mt-1 duration-300 ease-out">
          <li className="flex items-center justify-between border-gray-600 pb-1 border-b">
            <div className="">
              <p className="mb-0 text-xs text-gray-400">Current Profile</p>
              <strong
                className="font-bold text-inherit"
                style={{ color: data?.profile?.avatar?.color }}
              >
                {data?.profile?.name}
              </strong>
            </div>
            {showIcons && (
              <div
                className="rounded"
                style={{ backgroundColor: data?.profile?.avatar?.color }}
              >
                <img
                  src={Images.Profile}
                  alt="profile-icon"
                  width={30}
                  heigh={30}
                  className="profile-img object-contain"
                />
              </div>
            )}
          </li>

          {profiles?.map &&
            profiles
              .filter((p) => p.name !== data?.profile?.name)
              .map((pro) => {
                return (
                  <li key={pro.id} className="flex items-center space-x-2">
                    {showIcons && (
                      <div
                        className="rounded"
                        style={{ backgroundColor: pro?.avatar?.color }}
                      >
                        <img
                          src={Images.Profile}
                          alt="profile-icon"
                          width={30}
                          heigh={30}
                          className="profile-img object-contain"
                        />
                      </div>
                    )}
                    <Link
                      replaceClick={true}
                      onClick={(e) => switchProfile(e, pro)}
                    >
                      {pro.name}
                    </Link>
                  </li>
                );
              })}

          <li className="flex items-center space-x-2">
            {showIcons && <PencilIcon className="w-[30px] p-1" />}
            <Link href="/manage-profiles">Manage Profiles</Link>
          </li>
          <li className="flex items-center space-x-2">
            {showIcons && <UserIcon className="w-[30px] p-1" />}
            <Link href="/account">Account </Link>
          </li>
          <li className="flex items-center space-x-2">
            {showIcons && <InformationCircleIcon className="w-[30px] p-1" />}
            <Link href="https://about.sierraflixx.net/help">Help Center</Link>
          </li>

          <li className="border-b w-full border-gray-600"></li>

          <li className="flex items-center justify-center space-x-2">
            <LinkButton
              type="button"
              as="a"
              href="/choose-profile"
              className="hover:underline font-semibold text-orange-600"
              replaceClick={true}
              onClick={(e) => handleSignOut(e, true)}
            >
              Sign Out of profile
            </LinkButton>
          </li>

          <li className="flex items-center justify-center space-x-2">
            <LinkButton
              type="button"
              as="a"
              className="hover:underline text-rose-600 font-semibold"
              replaceClick={true}
              onClick={handleSignOut}
            >
              Sign Out of Sierraflixx
            </LinkButton>
          </li>
        </ul>
      </div>

      <SwitchProfileDialog />
    </>
  );
};

export default ProfileDropdown;
