import React from "react";
import { useAuth } from "../../imports/hooks";
import { LinkButton } from "../../imports/components";
import { ProfileLayout } from "../../imports/layouts";

function ManageProfiles() {
  const { redirect } = useAuth();

  return (
    <ProfileLayout
      isEdit={true}
      title="Manage Profiles"
      clientKey="manage-profiles"
    >
      <div className="flex gap-4 mt-8">
        <LinkButton
          href={redirect}
          className="border-2 text-sm sm:text-base text-gray-400 font-semibold hover:text-white border-gray-400 px-2 sm:px-4 py-2 hover:bg-secondary"
        >
          Done
        </LinkButton>
      </div>
    </ProfileLayout>
  );
}

export default ManageProfiles;
