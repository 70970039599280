import React, { useState } from "react";
import { LinkButton } from "../../imports/components";
import { BiCheckSquare, BiSquare } from "react-icons/bi";
import { usePost } from "../../imports/hooks";
import { ManageAccountLayout } from "../../imports/layouts";

function SignOutAll() {
  const [includeSession, setIncludeSession] = useState(false);
  const { post } = usePost("accounts/logout-all", {
    redirect: true,
    clientKey: ["auth", "profiles"],
    showProcessing: true,
    toggleOnSuccess: false,
    processingText: "Signing out of all sessions...",
  });

  function handleSignOutAll() {
    post(null, {
      includeSession,
    });
  }

  return (
    <ManageAccountLayout showReturn={false}>
      <h2 className="text-3xl">Sign Out of All Sessions</h2>
      <p className="tracking-normal">
        Are you sure you want to sign out of this Sierraflixx account on all
        sessions?
      </p>

      <button
        onClick={() => setIncludeSession((pre) => !pre)}
        className={`flex items-center space-x-1 text-xl text-secondary opacity-${
          includeSession ? "100" : "50"
        } font-semibold`}
      >
        {includeSession ? <BiCheckSquare /> : <BiSquare />}

        <span className="text-sm">Include this session</span>
      </button>

      <div className="flex space-x-2 pt-4">
        <button
          onClick={handleSignOutAll}
          className="text-xs shadow-md bg-green-500 px-5 py-2 rounded-sm text-white"
        >
          Sign Out Sessions
        </button>
        <LinkButton
          href="/account"
          className="text-xs shadow-md bg-zinc-300 px-5 py-2 rounded-sm"
        >
          Cancel
        </LinkButton>
      </div>
    </ManageAccountLayout>
  );
}

export default SignOutAll;
