import API, { baseURL } from "../utils/api";
import storage from "../utils/storage";
import Statics from "./statics";
import { Navigate } from "react-router-dom";

export const signUpSuccessCodes = [202, 402, 406, 410, 412];

const toObject = (ev, isForm = true) => {
  const formData = isForm ? new FormData(ev) : ev;
  const data = Object.fromEntries(formData.entries());

  return data;
};

const formDataToArray = (data, keys = [], separator = ",") => {
  keys.forEach((key) => {
    const key_arr = `${key}[]`;
    const val = data.get(key);
    data.delete(key);

    if (val) {
      if (val.indexOf(separator) > -1) {
        val
          .split(separator)
          .forEach((v, i) => data.set(`${key}[${i}]`, v.trim()));
      } else {
        data.set(key_arr, val);
      }
    }
  });

  return data;
};

const getAbbreviation = (word) => {
  const abbreviation = {
    year: "yr",
    month: "mnt",
  };

  return abbreviation[word?.toLowerCase()];
};

const getCountries = () => {
  const { Codes, Abbreviations } = Statics.Countries;
  const countries = Codes.map((cc) => {
    const abbreviation = Abbreviations.find(
      (ca) => ca.country === cc.country
    )?.abbreviation;

    return { ...cc, calling_code: `+${cc.calling_code}`, abbreviation };
  });

  return countries;
};

const getCountry = (code) => {
  const { Codes, Abbreviations } = Statics.Countries;
  code = code ? code.slice(1, code.length) : "";

  const fetched = Codes.find((c) => c.calling_code === Number(code));

  if (!fetched) return {};

  const abbreviation = Abbreviations.find(
    (ca) => ca.country === fetched?.country
  )?.abbreviation;

  if (abbreviation.length <= 0) return {};

  return { ...fetched, calling_code: `+${fetched.calling_code}`, abbreviation };
};

const getPhoneNumber = (code, number) => {
  if (number) {
    number = number.indexOf("+") > -1 ? number.replace("+", "") : number;
    const index = number.indexOf(code.slice(1, code.length));

    if (index === 0) {
      number = number.slice(code.length - 1, number.length);
    }

    return `${code}${Number(number)}`;
  }

  return "";
};

const extractPhoneNumber = (code, number) => {
  if (number && code) {
    number = number.indexOf("+") > -1 ? number.replace("+", "") : number;
    const index = number.indexOf(code.slice(1, code.length));

    if (index === 0) {
      number = number.slice(code.length - 1, number.length);
    }

    return Number(number);
  }

  return "";
};

const toArray = (val, add = 0) => {
  const arr = Number(val) + add;
  return Array.from(Array(arr));
};

const toDuration = (minutes) => {
  const min_hrs = 60;
  const hrs = Math.floor(minutes / min_hrs);
  const mins = minutes - hrs * 60;
  const words = `${hrs}hr${hrs > 1 ? "s" : ""} ${mins}min${
    mins > 1 ? "s" : ""
  }`;
  const duration = [
    hrs.toString().padStart(2, "0"),
    mins.toString().padStart(2, "0"),
  ].join(":");

  return { duration, words };
};

const toTimeDuration = (seconds) => {
  const dtr = new Date(seconds * 1000);
  const hrs = dtr.getUTCHours();
  const mins = dtr.getUTCMinutes();
  const secs = dtr.getUTCSeconds();

  return [hrs, mins, secs].map((v) => v.toString().padStart(2, "0")).join(":");
};

function toDate(date, options = {}) {
  const newDate = new Date(date);

  return newDate.toLocaleDateString("en-US", options);
}

export function Redirect(condition, url, args = { replace: false, state: {} }) {
  if (!condition) {
    return <Navigate to={url} replace={args.replace} state={args.state} />;
  }
}

export function toMovie(data) {
  if (!data) return {};

  const movie = {
    ...data,
    title: {
      ...data?.title,
      image: baseURL + data?.title?.image,
    },
    image: baseURL + data?.image,
    teaser: baseURL + data?.teaser,
    poster: baseURL + data?.poster,
    trailers: data?.trailers?.map((t) => baseURL + t),
  };

  if (data?.content) {
    movie.content = {
      ...data.content,
      link: baseURL + data.content?.link,
    };
  }

  return movie;
}

export {
  API,
  toDate,
  baseURL,
  storage,
  toArray,
  toObject,
  toDuration,
  getCountry,
  getCountries,
  toTimeDuration,
  getAbbreviation,
  getPhoneNumber,
  extractPhoneNumber,
  formDataToArray,
};
