import React from "react";

const Spinner = ({ color, size }) => {
  return (
    <div
      className={`spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full ${
        color ? color : ""
      } h-[${size}rem] w-[${size}rem] max-h-[${size}rem] max-w-[${size}rem]`}
      role="status"
    >
      <span className="visually-hidden">Processing...</span>
    </div>
  );
};

export default Spinner;
