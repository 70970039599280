import React, { useState } from "react";
import FloatingInput from "./FloatingInput";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

const args = {
  autoFocus: false,
  required: false,
  id: "password",
  className: "",
  inputClassName: "",
  name: "password",
  elementRef: null,
  placeholder: "Password",
};

const PasswordInput = (props = { ...args }) => {
  props = { ...args, ...props };
  const [show, setShow] = useState(false);

  return (
    <div className={`flex items-center relative group ${props.className}`}>
      <FloatingInput
        name={props.name}
        id={props.id}
        elementRef={props.elementRef}
        autoFocus={props.autoFocus}
        type={show ? "text" : "password"}
        placeholder={props.placeholder}
        inputClassName={props.inputClassName}
        required={props.required}
        minLength={4}
        feedback={
          props.feedback ?? "Your password must be at least 4 characters"
        }
      />

      <button
        tabIndex={-1}
        type="button"
        onClick={() => setShow((pre) => !pre)}
        className="absolute right-4 hidden group-focus-within:block"
      >
        {show ? (
          <EyeOffIcon className="h-5 w-5" />
        ) : (
          <EyeIcon className="h-5 w-5" />
        )}
      </button>
    </div>
  );
};

export default PasswordInput;
