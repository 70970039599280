import React from "react";
import { AccountNavigation, Footer, LinkButton } from "../imports/components";
import { Images } from "../imports/statics";

function NotFound() {
  return (
    <div className="flex flex-col min-h-screen w-full">
      <AccountNavigation showButton={true} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen relative bg-black-50 sm:!bg-radial-gradient items-center">
        <img
          src={Images.NotFoundCover}
          alt="bg_image"
          className="absolute -z-10 min-h-full top-0 object-cover max-h-full min-w-full"
        />
        <div className="max-w-md justify-center text-center flex-1 flex flex-col items-center pt-20 pb-5">
          <h1 className="text-4xl">Seem out of place!</h1>
          <p className="text-xl mb-4">
            The resource you are looking for does not seem to be available.
          </p>

          <LinkButton
            href={-1}
            size="lg"
            className="py-2 md:py-3 px-5 letter-spaced font-semibold text-truncate bg-secondary"
          >
            Return Back
          </LinkButton>
        </div>
        <div className="flex w-full">
          <Footer className="mt-auto sm:bg-black-50 w-full max-w-full" />
        </div>
      </div>
    </div>
  );
}

export default NotFound;
