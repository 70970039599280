import React from "react";
import { Footer, Loader } from "../../imports/components";
import { API, toDate } from "../../imports/utils";
import * as Icons from "react-icons/fa";
import { ManageAccountLayout } from "../../imports/layouts";
import { useMemo } from "react";

function BillingDetails() {
  const { data: subscription = {} } = API.Get({
    path: "subscriptions/current",
  });

  const { data = [], isLoading } = API.Get({
    path: "subscriptions/billing-info",
  });

  const billings = useMemo(() => (data.map ? data : []), [data]);

  function getCardIcon(brand) {
    const Icon = Icons?.[brand];

    if (Icon) {
      return <Icon className="text-xl text-blue-700" />;
    }

    return "";
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ManageAccountLayout>
        <h2 className="text-3xl">Billing Details</h2>

        <div className="space-y-1">
          <p className="text-gray-600 uppercase">your membership</p>

          <div className="bg-white !m-0 border border-zinc-400 py-3 px-2">
            <div className="mb-4 flex flex-col">
              <span className="font-semibold">Plan</span>
              <span className="text-[.98rem]">{subscription?.plan?.title}</span>
              <span>
                ${subscription?.plan?.price?.amount}/
                {subscription?.plan?.price?.period?.toLowerCase()}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="font-semibold">Next billing date</span>
              <span className="text-[.98rem]">
                {toDate(subscription?.expires_at, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>

          <p className="text-xs text-zinc-500">
            Membership fees are billed at the beginning of each period and may
            take a few days after the billing date to appear on your account.
          </p>
        </div>

        <div className="hidden md:block">
          <table className="billing-table">
            <thead>
              <tr className="text-zinc-600">
                <th>Date</th>
                <th>Description</th>
                <th>Service Period</th>
                <th className="text-end">Payment Method</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              {billings?.map((billing, index) => (
                <tr key={`row_${index}`} className="text-[.8rem]">
                  <td className="font-semibold text-blue-500">
                    {billing?.created_at}
                  </td>
                  <td>{billing?.description}</td>
                  <td>
                    {billing?.period?.start} - {billing?.period?.end}
                  </td>
                  <td className="text-center">
                    <span>{billing?.method}</span>
                  </td>
                  <td className="text-end">
                    {billing?.method === "card" ? "$" : "SLE"}
                    {billing?.amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col md:hidden">
          {billings?.map &&
            billings?.map((billing, index) => (
              <div
                key={`col_${index}`}
                className="flex space-x-3 border-b border-zinc-400 py-4 justify-between"
              >
                <div className="flex flex-col flex-1 space-y-3">
                  <span className="text-blue-500 font-semibold">
                    {billing.created}
                  </span>

                  <div className="flex flex-col">
                    <span>{billing.description}</span>
                  </div>

                  <div className="flex text-truncate space-x-1 sm:space-x-2">
                    <span>{getCardIcon(billing.payment_method?.brand)}</span>
                    <span>•••• •••• ••••</span>
                    <span>{billing?.payment_method?.last4}</span>
                  </div>
                </div>

                <div className="text-lg font-bold">${billing.amount}</div>
              </div>
            ))}
        </div>

        <p className="text-xs !mt-1">
          NOTE: We only show up to 1 year of billing history
        </p>
      </ManageAccountLayout>

      <Footer />
    </>
  );
}

export default BillingDetails;
