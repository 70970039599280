import React, { useMemo } from "react";
import { useClientLocation } from "../../imports/hooks";

const Footer = ({
  links = [],
  replace = false,
  showLocation = false,
  className = "",
}) => {
  const { location, locating } = useClientLocation();

  const navigations = useMemo(() => {
    return replace && links.length > 0
      ? links
      : [
          "FAQ",
          "Help Center",
          "Terms of Use",
          "Privacy",
          "Cookie Preference",
          "Corporate Information",
          ...links,
        ];
  }, [links, replace]);

  return (
    <footer className={className}>
      <div className="py-14 px-8 md:px-0">
        <div className="grid auto-cols-auto">
          <div className="col-span-12 sm:col-span-8 sm:col-start-3">
            <a
              href="https://about.sierraflixx.net/contact-us"
              className="text-gray-500 hover:text-inherit hover:underline"
            >
              Questions? Contact us.
            </a>

            <div className="grid mt-6 gap-2 grid-cols-12">
              {navigations.map((link, index) => {
                return (
                  <div
                    key={`${link}_${index}`}
                    className="col-span-6 sm:col-span-4 md:col-span-3"
                  >
                    <a
                      href={`https://about.sierraflixx.net/${link
                        .toLowerCase()
                        .replace(" ", "-")}`}
                      className="text-gray-500 text-xs sm:text-sm hover:text-inherit hover:underline"
                    >
                      {link}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {showLocation && (
        <div className="py-3 border-t border-zinc-700 text-center bg-inherit">
          Sierraflixx
          {!locating && ` from ${location?.country}`}
        </div>
      )}
    </footer>
  );
};

export default Footer;
