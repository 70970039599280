import { useState } from "react";

export default function useToggle() {
  const [state, setState] = useState(null);

  const toggle = (data, timeout = false, interval = 4000) => {
    setState(data);

    if (timeout) {
      setTimeout(() => {
        setState(null);
      }, interval);
    }
  };

  return {
    state,
    toggle,
  };
}
