import React from "react";

const FormInput = ({
  autoFocus = false,
  required = false,
  type = "text",
  name = "",
  id = "",
  placeholder = "",
  className = "",
  feedback = "",
  label = "",
  validated = false,
}) => {
  return (
    <div className="flex-flex-col w-full text-[.97rem] text-zinc-700">
      {label.length > 0 && <label htmlFor={id}>{label}</label>}
      <input
        autoFocus={autoFocus}
        required={required}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className={`border w-full focus:outline-none px-3 py-2 rounded-[.15rem] peer focus:border-green-500 focus:!border ${
          validated ? "invalid:border-red invalid:border-2" : ""
        } placeholder:text-zinc-600/80 ${className}`}
      />
      {feedback.length > 0 && (
        <span
          className={`text-red text-base hidden text-[.96rem] ${
            validated ? "peer-invalid:block" : ""
          }`}
        >
          {feedback}
        </span>
      )}
    </div>
  );
};

export default FormInput;
