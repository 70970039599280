import React, { useEffect, useLayoutEffect } from "react";
import { API } from "../../imports/utils";
import {
  AccountNavigation,
  Alert,
  Footer,
  LinkButton,
  Loader,
} from "../../imports/components";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import usePost from "../../hooks/post-hook";

const PaymentPlan = () => {
  const [selected, setSelected] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const { data: plans = [], isLoading } = API.Get({
    path: "static/plans/all",
  });
  const { data: subscription = {} } = API.Get({
    path: "subscriptions/current",
  });

  function getLast(plan) {
    const last = plan.meta.devices[plan.meta.devices.length - 1];
    if (last?.name) {
      last.name = last.name.toLowerCase();
    }
    return last;
  }

  useLayoutEffect(() => {
    if (subscription?.plan) {
      setSelected(subscription.plan);
    }
  }, [subscription]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
        <AccountNavigation bg="black" showProfile={true} showButton={false} />

        <div className="flex flex-col min-w-full max-w-full min-h-screen text-black">
          <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
            <div className="w-full space-y-5 max-w-5xl pt-4 md:pt-8 min-h-full px-6">
              <h2 className="text-4xl mb-14">Change Plan</h2>

              {plans?.map &&
                plans.map((plan) => {
                  return (
                    <div key={plan.slug} className="relative flex">
                      <button
                        onClick={() => setSelected(plan)}
                        className={`grid grid-cols-1 lg:grid-cols-7 items-center border-2 lg:border-4 ${
                          plan.slug === selected?.slug
                            ? "border-secondary"
                            : "border-zinc-300"
                        } w-full hover:border-secondary bg-zinc-100 transition duration-75 ease-in-out`}
                      >
                        <div
                          className={`col-span-1 lg:mx-0 py-3 lg:py-12 lg:bg-gray/60 min-h-full flex flex-col items-center justify-center ${
                            plan.slug === selected?.slug
                              ? "bg-zinc-300"
                              : "lg:bg-zinc-200 border-zinc-400 border-b lg:border-0 mx-4"
                          }`}
                        >
                          {plan.slug === subscription?.plan?.slug && (
                            <span className="lg:text-xs font-extrabold text-zinc-700 uppercase">
                              Current Plan:
                            </span>
                          )}
                          <span
                            className={`font-bold ${
                              plan.slug === selected?.slug && "text-secondary"
                            }`}
                          >
                            {plan.title}
                          </span>
                        </div>

                        <div className="py-4 lg:py-8 pl-4 pr-8 col-span-1 lg:col-span-6 flex justify-center lg:justify-between space-x-8 bg-zinc-100 flex-1 items-center w-full">
                          <span className="text-start lg:text-lg">
                            {plan.meta["video-quality"]} video quality in{" "}
                            {plan.meta.resolution.quality} (
                            {plan.meta.resolution.dimension}). Watch on any{" "}
                            {plan.meta["devices"]
                              .map((device) => device.name?.toLowerCase())
                              .join(", ")
                              .replace(
                                `, ${getLast(plan)?.name}`,
                                ` or ${getLast(plan)?.name}`
                              )}{" "}
                            <span className="font-bold">
                              ${plan.price.amount}/
                              {plan.price.period?.toLowerCase()}
                            </span>
                          </span>
                          <span className="hidden lg:block sm:text-lg font-bold sm:font-regular">
                            ${plan.price.amount}/
                            {plan.price.period?.toLowerCase()}
                          </span>
                        </div>
                      </button>

                      {plan.slug === selected?.slug && (
                        <div className="absolute w-8 lg:w-10 -left-4 lg:-left-5 min-h-full flex items-center">
                          <FaCheckCircle className="text-6xl lg:text-10xl text-secondary rounded-full z-10 bg-zinc-200 py-0 h-fit" />
                        </div>
                      )}
                    </div>
                  );
                })}

              <div className="flex flex-col text-xs sm:text-sm text-zinc-700 space-y-3">
                <span>
                  SD (480p), HD (720p), Full HD (1080p), Ultra HD (4K) and HDR
                  availability subject to your internet service and device
                  capabilities. Not all content is available in all resolutions.
                  See our{" "}
                  <a
                    href="https://about.sierraflixx.net/terms-of-use"
                    className="text-primary"
                  >
                    Terms of Use
                  </a>{" "}
                  for more details.
                </span>

                <span>
                  Only people who live with you may use your account. Watch on 4
                  different devices at the same time with Premium, 3 with
                  Standard, and 2 with Basic.
                </span>
              </div>

              <div className="flex space-x-3 items-center justify-center min-w-full py-4">
                <button
                  onClick={() => setShowConfirm(true)}
                  disabled={selected?.slug === subscription?.plan?.slug}
                  className="bg-secondary text-sm rounded-sm px-4 sm:px-8 tracking-widest min-w-fit py-3 text-zinc-200 disabled:opacity-60"
                >
                  Continue
                </button>

                <LinkButton
                  href={-1}
                  className="bg-zinc-300 text-xs sm:text-sm border border-zinc-300 rounded-sm min-w-fit px-4 sm:px-8 tracking-widest py-3 text-secondary"
                >
                  Go Back
                </LinkButton>
              </div>
            </div>
          </div>
        </div>

        <Footer className="border-zinc-300 mt-14 border-t text-zinc-700" />
      </div>

      <ConfirmDialog
        newPlan={selected}
        oldPlan={subscription?.plan}
        onHide={() => setShowConfirm(false)}
        showConfirm={showConfirm && selected?.slug !== subscription?.plan?.slug}
      />
    </>
  );
};

function ConfirmDialog({
  newPlan,
  oldPlan,
  showConfirm = false,
  onHide = () => {},
}) {
  const [show, setShow] = useState(showConfirm);
  const { post, processing, state } = usePost(
    "subscriptions/card/change-plan",
    {
      toggleOnSuccess: false,
      redirect: true,
    }
  );

  function handlePost() {
    const data = new FormData();
    data.append("plan", newPlan.id);

    post(null, data);
  }

  function handleHide() {
    setShow(false);
    onHide();
  }

  useEffect(() => {
    setShow(showConfirm);
  }, [showConfirm]);

  return (
    show && (
      <div className="fixed flex items-center inset-0 justify-center top-0 left-0 min-h-screen w-screen z-50 bg-gray-800/70">
        <div className="w-full max-w-xl bg-white text-black">
          <div className="py-4 px-6 flex justify-between w-full text-zinc-700">
            <span className="text-3xl">Change Plan</span>
            <button onClick={handleHide} className="text-4xl">
              &times;
            </button>
          </div>

          <div className="text-sm flex flex-col space-y-1 bg-zinc-200">
            <div className="border-b px-6 py-3  border-zinc-400">
              <span className="uppercase text-zinc-500">current plan:</span>
              <div className="text-zinc-600 font-semibold flex space-x-2">
                <span>{oldPlan?.title}</span>
                <span>
                  ${oldPlan?.price?.amount}/
                  {oldPlan?.price?.period?.toLowerCase()}
                </span>
              </div>
            </div>

            <div className="px-6 py-3">
              <span className="uppercase">new plan:</span>
              <div className="font-semibold flex space-x-2">
                <span>{newPlan?.title}</span>
                <span>
                  ${newPlan?.price?.amount}/
                  {newPlan?.price?.period?.toLowerCase()}
                </span>
              </div>
            </div>
          </div>

          <div className="px-6 py-4 flex flex-col text-sm space-y-3">
            <span>
              Your new plan starts now and you will be charged the new price.
            </span>
            <span>
              You agree that your{" "}
              <strong>
                Sierraflixx membership will continue and that we will charge the
                updated monthly fee until you cancel.
              </strong>
            </span>

            {oldPlan?.meta?.["number-of-users"] >
              newPlan?.meta?.["number-of-users"] && (
              <code className="text-orange-400">
                Note that all profiles (except for the master profile) will be
                deleted because your new plan has less number of profiles
                compared to your old plan
              </code>
            )}
          </div>

          {state && (
            <Alert>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: state.message }}
              ></p>
            </Alert>
          )}

          <div className="flex space-x-3 items-center justify-center min-w-full py-5">
            <button
              disabled={processing}
              onClick={handlePost}
              className="bg-secondary text-sm rounded-sm px-4 tracking-wider py-2 text-zinc-200 disabled:opacity-60"
            >
              {processing ? "Processing..." : "Confirm Change"}
            </button>

            <button
              onClick={handleHide}
              className="bg-zinc-300 text-sm border border-zinc-300 rounded-sm px-4 tracking-wider py-2 text-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default PaymentPlan;
