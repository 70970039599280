import React, { useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { API } from "../../imports/utils";
import { CommonDialog } from "../../imports/components";
import { useEffect } from "react";

const args = {
  show: true,
  current: {},
  setNew: () => {},
  setShow: () => {},
};
const ChangePlan = (props = { ...args }) => {
  const {
    show,
    setNew,
    setShow,
    current: subscription,
  } = { ...args, ...props };
  const [chosen, setChosen] = useState();

  const selected = useMemo(() => {
    return chosen ?? subscription.plan ?? {};
  }, [subscription, chosen]);

  const { data: plans = [], isLoading } = API.Get({
    path: "static/plans/all",
  });

  function getLast(plan) {
    const last = plan.meta.devices[plan.meta.devices.length - 1];
    if (last?.name) {
      last.name = last.name.toLowerCase();
    }
    return last;
  }

  useEffect(() => {
    return () => setChosen(null);
  }, [show]);

  return (
    <CommonDialog
      show={show}
      bg="bg-black/50"
      dialogClassName="sm:max-w-5xl lg:max-w-4xl w-full"
      className="bg-white text-black px-6 py-3"
    >
      {!isLoading && (
        <>
          <h2 className="text-3xl font-semibold mb-8">Change Plan</h2>

          <div className="space-y-4 mb-5">
            {plans?.map &&
              plans.map((plan) => {
                return (
                  <div key={plan.slug} className="relative flex">
                    <button
                      onClick={() => setChosen(plan)}
                      className={`grid grid-cols-1 lg:grid-cols-7 items-center border-2 lg:border-4 ${
                        plan.slug === selected?.slug
                          ? "border-secondary"
                          : "border-zinc-300"
                      } w-full hover:border-secondary bg-zinc-100 transition duration-75 ease-in-out`}
                    >
                      <div
                        className={`col-span-1 lg:mx-0 py-3 lg:py-12 lg:bg-gray/60 min-h-full flex flex-col items-center justify-center ${
                          plan.slug === selected?.slug
                            ? "bg-zinc-300"
                            : "lg:bg-zinc-200 border-zinc-400 border-b lg:border-0 mx-4"
                        }`}
                      >
                        {plan.slug === subscription?.plan?.slug && (
                          <span className="lg:text-xs font-extrabold text-zinc-700 uppercase">
                            Current Plan:
                          </span>
                        )}
                        <span
                          className={`font-bold ${
                            plan.slug === selected?.slug && "text-secondary"
                          }`}
                        >
                          {plan.title}
                        </span>
                      </div>

                      <div className="py-4 lg:py-8 pl-4 pr-8 col-span-1 lg:col-span-6 flex justify-center lg:justify-between space-x-8 bg-zinc-100 flex-1 items-center w-full">
                        <span className="text-start lg:text-lg">
                          {plan.meta["video-quality"]} video quality in{" "}
                          {plan.meta.resolution.quality} (
                          {plan.meta.resolution.dimension}). Watch on any{" "}
                          {plan.meta["devices"]
                            .map((device) => device.name?.toLowerCase())
                            .join(", ")
                            .replace(
                              `, ${getLast(plan)?.name}`,
                              ` or ${getLast(plan)?.name}`
                            )}{" "}
                          <span className="font-bold">
                            ${plan.price.amount}/
                            {plan.price.period?.toLowerCase()}
                          </span>
                        </span>
                        <span className="hidden lg:block sm:text-lg font-bold sm:font-regular">
                          ${plan.price.amount}/
                          {plan.price.period?.toLowerCase()}
                        </span>
                      </div>
                    </button>

                    {plan.slug === selected?.slug && (
                      <div className="absolute w-8 lg:w-10 -left-4 lg:-left-5 min-h-full flex items-center">
                        <FaCheckCircle className="text-6xl lg:text-10xl text-secondary rounded-full z-10 bg-zinc-200 py-0 h-fit" />
                      </div>
                    )}
                  </div>
                );
              })}

            <div className="">
              {subscription?.plan?.meta?.["number-of-users"] >
                selected?.meta?.["number-of-users"] && (
                <>
                  <p className="font-semibold text-rose-600">
                    You seem to be downgrading your plan
                  </p>
                  <code className="text-red">
                    Note that all profiles (except for the master profile) will
                    be deleted because your new plan has less number of profiles
                    compared to your old plan
                  </code>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col text-xs sm:text-sm text-zinc-700 space-y-3">
            <span>
              SD (480p), HD (720p), Full HD (1080p), Ultra HD (4K) and HDR
              availability subject to your internet service and device
              capabilities. Not all content is available in all resolutions. See
              our{" "}
              <a
                href="https://about.sierraflixx.net/terms-of-use"
                className="text-primary"
              >
                Terms of Use
              </a>{" "}
              for more details.
            </span>

            <span>
              Only people who live with you may use your account. Watch on 4
              different devices at the same time with Premium, 3 with Standard,
              and 2 with Basic.
            </span>
          </div>

          <div className="flex space-x-3 items-center justify-center min-w-full py-4">
            <button
              onClick={() => {
                setNew(selected);
                setShow(false);
              }}
              disabled={selected?.slug === subscription?.plan?.slug}
              className="bg-secondary text-sm rounded-sm px-4 sm:px-8 tracking-widest min-w-fit py-3 text-zinc-200 disabled:opacity-60"
            >
              Continue
            </button>

            <button
              onClick={() => setShow(false)}
              className="bg-zinc-300 text-xs sm:text-sm border border-zinc-300 rounded-sm min-w-fit px-4 sm:px-8 tracking-widest py-3 text-secondary"
            >
              Go Back
            </button>
          </div>
        </>
      )}
    </CommonDialog>
  );
};

export default ChangePlan;
