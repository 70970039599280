import React from "react";
import { Images } from "../../imports/statics";
import MovieCard from "./MovieCard";
import { toArray } from "../../imports/utils";

const args = {
  isMovies: false,
  showSpinner: true,
  position: "fixed",
  className: "w-screen h-screen",
};
const Loader = (props = { ...args }) => {
  const { position, className, showSpinner, isMovies } = { ...args, ...props };

  return isMovies ? (
    <div className={`${className} mt-16 sm:mt-[10vh] relative`}>
      <div className="grid animate-bounce transition ease-in-out gap-1 gri-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
        {toArray(6).map((_, index) => (
          <MovieCard
            defaultClick={false}
            key={`placeholder_${index}`}
            className="!border-zinc-700"
          />
        ))}
      </div>
    </div>
  ) : (
    <div
      className={`${position} ${className} top-0 left-0 bg-black flex items-center justify-center z-50`}
    >
      {showSpinner ? (
        <img src={Images.Loader} alt="loader" className="w-[50rem]" />
      ) : (
        <span className="animate-ping bg-primary rounded-full h-20 w-20"></span>
      )}
    </div>
  );
};

export default Loader;
