import axios from "axios";
import { useQuery } from "react-query";

export const baseURL =
  process.env.NODE_ENV === "development" ? "/www/" : "/api/www/";
const apiURL =
  process.env.NODE_ENV === "development" ? "/frontend/" : "/api/frontend/";

const fetchArgs = {
  path: "",
  param: "",
  value: "",
  key: null,
  init: [],
  exclude: [],
};

const api = axios.create({
  baseURL: apiURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

async function Fetch(props = { ...fetchArgs }) {
  let url = props.path;

  if (props?.param?.length > 0) {
    url += `?param=${props?.param}`;

    if (props?.value?.length > 0) {
      url += `&value=${props.value}`;
    }

    if (props?.exclude?.length > 0) {
      url += `&exclude[]=${props.exclude}`;
    }
  }

  return await api
    .get(url)
    .then(({ data }) =>
      typeof data === "string" ? (props?.init ? props?.init : []) : data
    )
    .catch(({ response }) => response);
}

function Get(props = { ...fetchArgs }, enabled = true) {
  const get = () => Fetch(props);
  return useQuery(props?.key || props.path, get, {
    enabled,
    staleTime: 300,
    refetchOnWindowFocus: false,
    placeholderData: props?.init,
  });
}

async function Post(props = { path: "", data: null }) {
  return await api
    .post(props.path, props.data)
    .then((res) => res.data)
    .catch(({ response }) => response);
}

async function Update(
  props = { path: "", param: "id", data: null, value: null }
) {
  let url = `${props.path}?${props.param}=${props.value}`;

  return await api
    .put(url, props.data)
    .then((res) => res.data)
    .catch(({ response }) => response);
}

async function Delete(
  props = {
    path: "",
    param: "",
    value: "",
  }
) {
  let url = `${props?.path}?param=${props?.param}`;

  if (props?.value?.length > 0) {
    url += `&value=${props?.value}`;
  }

  return await api
    .delete(url)
    .then((res) => res.data)
    .catch(({ response }) => response);
}

const API = {
  Get,
  Post,
  Fetch,
  Update,
  Delete,
};

export default API;
