import React from "react";
import { Navigate } from "react-router-dom";
import { API, toMovie } from "../../imports/utils";
import {
  Header,
  LinkButton,
  Loader,
  MovieCard,
} from "../../imports/components";
import { useMemo } from "react";
import { Fragment } from "react";
import useAuth from "../../hooks/auth-hook";

function Search() {
  let results = {};
  const { params } = useAuth();
  const path = useMemo(() => {
    const q = params.get("q");
    const sug = params.has("sug") ? `&sug=${params.get("sug")}` : "";

    return `static/search?q=${q}${sug}`;
  }, [params]);

  const { data = results, isLoading } = API.Get({
    path,
    key: ["search", path],
  });

  results = useMemo(() => {
    return {
      ...data,
      movies: data.movies?.map((m) => toMovie(m)) || [],
    };
  }, [data]);

  return !params.has("q") ? (
    <Navigate to="?q=all" replace={true} />
  ) : (
    <>
      <Header />
      <div className="mt-14 sm:mt-20 default-padding space-y-8">
        {isLoading ? (
          <Loader isMovies={true} />
        ) : results.code ? (
          <>
            {results.code !== 111 && (
              <div className="flex items-center flex-wrap">
                <span className="sm:text-xl mr-4 text-zinc-500">
                  Related searches:
                </span>

                <div className="flex flex-wrap items-center">
                  {results.tags?.map((tag, index) => (
                    <Fragment key={tag.id}>
                      <LinkButton
                        href={`?q=${params.get("q")}&sug=${tag.slug}`}
                        className="hover:text-primary sm:text-lg"
                      >
                        <span
                          className={
                            params.get("sug") === tag.slug ? "text-primary" : ""
                          }
                        >
                          {tag.title}
                        </span>
                      </LinkButton>
                      {index < results.tags?.length - 1 && (
                        <span className="mx-3 text-xl font-thin text-zinc-500">
                          |
                        </span>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}

            <h5 className="sm:text-2xl">
              {results.code === 204 &&
                `No results found for "${params.get(
                  "q"
                )}" but here are some suggestions:`}
            </h5>

            <div className="grid gap-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
              {results.movies?.map((movie) => (
                <MovieCard key={movie.id} movie={movie} />
              ))}
            </div>
          </>
        ) : (
          <div className="flex flex-col w-full items-center">
            <div className="space-y-3">
              <span className="text-xl text-gray-400">
                You search &quot;{params.get("q")}&quot; returned no matches
              </span>

              <div className="text-sm">
                <span className="font-semibold">We recommend you try:</span>
                <ul className="list-disc list-inside px-3">
                  <li>A different keyword</li>
                  <li>Using a movie title or a more general term</li>
                  <li>
                    A genre, like social issues, adventure, action or comedy
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Search;
