import { Dialog, Transition } from "@headlessui/react";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuth, useModalContext, usePost } from "../imports/hooks";
import {
  Alert,
  FloatingInput,
  Form,
  PasswordInput,
  SubmitButton,
} from "../imports/components";
import { Images } from "../imports/statics";
import { PencilIcon } from "@heroicons/react/outline";

const EditProfileDialog = () => {
  const { data: user } = useAuth();
  const cancelButtonRef = useRef(null);
  const [isKid, setIsKid] = useState(false);
  const [color, setColor] = useState(null);
  const [autoPlayEpisodes, setAutoPlayEpisodes] = useState(false);
  const [autoPlayBrowsing, setAutoPlayBrowsing] = useState(false);
  const { show, content: profile, slug, dispatch } = useModalContext();

  const { post, state, processing } = usePost(
    `profiles/update?id=${profile?.id}`,
    {
      delay: 4000,
      redirect: false,
      successCodes: [200],
      clientKey: "manage-profiles",
    }
  );

  const setOpen = () => {
    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (data.get("create_pin")?.length < 1) {
      data.delete("create_pin");
    }

    post(e, data);
  };

  useEffect(() => {
    if (profile) {
      setIsKid(profile?.is_kid);
      setAutoPlayEpisodes(profile?.autoplay_episodes);
      setAutoPlayBrowsing(profile?.autoplay_browsing);
    }

    return () => {
      setIsKid(false);
      setColor(null);
    };
  }, [profile]);

  return (
    <Transition.Root show={show && slug === "edit-profile"} as={Fragment}>
      <Dialog
        as="div"
        className="relative bg-black z-[60]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-2xl">
                <div className="default-padding">
                  <h2 className="text-3xl font-semibold m-0">Edit Profile</h2>
                  <hr />

                  <Form onSubmit={handleFormSubmit}>
                    <input
                      type="hidden"
                      name="avatar[image]"
                      defaultValue={profile?.avatar?.image}
                    />

                    <input type="hidden" name="is_kid" defaultValue={isKid} />

                    <input
                      type="hidden"
                      name="autoplay_browsing"
                      defaultValue={autoPlayBrowsing}
                    />

                    <input
                      type="hidden"
                      name="autoplay_episodes"
                      defaultValue={autoPlayEpisodes}
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-12 my-4 gap-x-4">
                      <div className="cols-span-1 sm:col-span-2 relative">
                        <div
                          style={{
                            background: color ? color : profile?.avatar?.color,
                          }}
                          className="mb-4 rounded max-w-fit items-center justify-center sm:mb-0 relative"
                        >
                          <label htmlFor="color" className="cursor-pointer">
                            <img
                              alt="profile_icon"
                              src={Images.Profile}
                              className="profile-img rounded sm:w-full w-20"
                            />
                            <div className="absolute top-0 opacity-0 hover:opacity-75 bg-black/50 w-full h-full flex items-center justify-center rounded">
                              <PencilIcon className="w-6" />
                            </div>
                          </label>
                        </div>

                        <input
                          className="!absolute top-0"
                          type="color"
                          onChange={({ target }) => setColor(target.value)}
                          hidden
                          id="color"
                          name="avatar[color]"
                          defaultValue={profile?.avatar?.color}
                        />
                      </div>

                      <div className="col-span-1 sm:col-span-10 space-y-4">
                        <FloatingInput
                          name="name"
                          type="text"
                          placeholder="Name"
                          defaultValue={profile?.name}
                          inputClassName="bg-zinc-800"
                          required={true}
                        />

                        <hr />

                        <div className="flex flex-col gap-y-1">
                          <p className=" text-gray-300">Autoplay Controls</p>
                          <div
                            className="flex w-full items-center cursor-pointer"
                            onClick={() => setAutoPlayEpisodes((pre) => !pre)}
                          >
                            {autoPlayEpisodes ? (
                              <BiCheckboxChecked className="font-extralight text-4xl" />
                            ) : (
                              <BiCheckbox className="font-extralight text-4xl" />
                            )}

                            <span className="text-xs sm:text-base">
                              Autoplay next episode in a series on all devices
                            </span>
                          </div>

                          <div
                            className="flex w-full items-center cursor-pointer"
                            onClick={() => setAutoPlayBrowsing((pre) => !pre)}
                          >
                            {autoPlayBrowsing ? (
                              <BiCheckboxChecked className="font-extralight text-4xl" />
                            ) : (
                              <BiCheckbox className="font-extralight text-4xl" />
                            )}

                            <span className="text-xs sm:text-base">
                              Autoplay previews while browsing on all devices
                            </span>
                          </div>
                        </div>

                        <hr />

                        <div className="flex flex-col gap-y-1">
                          <p className="text-gray-300">
                            {profile?.has_secret ? "Change" : "Create"} PIN
                          </p>

                          <PasswordInput
                            inputClassName="bg-zinc-800"
                            name={
                              profile?.has_secret ? "old_pin" : "create_pin"
                            }
                            placeholder={
                              profile?.has_secret ? "Old PIN" : "New PIN"
                            }
                          />

                          {profile?.has_secret && (
                            <PasswordInput
                              id="new_password"
                              name="new_pin"
                              inputClassName="bg-zinc-800"
                              placeholder="New PIN"
                            />
                          )}
                        </div>
                        {user?.profile?.is_master && (
                          <>
                            <hr />

                            <div className="flex flex-col gap-y-1">
                              <p className="text-gray-300">Maturity</p>
                              <div
                                className="flex items-center cursor-pointer"
                                onClick={() => setIsKid((pre) => !pre)}
                              >
                                {isKid ? (
                                  <BiCheckboxChecked className="text-4xl" />
                                ) : (
                                  <BiCheckbox className="text-4xl" />
                                )}
                                <span>Kid profile</span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {state && (
                      <Alert color={state.success ? "green" : "rose"}>
                        {state.message}
                      </Alert>
                    )}

                    <hr />

                    <div className="flex mt-4 gap-x-4 justify-end">
                      <div className="">
                        <SubmitButton
                          isProcessing={processing}
                          className="px-3 normal-case !font-normal !tracking-normal text-base"
                        >
                          Save
                        </SubmitButton>
                      </div>
                      <button
                        type="button"
                        className="text-gray-800 bg-gray-200 px-3 rounded"
                        onClick={setOpen}
                      >
                        Close
                      </button>
                    </div>
                  </Form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditProfileDialog;
