import React, { useEffect } from "react";
import { useModalContext } from "../../imports/hooks";

const args = {
  playerRef: null,
  children: null,
  movie: {},
  playing: false,
  className: "",
};

const MoreInfoButton = (props = { ...args }) => {
  const { children, className, movie, playerRef, playing } = {
    ...args,
    ...props,
  };
  const { show, dispatch } = useModalContext();

  const handleShowMovieInfo = () => {
    dispatch({
      type: "SHOW",
      payload: {
        content: movie,
        slug: "movie-info",
        reference: playerRef,
      },
    });
  };

  useEffect(() => {
    if (playerRef?.current) {
      if (!show && playing && playerRef.current && playerRef.current.paused) {
        playerRef.current.play();
      }
    }
  }, [show, playing, playerRef]);

  return (
    <button
      onClick={handleShowMovieInfo}
      className={`${className} p-2 md:py-4 transition-all duration-150 ease-in-out`}
    >
      {children}
    </button>
  );
};

export default MoreInfoButton;
