import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "animate.css";

import {
  AddProfileDialog,
  EditProfileDialog,
  ExpandDialog,
  MovieInfoDialog,
  ProcessDialog,
  VerifyPinDialog,
} from "./imports/components";
import Pages from "./imports/pages";
import Layouts from "./imports/layouts";
import RouteWrapper from "./layouts/RouteWrapper";
import useAuth from "./hooks/auth-hook";

function App() {
  const { state } = useLocation();
  const {
    redirect,
    authenticated,
    status_code,
    data = {},
    success,
    params,
  } = useAuth();
  window.$ = window.jQuery = require("jquery");

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <RouteWrapper redirectIf={success} fallback={redirect}>
              <Pages.Index />
            </RouteWrapper>
          }
        />

        <Route
          element={
            <RouteWrapper
              redirectIf={!data.profile || !authenticated}
              fallback={redirect}
            />
          }
        >
          <Route path="browse" element={<Pages.Browse />} />
          <Route path="movies" element={<Pages.Movies />} />
          <Route path="search" element={<Pages.Search />} />
          <Route
            path="watch"
            element={
              <RouteWrapper
                redirectIf={!params.has("tkn") || !params.has("ref")}
                fallback={redirect}
              >
                <Pages.Watch />
              </RouteWrapper>
            }
          />
        </Route>

        <Route
          path="choose-profile"
          element={
            <RouteWrapper
              redirectIf={!success || data?.profile}
              fallback={redirect}
            >
              <Pages.ChooseProfile />
            </RouteWrapper>
          }
        />

        <Route
          path="manage-profiles"
          element={
            <RouteWrapper redirectIf={!success} fallback={redirect}>
              <Pages.ManageProfiles />
            </RouteWrapper>
          }
        />

        <Route
          path="login"
          element={
            <RouteWrapper
              fallback={redirect}
              redirectIf={authenticated && redirect !== "/login"}
            >
              <Pages.SignIn data={state?.emailOrPhone} />
            </RouteWrapper>
          }
        />
        <Route
          path="forgot-password"
          element={
            <RouteWrapper fallback={redirect} redirectIf={authenticated}>
              <Pages.ForgotPassword />
            </RouteWrapper>
          }
        />

        <Route
          path="reset-password"
          element={
            <RouteWrapper
              fallback={redirect}
              redirectIf={!state || !state?.auth || !state?.message}
            >
              <Pages.ResetPassword data={state} />
            </RouteWrapper>
          }
        />

        <Route
          path="verify-otp"
          element={
            <RouteWrapper
              fallback={redirect}
              redirectIf={!state || !state?.message}
            >
              <Pages.VerifyOTP data={state} />
            </RouteWrapper>
          }
        />

        <Route path="signup/" element={<Layouts.CreateAccount />}>
          <Route
            element={
              <RouteWrapper redirectIf={authenticated} fallback={redirect} />
            }
          >
            <Route index element={<Pages.SignUpInto />} />
            <Route path="registration" element={<Pages.RegisterPhoneEmail />} />
          </Route>

          <Route
            path="password"
            element={
              <RouteWrapper
                redirectIf={state?.phone && state?.status}
                invertCondition={true}
                fallback="/signup/registration"
              >
                <Pages.CreateVerifyPassword />
              </RouteWrapper>
            }
          />

          <Route
            path="plan"
            element={
              <RouteWrapper
                redirectIf={!authenticated}
                fallback={redirect}
                conditions={[
                  {
                    condition: !(state?.plans && state?.categories),
                    fallback: "/signup/plan/choose",
                  },
                ]}
              >
                <Pages.PlanIntro />
              </RouteWrapper>
            }
          />

          <Route
            path="plan/choose"
            element={
              <RouteWrapper
                redirectIf={
                  !authenticated || !(state?.plans && state?.categories)
                }
                fallback={redirect}
              >
                <Pages.ChoosePlan data={{ ...state }} />
              </RouteWrapper>
            }
          />

          <Route
            path="profiles"
            element={
              <RouteWrapper
                fallback={redirect}
                redirectIf={!authenticated || status_code !== 202 || success}
              >
                <Pages.Profiles />
              </RouteWrapper>
            }
          />

          <Route
            path="payments"
            element={
              <RouteWrapper
                redirectIf={!authenticated || status_code === 202}
                fallback={redirect}
                conditions={[
                  {
                    redirectIf: !state?.plan,
                    fallback: "/signup/plan/choose",
                  },
                ]}
              />
            }
          >
            <Route path="card" element={<Pages.CardPayment data={state} />} />
            <Route index element={<Pages.ChoosePaymentMethod data={state} />} />
            <Route
              path="orange-money"
              element={<Pages.OrangePayment data={state} />}
            />
          </Route>
        </Route>

        <Route
          path="account/"
          element={
            <RouteWrapper redirectIf={!authenticated} fallback={redirect} />
          }
        >
          <Route
            element={
              <RouteWrapper redirectIf={!data.profile} fallback={redirect} />
            }
          >
            <Route index element={<Pages.Account />} />
            <Route path="signout-all" element={<Pages.SignOutAll />} />
            <Route path="verify-phone" element={<Pages.VerifyPhone />} />
            <Route path="payment-plan" element={<Pages.PaymentPlan />} />
            <Route path="payment-info" element={<Pages.PaymentInfo />} />
            <Route path="cancel" element={<Pages.CancelSubscription />} />
            <Route path="manage-access" element={<Pages.ManageAccess />} />
            <Route path="manage-access" element={<Pages.ManageAccess />} />
            <Route path="email-account" element={<Pages.AddEditEmail />} />
            <Route path="billing-details" element={<Pages.BillingDetails />} />
            <Route path="change-password" element={<Pages.ChangePassword />} />
            <Route path="create-profiles" element={<Pages.CreateProfiles />} />
            <Route
              path="profiles-signout"
              element={<Pages.SignOutProfiles />}
            />
            <Route
              path="change-phone"
              element={
                <RouteWrapper
                  redirectIf={!state || !state?.auth || !state?.message}
                >
                  <Pages.ChangePhone data={state} />
                </RouteWrapper>
              }
            />
          </Route>

          <Route
            path="restart-membership"
            element={
              <RouteWrapper
                redirectIf={status_code !== 410}
                fallback={redirect}
              >
                <Pages.RestartMembership />
              </RouteWrapper>
            }
          />

          <Route
            path="subscription"
            element={
              <RouteWrapper
                redirectIf={status_code !== 402}
                fallback={redirect}
              >
                <Pages.Subscription />
              </RouteWrapper>
            }
          />
        </Route>

        <Route
          path="account/payment"
          element={
            <RouteWrapper
              redirectIf={
                !authenticated ||
                status_code !== 402 ||
                !state?.plan ||
                !state?.caption
              }
              fallback={redirect}
            />
          }
        >
          <Route element={<Layouts.CreateAccount />}>
            <Route
              path="orange-money"
              element={
                <Pages.OrangePayment
                  data={{ plan: state?.plan }}
                  caption={state?.caption}
                  isSignUp={state?.isSignUp}
                />
              }
            />

            <Route
              path="card"
              element={
                <Pages.Pay
                  plan={state?.plan}
                  caption={state?.caption}
                  isSignUp={state?.isSignUp}
                />
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<Pages.NotFound />} />
      </Routes>

      <ExpandDialog />
      <VerifyPinDialog />
      <AddProfileDialog />
      <EditProfileDialog />
      <MovieInfoDialog />
      <ProcessDialog />
    </>
  );
}

export default App;
