import React from "react";
import Spinner from "../custom/Spinner";
import LinkButton from "./LinkButton";

const args = {
  href: "",
  state: {},
  replace: false,
  className: "",
  children: null,
  processing: false,
  onClick: () => {},
  replaceClick: false,
};
const NextButton = (props = { ...args }) => {
  props = { ...args, ...props };

  return (
    <LinkButton
      href={props.href}
      state={props.state}
      replace={props.replace}
      replaceClick={props.replaceClick}
      onClick={props.onClick}
      disabled={props.processing}
      className={`bg-primary text-xl w-full hover:text-white uppercase letter-spaced font-semibold text-[#e5e5e5] py-3 ${props.className} disabled:cursor-not-allowed`}
    >
      {props.processing ? <Spinner size={1} /> : props.children ?? "Next"}
    </LinkButton>
  );
};

export default NextButton;
