import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/tailwind.css";
import "tw-elements";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  AuthContextProvider,
  ModalContextProvider,
  RenderProvider,
} from "./imports/contexts";
import { QueryClient, QueryClientProvider } from "react-query";

const client = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <AuthContextProvider>
          <ModalContextProvider>
            <RenderProvider>
              <App />
            </RenderProvider>
          </ModalContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
