import React from "react";
import { Footer, LinkButton, Loader } from "../../imports/components";
import { ManageAccountLayout } from "../../imports/layouts";
import { API, toDate } from "../../imports/utils";
import { usePost } from "../../imports/hooks";
import { BsCircleFill } from "react-icons/bs";
import { ArrowRightIcon } from "@heroicons/react/outline";

function CancelSubscription() {
  const { data: lowest = {} } = API.Get({
    path: "static/plans/lowest",
  });

  const { data: subscription = {}, isLoading } = API.Get({
    path: "subscriptions/current",
  });

  const { post: cancel } = usePost("subscriptions/cancel", {
    toggleOnSuccess: false,
    redirect: true,
    processingText: "Cancelling subscription...",
    showProcessing: true,
  });

  const { post } = usePost("subscriptions/card/change-plan", {
    toggleOnSuccess: false,
    redirect: true,
    processingText: "Changing plan...",
    showProcessing: true,
    clientKey: "subscription",
  });

  function getLast(plan) {
    const last = plan.meta.devices[plan.meta.devices.length - 1];
    if (last?.name) {
      last.name = last.name.toLowerCase();
    }
    return last;
  }

  function handleChangeToLowest() {
    const data = new FormData();
    data.append("plan", lowest.id);

    post(null, data);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ManageAccountLayout className="max-w-xl">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold">
            It is easy to cancel or switch plans
          </h2>
          <p className="text-gray-500">
            Thanks for spending time with us. You’re always welcome back. Finish
            your cancellation below.
          </p>
        </div>

        <div className="p-6 bg-white border border-zinc-400">
          <div className="space-y-2">
            <div className="flex items-start space-x-3">
              <BsCircleFill className="mt-1" />
              <span>
                Cancellation will be effective immediately you accept to finish
                cancellation process using the button below.
              </span>
            </div>
            <div className="flex items-start space-x-3">
              <BsCircleFill className="mt-1" />
              <span>
                Come back anytime; your profiles, viewing preferences and
                account details will be waiting for you.
              </span>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 py-3 border-b border-zinc-300">
            <div className="col-span-1">
              <button
                onClick={() => cancel(null, {})}
                className="text-xs w-full shadow-md bg-green-500 px-5 py-3 rounded-sm text-white"
              >
                Finish Cancellation
              </button>
            </div>
            <div className="col-span-1">
              <LinkButton
                href="/account"
                className="text-xs w-full shadow-md bg-zinc-300 px-5 py-3 rounded-sm"
              >
                Go Back
              </LinkButton>
            </div>
          </div>

          {lowest?.id !== subscription?.plan?.id && (
            <>
              <div className="space-y-6 my-4">
                <button
                  onClick={handleChangeToLowest}
                  className="text-xs w-full shadow bg-zinc-300 px-5 py-3 rounded-sm"
                >
                  Change to {lowest?.title}
                </button>

                <div className="flex space-x-2 justify-center items-center text-center text-lg font-semibold">
                  <span className="text-zinc-500">
                    ${subscription?.plan?.price?.amount}
                  </span>
                  <ArrowRightIcon className="h-4 font-semibold text-zinc-500" />
                  <span>${lowest?.price?.amount}</span>
                </div>

                <div className="flex justify-center !mt-4">
                  <span className="text-xs max-w-[75%] text-center">
                    Good video quality in {lowest?.meta?.resolution?.quality} (
                    {lowest?.meta?.resolution?.dimension}). Watch on any{" "}
                    {lowest?.meta?.devices?.map &&
                      lowest?.meta?.devices
                        ?.map((d) => d.name?.toLowerCase())
                        .join(", ")
                        .replace(
                          `, ${getLast(lowest)?.name}`,
                          ` or ${getLast(lowest)?.name}`
                        )}
                  </span>
                </div>
              </div>

              <div className="flex justify-center pt-4 pb-2">
                <LinkButton
                  href="/account/payment-plan"
                  className="text-blue-700 hover:underline"
                >
                  Compare All Plans
                </LinkButton>
              </div>
            </>
          )}
        </div>
      </ManageAccountLayout>

      <Footer />
    </>
  );
}

export default CancelSubscription;
