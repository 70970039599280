import React, { useMemo } from "react";
import { API, toArray } from "../imports/utils";
import { Loader } from "../imports/components";
import ProfileCard from "../components/custom/ProfileCard";

const args = {
  title: "",
  className: "",
  clientKey: "profiles",
  isEdit: false,
};

const Profiles = (props = { ...args }) => {
  props = { ...args, ...props };
  const { data: profiles, isLoading } = API.Get({
    path: "accounts/get-max-profiles",
    key: props.clientKey,
  });
  const max = useMemo(() => Number(profiles?.max || 1), [profiles]);

  return isLoading ? (
    <Loader />
  ) : (
    <div
      className={`animate__animated animate__fadeIn flex items-center justify-center min-w-screen min-h-screen flex-col ${props.className}`}
    >
      <h2 className="font-bold text-3xl sm:text-5xl text-center text-white mb-10">
        {props.title}
      </h2>

      <div
        className={`grid grid-cols-2 sm:grid-cols-${max} gap-4 px-14 sm:default-padding relative`}
      >
        {toArray(max).map((profile, index) => {
          return (
            <div
              className="col-span-1 flex min-w-full max-h-fit items-center justify-center max-w-[8rem]"
              key={`profile_${profile}${index}`}
            >
              <ProfileCard
                index={index}
                clientKey={props.clientKey}
                profile={profiles?.data?.[index]}
                isEdit={props.isEdit && profiles?.data?.[index]}
              />
            </div>
          );
        })}
      </div>

      {props?.children}
    </div>
  );
};

export default Profiles;
