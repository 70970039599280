import React, { useMemo } from "react";
import { API, toArray } from "../../imports/utils";
import {
  AccountNavigation,
  Alert,
  FloatingInput,
  LinkButton,
  SubmitButton,
} from "../../imports/components";
import { usePost } from "../../imports/hooks";
import { UserAddIcon } from "@heroicons/react/outline";

function Profiles() {
  const { data: profiles } = API.Get({
    path: "accounts/get-max-profiles",
  });

  const newProfiles = useMemo(() => {
    if (profiles?.max) {
      const max = profiles.max - profiles.data?.length;
      return max > 0 ? toArray(max) : [];
    }

    return [];
  }, [profiles]);

  const { post, processing, state } = usePost("profiles", {
    redirect: true,
    successCodes: [201],
    delay: 4000,
  });

  return (
    <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
      <AccountNavigation bg="black" showButton={false} showProfile={true} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen text-black">
        <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
          <div className="w-full space-y-4 max-w-5xl pt-8"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full md:max-w-3xl lg:max-w-6xl default-padding gap-3">
            <div className="cols-span-1 lg:col-span-2">
              {/* <small>STEP 3 OF 4</small> */}
              <h2 className="font-semibold text-3xl">
                Who will be watching with you?
              </h2>
              <p className="lg:max-w-lg">
                People living in your home can enjoy recommendations tailored to
                their tastes and watching preferences. Great for kids.
              </p>
            </div>

            <div className="col-span-1">
              <div className="gap-y-6 flex w-full flex-col">
                <div className="flex flex-col gap-y-1">
                  <p className="font-semibold text-black/75">
                    Your profile{profiles?.data?.length > 1 ? "s" : ""}
                  </p>
                  {profiles?.data?.map &&
                    profiles?.data.map((profile) => {
                      return (
                        <FloatingInput
                          id="profile_0"
                          readOnly={true}
                          key={profile.id}
                          placeholder="Name"
                          name="profiles[0][name]"
                          defaultValue={profile.name}
                          inputClassName="w-full bg-zinc-300 rounded-[.15rem] cursor-not-allowed"
                        />
                      );
                    })}
                </div>

                {newProfiles.length > 0 ? (
                  <form onSubmit={post} className="flex flex-col gap-y-2">
                    <p className="font-semibold text-black/75">
                      Add profile{newProfiles.length > 1 ? "s" : ""}
                    </p>
                    <div className="flex flex-col gap-y-3">
                      {newProfiles.map((profile, index) => {
                        return (
                          <div
                            key={`profile_${profile}_${index}`}
                            className="flex w-full"
                          >
                            <UserAddIcon className="w-6" />
                            <div className="flex-1">
                              <FloatingInput
                                name={`profiles[${index + 1}][name]`}
                                id={`profile_${index + 1}`}
                                inputClassName="bg-zinc-300 rounded-[.15rem]"
                                placeholder="Name"
                              />
                            </div>
                            <label
                              htmlFor={`kid_${index}`}
                              className="flex items-center space-x-1 pr-2 rounded-r bg-zinc-300 cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                defaultValue={"true"}
                                className="accent-emerald-600/60"
                                id={`kid_${index}`}
                                name={`profiles[${index + 1}][is_kid]`}
                              />
                              <span className="text-sm text-black/80">
                                Kid?
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>

                    <div className="sm:pl-5 w-full space-y-4">
                      <Alert color="secondary" className="bg-blue-100 w-full">
                        Only people who live with you may use your account.{" "}
                        <LinkButton
                          href="/account/help"
                          className="text-blue-700 hover:underline"
                        >
                          Learn more
                        </LinkButton>
                      </Alert>

                      {state && (
                        <Alert>
                          <p
                            className="m-0"
                            dangerouslySetInnerHTML={{ __html: state.message }}
                          ></p>
                        </Alert>
                      )}

                      <SubmitButton
                        isProcessing={processing}
                        className="text-white"
                      >
                        Next
                      </SubmitButton>
                    </div>
                  </form>
                ) : (
                  <LinkButton
                    href="/account"
                    className="text-blue-500 hover:underline"
                  >
                    Return to account
                  </LinkButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profiles;
