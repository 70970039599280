import React, { useMemo } from "react";
import {
  Banner,
  FilterNav,
  Header,
  Loader,
  MovieCard,
  MovieSlider,
} from "../../imports/components";
import { useAuth, useGenres, useMovies, useTags } from "../../imports/hooks";

function Movies() {
  const { params } = useAuth();
  const { movies } = useMovies({ type: "movie", enabled: params.has("so") });

  const { genres: data, fetching } = useGenres({
    type: "movie",
    includeMovies: true,
    includeCategories: ["new", "trending"],
  });

  const { tags } = useTags(params.get("rfn"), {
    type: "movie",
    includeMovies: true,
    enabled: params.has("rfn"),
    includeCategories: ["new", "trending"],
  });

  const genres = useMemo(() => {
    return params.has("rfn") ? tags : data;
  }, [data, params, tags]);

  const sort = useMemo(() => {
    if (params.has("so")) {
      return params.get("so");
    }

    return false;
  }, [params]);

  const FilterElement = <FilterNav />;

  function getSort(contents) {
    const sorted = contents.sort((a, b) => {
      switch (sort) {
        case "yr":
          return b.year_released - a.year_released;
        case "az":
          return a.title?.text.localeCompare(b.title?.text);
        case "za":
          return b.title?.text.localeCompare(a.title?.text);
        default:
          return 1;
      }
    });

    return sorted;
  }

  return (
    <>
      <Header />

      {fetching ? (
        <Loader isMovies={true} className="mt-14 default-padding" />
      ) : (
        <>
          {!sort ? (
            <Banner
              filterElement={FilterElement}
              type="movie"
              genre={
                params.has("rfn")
                  ? genres.find((c) => c.slug === params.get("rfn"))?.id
                  : "all"
              }
            >
              {genres.map((genre) => (
                <MovieSlider key={genre.id} genre={genre} />
              ))}
            </Banner>
          ) : (
            <div className="default-padding">
              <div className="mt-14 sm:mt-[10vh] mb-10">{FilterElement}</div>

              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-[0.3em] transition duration-150">
                {getSort(movies).map((movie) => {
                  return (
                    <MovieCard
                      key={movie.id}
                      movie={movie}
                      maxWidth="full"
                      className="col-span-1"
                    />
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Movies;
