import { ChevronRightIcon } from "@heroicons/react/solid";
import React, { useMemo } from "react";
import useAuth from "../hooks/auth-hook";
import { AccountNavigation, Footer, LinkButton } from "../imports/components";
import Statics, { Images } from "../imports/statics";

function Index() {
  return (
    <>
      <header className="relative animate__animated animate__fadeIn">
        <AccountNavigation />

        <div className="relative py-16 w-screen max-w-full min-h-[25rem] sm:min-h-[85vh] md:min-h-screen xl:min-h-[90vh] max-h-screen object-cover transition-all duration-75">
          <img
            src={Images.Cover}
            alt="cover_image"
            loading="eager"
            className="absolute top-0 left-0 inset-0 -z-10 w-full max-w-full min-h-full max-h-full object-cover"
          />

          <div className="bg-radial-gradient flex items-center justify-center absolute top-0 w-full min-h-full min-w-full max-w-full max-h-full">
            <div className="flex flex-col items-center space-y-4 sm:space-y-10 max-w-max sm:max-w-2md text-center md:max-w-2xl">
              <h3 className="max-w-lg text-[2rem] !leading-tight transition duration-200 font-semibold sm:text-5xl md:text-6xl md:max-w-max px-4 sm:px-0">
                Unlimited movies, TV shows, and more...
              </h3>

              <StartButton />
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-full animate__animated animate__fadeIn">
        <section className="bordered-top section-content">
          <div className="section-grid">
            <div className="col-span-1">
              <h2 className="section-header">Enjoy on your Smart TV.</h2>
              <p className="text-xl">
                Watch all your favourite movies, TV shows on Ultra high
                definition on your widescreen.
              </p>
            </div>
            <div className="col-span-1 relative">
              <div className="img-cover">
                <img
                  src={Images.Television}
                  alt="smart_device_anim"
                  loading="eager"
                  className="object-contain w-full max-w-full"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bordered-top section-content">
          <div className="section-grid">
            <div className="col-span-1 relative">
              <div className="img-cover">
                <img
                  src={Images.Mobile}
                  alt="smart_device_anim"
                  loading="eager"
                  className="object-contain w-full max-w-full"
                />
              </div>
            </div>
            <div className="col-span-1 row-start-1 md:col-start-2">
              <h2 className="section-header">
                Watch shows and movies on the go!
              </h2>
              <p className="text-xl">
                Stream unlimited movies and TV shows on your phone, tablet,
                laptop, and more, everywhere, at anywhere.
              </p>
            </div>
          </div>
        </section>

        <section className="bordered-top section-content">
          <div className="section-grid">
            <div className="col-span-1">
              <h2 className="section-header">Made for kids.</h2>
              <p className="text-xl">
                Create profile for your kids and enable them to watch their
                favourite characters in a space made just for them with your
                standard and Premium membership.
              </p>
            </div>
            <div className="col-span-1 relative">
              <div className="img-cover">
                <img
                  src={Images.Kids}
                  alt="smart_device_anim"
                  loading="eager"
                  className="object-contain w-full max-w-full"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bordered-top bordered-bottom section-content">
          <div className="text-center default-padding max-w-max sm:max-w-xl py-10">
            <h2 className="section-header">Watch everywhere.</h2>
            <p className="text-xl">
              Stream unlimited movies and TV shows on your phone, tablet,
              laptop, and TV.
            </p>
          </div>
        </section>

        <section className="bordered-bottom section-content">
          <div className="default-padding sm:w-[80%] sm:max-w-[80%] py-10">
            <h2 className="section-header text-center mb-5">
              Frequently Asked Questions.
            </h2>
            <div className="accordion" id="faqs">
              {Statics.Json.FAQs.map((faq, index) => {
                return (
                  <div key={`faq_${index}`} className="accordion-item mb-5">
                    <h2
                      className="accordion-header mb-0"
                      id={`heading_${index + 1}`}
                    >
                      <button
                        type="button"
                        data-bs-target={`#faq_${index}`}
                        data-bs-toggle="collapse"
                        className="collapsed accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-800 text-left bg-white border-0 !rounded-none transition focus:outline-none sm:text-xl"
                        aria-expanded={true}
                        aria-controls={`faq_${index}`}
                      >
                        {faq.question}
                      </button>
                    </h2>
                    <div
                      className="accordion-collapse collapse bg-white !rounded-none"
                      data-bs-parent="#faqs"
                      id={`faq_${index}`}
                      aria-labelledby={`heading_${index + 1}`}
                    >
                      <div className="accordion-body px-5 py-4 text-secondary">
                        {faq.answer}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col items-center justify-center mt-10">
              <h2 className="text-center mb-3 text-2xl font-semibold">
                Ready to watch?
              </h2>
              <StartButton />
            </div>
          </div>
        </section>
      </main>

      <Footer
        className="animate__animated animate__fadeIn"
        showLocation={true}
        links={[
          "FAQ",
          "Help Center",
          "Account",
          "Media Center",
          "Investor Relation",
          "Ways to watch",
          "Terms of Use",
          "Privacy",
          "Cookie Preference",
          "Corporate Information",
          "Contact Us",
          "Speed Test",
          "Legal Notices",
          "Only on Sierraflixx",
        ]}
      />
    </>
  );
}

function StartButton() {
  const { authenticated, redirect, message, status_code } = useAuth();

  const goto = useMemo(() => {
    const excepts = [402, 410];

    return {
      redirect,
      text: excepts.includes(status_code) ? message : "Finish Account",
    };
  }, [redirect, status_code, message]);

  return (
    <LinkButton
      href={authenticated ? goto.redirect : "/signup"}
      className="flex items-center min-w-max bg-primary px-10 py-3 rounded-[.15rem] sm:text-xl sm:py-5 sm:px-16 md:py-4 md:px-16 font-semibold justify-center letter-spaced uppercase"
    >
      {authenticated ? goto.text : "START NOW"}
      <ChevronRightIcon className="w-6 h-6 sm:w-8 sm:h-8" />
    </LinkButton>
  );
}

export default Index;
