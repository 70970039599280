import { ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";
import { BiMailSend } from "react-icons/bi";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { useAuth, usePost } from "../../imports/hooks";
import { ManageAccountLayout } from "../../imports/layouts";
import { LinkButton } from "../../imports/components";

function VerifyPhone() {
  const { data: user } = useAuth();
  const { post } = usePost("static/send-otp", {
    redirect: true,
    setState: true,
    replace: false,
    showProcessing: true,
    toggleOnSuccess: false,
    processingText: "Texting code",
  });

  function handlePost(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("param", user.phone);
    formData.append("action", "change phone");
    formData.append("callback", "/account/change-phone");

    post(null, formData);
  }

  return (
    <ManageAccountLayout className="max-w-3xl">
      <div className="flex flex-col items-center justify-center space-y-4">
        <MdOutlineVerifiedUser className="text-6xl sm:text-8xl text-orange-700" />

        <div className="text-center space-y-3">
          <h4 className="font-extrabold text-2xl sm:text-3xl">
            First, let's confirm your identity.
          </h4>
          <p className="text-lg">
            Before you make any changes, we'll just need to make sure it's you.
          </p>
        </div>

        <div className="sm:px-8 w-full">
          <div className="bg-white rounded-md shadow-md w-full">
            {[
              {
                title: "Text",
                value: user?.phone,
                icon: <BiMailSend className="text-4xl text-primary" />,
              },
            ].map((prop, index) => {
              return (
                <LinkButton
                  href="/verify-otp"
                  onClick={handlePost}
                  replaceClick={true}
                  key={`prop_${index}`}
                  className="p-6 w-full flex justify-between items-center border-b-2 last:border-0 disabled:opacity-50"
                >
                  <div className="flex items-center space-x-4">
                    {prop.icon}
                    <div className="flex-col flex text-start">
                      <span className="font-bold text-xl">
                        {prop.title} a code
                      </span>
                      <small>{prop.value}</small>
                    </div>
                  </div>

                  <ChevronRightIcon className="h-6 text-gray-400" />
                </LinkButton>
              );
            })}
          </div>
        </div>
      </div>
    </ManageAccountLayout>

    // <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
    //   <AccountNavigation bg="black" showButton={false} showProfile={true} />

    //   <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
    //     <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
    //       <div className="w-full space-y-4 max-w-3xl md:px-12 pt-8">
    //         <LinkButton
    //           href="/account"
    //           className="hover:underline flex items-center space-x-2 font-semibold text-blue-800"
    //         >
    //           <BsArrowLeft className="text-2xl" />

    //           <span>Back to account</span>
    //         </LinkButton>

    //         <div className="flex flex-col items-center justify-center space-y-4">
    //           <MdOutlineVerifiedUser className="text-6xl sm:text-8xl text-orange-700" />

    //           <div className="text-center space-y-3">
    //             <h4 className="font-extrabold text-2xl sm:text-3xl">
    //               First, let's confirm your identity.
    //             </h4>
    //             <p className="text-lg">
    //               Before you make any changes, we'll just need to make sure it's
    //               you.
    //             </p>
    //           </div>

    //           <div className="sm:px-8 w-full">
    //             <div className="bg-white rounded-md shadow-md w-full">
    //               {[
    //                 {
    //                   title: "Text",
    //                   value: user?.phone,
    //                   icon: <BiMailSend className="text-4xl text-primary" />,
    //                 },
    //               ].map((prop, index) => {
    //                 return (
    //                   <LinkButton
    //                     href="/verify-otp"
    //                     onClick={handlePost}
    //                     replaceClick={true}
    //                     key={`prop_${index}`}
    //                     className="p-6 w-full flex justify-between items-center border-b-2 last:border-0 disabled:opacity-50"
    //                   >
    //                     <div className="flex items-center space-x-4">
    //                       {prop.icon}
    //                       <div className="flex-col flex text-start">
    //                         <span className="font-bold text-xl">
    //                           {prop.title} a code
    //                         </span>
    //                         <small>{prop.value}</small>
    //                       </div>
    //                     </div>

    //                     <ChevronRightIcon className="h-6 text-gray-400" />
    //                   </LinkButton>
    //                 );
    //               })}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default VerifyPhone;
