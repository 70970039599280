import React, { useRef } from "react";
import { CountriesDropdown, FloatingInput } from "../../imports/components";

const args = {
  defaultValue: {},
  required: false,
  autoFocus: false,
  name: "phone",
  setCode: () => {},
  className: "",
  inputClassName: "",
  placeholder: "Phone",
};

const PhoneInput = (props = { ...args }) => {
  props = { ...args, ...props };

  const ref = useRef();

  return (
    <div className={`relative w-full ${props.className}`}>
      <FloatingInput
        name={props.name}
        id={props.name}
        elementRef={ref}
        type="number"
        required={props.required}
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onKeyDown={props.handleKeyDown}
        className="peer"
        feedback={props?.feedback}
        inputClassName={`bg-zinc-200 ${props.inputClassName}`}
      />

      <CountriesDropdown elementRef={ref} setCode={props.setCode} />
    </div>
  );
};

export default PhoneInput;
