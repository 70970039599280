import React, { useState, useEffect } from "react";
import { BiCheckDouble, BiLock } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { usePost } from "../../imports/hooks";
import {
  AccountNavigation,
  Form,
  SubmitButton,
  LinkButton,
  Alert,
  PasswordInput,
  PaymentMethods,
} from "../../imports/components";
import { BsArrowLeft } from "react-icons/bs";

function AddPaymentMethod() {
  const { state: data } = useLocation();
  const [status, setStatus] = useState(null);
  const { post, processing, state } = usePost("accounts/change-password", {
    delay: 8000,
    redirect: false,
    toggleOnSuccess: true,
  });

  useEffect(() => {
    if (state && state?.message) {
      setStatus(state);
    }
  }, [state]);

  return (
    <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
      <AccountNavigation
        bg="transparent"
        useLink={true}
        showButton={true}
        className="text-black border-b border-zinc-300"
      />

      <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
        <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
          <div className="w-full space-y-4 max-w-xl md:px-12 pt-8">
            <div className="text-center flex flex-col items-center space-y-6 mb-4">
              <BiLock className="text-4xl md:text-6xl rounded-full p-2 text-primary border-4 border-primary" />

              <h1 className="text-xl font-bold text-center">
                Add Payment Method
              </h1>

              <span className="text-md">
                Never miss a month. We'll only use your backup payment method if
                we can't process your main one.
              </span>
            </div>
          </div>

          <div className="w-full space-y-4 max-w-xl md:px-12">
            <PaymentMethods
              root="/account/add-payment-method"
              suffix="-payment"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentMethod;
