import React, { useState } from "react";
import { useSignIn } from "../imports/hooks";
import {
  AccountNavigation,
  Alert,
  EmailPhoneInput,
  Footer,
  Form,
  LinkButton,
  PasswordInput,
  SubmitButton,
} from "../imports/components";
import { getPhoneNumber } from "../imports/utils";
import { Images } from "../imports/statics";

const args = {
  data: "",
};

function SignIn(props = { ...args }) {
  props = { ...args, ...props };
  const [code, setCode] = useState("");

  const { signIn: post, signingIn: processing, signedIn: state } = useSignIn();

  const handleLogin = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const uid = formData.get("email_or_phone");

    if (!isNaN(uid)) {
      formData.set("email_or_phone", getPhoneNumber(code, uid));
    }

    post(e, formData);
  };

  return (
    <div className="flex animate__animated animate__fadeIn flex-col min-h-screen w-full">
      <AccountNavigation showButton={false} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen relative bg-black sm:!bg-radial-gradient">
        <img
          src={Images.Cover}
          alt="bg_image"
          className="absolute -z-10 min-h-full max-h-full w-screen top-0 object-cover"
        />

        <div className="min-w-full max-w-full flex-1 flex flex-col justify-center items-center pt-20 pb-5">
          <div className="sm:bg-black-50 px-4 py-6 sm:px-14 sm:py-12 max-w-[28rem] w-full">
            <h4 className="text-4xl font-semibold mb-8">Sign In</h4>
            {state && (
              <Alert color="rose" className="mb-2">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: state.message }}
                ></p>
              </Alert>
            )}
            <Form
              noValidate={true}
              onSubmit={handleLogin}
              className="pb-3 group/form"
            >
              <div className="space-y-6 mb-12">
                <EmailPhoneInput
                  inputClassName="bg-zinc-900"
                  name="email_or_phone"
                  id="email_or_phone"
                  autoFocus={true}
                  required={true}
                  setCode={setCode}
                  defaultValue={props.data}
                  feedback="Please enter a valid email or phone number."
                />
                <PasswordInput required={true} inputClassName="bg-zinc-900" />
              </div>

              <SubmitButton isProcessing={processing}>Sign In</SubmitButton>

              <div className="grid grid-cols-2 mt-8">
                <div className="col-span-1 flex gap-x-2">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    defaultChecked={true}
                    defaultValue="true"
                    className="accent-primary w-4 cursor-pointer"
                  />
                  <label
                    htmlFor="remember"
                    className="text-gray-400 hover:text-gray-300 cursor-pointer"
                  >
                    Remember me
                  </label>
                </div>
                <div className="col-span-1 text-end">
                  <LinkButton
                    href="/forgot-password"
                    className="text-gray-400 hover:text-gray-300 hover:underline"
                  >
                    Forgot password?
                  </LinkButton>
                </div>
              </div>

              <div className="mt-10 flex gap-x-2">
                <span className="text-gray-400">New to Sierraflixx?</span>
                <LinkButton
                  href="/signup"
                  className="text-green-800 font-semibold hover:text-primary"
                >
                  Sign Up Now
                </LinkButton>
              </div>
            </Form>
          </div>
        </div>

        <Footer className="mt-auto sm:bg-black-50 max-w-full" />
      </div>
    </div>
  );
}

export default SignIn;
