import {
  RefreshIcon,
  VolumeOffIcon,
  VolumeUpIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useRef, useState } from "react";
import { storage } from "../../imports/utils";
import { PlayMovieButton } from "../../imports/components";
import { useAuth } from "../../imports/hooks";
import Player from "@gumlet/react-hls-player";

const args = {
  movie: {},
  reference: {},
  shown: false,
  setState: () => {},
};

const MovieBanner = (props = { ...args }) => {
  const ref = useRef();
  const { data: user } = useAuth();
  const [playing, setPlaying] = useState(false);
  const { movie, reference, setState, shown } = { ...args, ...props };
  const [muted, setMuted] = useState(
    storage.getData("spr", storage.path.session) ?? true
  );
  const [played, setPlayed] = useState(
    storage.getData("plr", storage.path.session) ?? false
  );

  const handleVideoEnded = () => {
    setPlaying(false);

    setTimeout(() => {
      setPlayed(true);
      if (ref.current) {
        ref.current.load();
        ref.current.scr = "";
      }
    }, 120);
  };

  const handlePlay = () => {
    if (ref.current) {
      if (playing) {
        storage.setData("spr", !muted, storage.path.session);
        if (reference?.current) {
          reference.current.muted = !muted;
        }
        setMuted((pre) => !pre);

        if (reference?.current) {
          reference.current.muted = !reference.current.muted;
        }
      } else {
        ref.current.play();
      }
    }
  };

  function videoPlaying(e) {
    setPlaying(true);
    document.querySelector("video").pause();
  }

  useEffect(() => {
    if (movie && shown) {
      const stored = storage.getData("spr", storage.path.session);

      if (reference?.current) {
        const { current: curr } = reference;
        setMuted(stored !== null ? stored : curr?.muted);
        ref.current.currentTime = curr.currentTime;
      } else {
        setMuted(stored !== null ? stored : true);
      }

      setState(ref);
    }
  }, [movie, ref, reference, shown, setState, user]);

  return (
    movie && (
      <div className="max-h=[100vw] lg:max-h-[85vw] xl:max-h-[90vh] sm:mb-0 relative w-full max-w-full aspect-video transition delay-100 bg-gradient-bottom overflow-hidden">
        <Player
          muted={muted}
          preload="none"
          playerRef={ref}
          poster={movie?.poster}
          src={movie?.trailers?.[0]}
          onEnded={handleVideoEnded}
          onPlaying={videoPlaying}
          autoPlay={user?.profile?.autoplay_browsing}
          className="object-cover relative -z-50 w-full max-h-full top-0 left-0"
        />

        <div className="h-full absolute max-h-full top-0 w-full">
          <div className="flex flex-col h-full max-h-[96%] px-4 sm:px-8 md:px-12 justify-end space-y-3">
            {playing && (
              <img
                src={movie?.title?.image}
                alt={movie?.title?.text + "_title"}
                width={196}
                loading="eager"
                className="hidden sm:block object-contain max-h-min animate__animated animate__fadeInDown"
              />
            )}

            <div className="flex justify-between max-w-full">
              <div className="flex items-center">
                <PlayMovieButton
                  movieId={movie?.id}
                  isWatched={movie?.is_watched}
                  key="movie_info_play"
                />
              </div>

              <div className="flex items-center">
                {(playing || played) && (
                  <button
                    onClick={handlePlay}
                    type="button"
                    className="rounded-button hover:bg-[#ffffff22]"
                  >
                    {played && !playing ? (
                      <RefreshIcon className="text-lg" />
                    ) : muted ? (
                      <VolumeOffIcon className="text-lg" />
                    ) : (
                      <VolumeUpIcon className="text-lg" />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MovieBanner;
