import React, { useMemo } from "react";

const Alert = ({
  children,
  isSuccess = false,
  color = "rose",
  className = "",
}) => {
  const col = useMemo(
    () => (isSuccess || color !== "rose" ? "green" : "rose"),
    [isSuccess, color]
  );

  return (
    <div
      className={`font-regular relative block bg-${col}-200 text-${col}-700 w-full rounded-md p-4 text-base leading-5 opacity-100 ${className}}`}
    >
      {children}
    </div>
  );
};

export default Alert;
