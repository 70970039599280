import React from "react";
import { useNavigate } from "react-router-dom";

const args = {
  as: "a",
  href: "",
  state: null,
  className: "",
  children: null,
  replace: false,
  disabled: false,
  replaceClick: false,
  onClick: () => {},
};

const LinkButton = (props = { ...args }) => {
  const {
    as,
    children,
    className,
    disabled,
    href,
    onClick,
    replace,
    replaceClick,
    state,
  } = { ...args, ...props };
  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    let data = state;

    if (onClick) {
      if (onClick instanceof Promise) {
        data = await onClick(e);
      } else {
        onClick(e);
      }
    }

    if (!replaceClick) {
      navigate(href, { replace, state: data });
    }
  };

  return (
    <button
      as={as}
      type="button"
      disabled={disabled}
      onClick={(e) => handleClick(e)}
      className={className}
    >
      {children}
    </button>
  );
};

export default LinkButton;
