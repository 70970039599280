import React from "react";

const SubmitButton = ({
  children,
  className = "",
  type = "submit",
  disabled = false,
  isProcessing = false,
  onClick = () => {},
}) => {
  return (
    <button
      disabled={disabled || isProcessing}
      type={type}
      onClick={onClick}
      className={`w-full p-2 letter-spaced uppercase font-semibold rounded-[.15rem] text-xl bg-primary disabled:opacity-60 disabled:cursor-not-allowed ${className}`}
    >
      {isProcessing ? (
        <div
          className="spinner-border animate-spin inline-block w-7 h-7 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Processing...</span>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default SubmitButton;
