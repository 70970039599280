import React, { useEffect, useState } from "react";
import { ChevronDoubleDownIcon } from "@heroicons/react/outline";
import { ProfileDropdown, Search } from "../../imports/components";
import { Images } from "../../imports/statics";
import { useAuth } from "../../imports/hooks";
import LinkButton from "./LinkButton";

const Header = ({ position = "fixed" }) => {
  const { navigate, search, pathname } = useAuth();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function scroll() {
      setScrolled(window.scrollY > 2);
    }

    window.addEventListener("scroll", scroll);

    return () => window.removeEventListener("scroll", scroll);
  }, []);

  return (
    <nav
      className={`${position} ${
        scrolled ? "bg-black bg-opacity-75" : "bg-gradient-top"
      }`}
    >
      <div className="flex items-center space-x-2 md:space-x-8 min-h-max">
        <LinkButton href="/browse">
          <img
            src={Images.Logo}
            alt="Site logo"
            width={174}
            loading="eager"
            className="cursor-pointer object-contain max-h-min"
          />
        </LinkButton>

        <ul className="hidden space-x-3.5 md:flex">{Links()}</ul>

        <div className="dropdown relative hover-drop group md:hidden">
          <button
            className="flex items-center tracking-wider space-x-[.15rem] h-full"
            type="button"
            aria-expanded="false"
          >
            <span className="text-[.85rem] sm:text-base">Browse</span>
            <ChevronDoubleDownIcon className="w-3 sm:w-4 icon" />
          </button>

          <ul className="dropdown-menu absolute !mt-1 invisible border space-y-3 px-4 p-3 bg-black">
            {Links()}
          </ul>
        </div>
      </div>

      <div className="flex items-center space-x-4 text-sm font-light">
        <div className="relative">
          <Search navigate={navigate} pathname={pathname} search={search} />
        </div>

        {/* <BellIcon className="w-6 hidden sm:block cursor-pointer" /> */}

        <ProfileDropdown />
      </div>
    </nav>
  );
};

export function Link({
  children,
  href = "",
  className = "",
  replaceClick = false,
  onClick = () => {},
}) {
  return (
    <LinkButton
      onClick={onClick}
      href={href}
      replaceClick={replaceClick}
      className={`dropdown-item tracking-wider text-start hover:underline hover:text-[#e5e5e5] text-sm w-max flex-1 ${className}`}
    >
      {children}
    </LinkButton>
  );
}

function Links() {
  const { pathname } = useAuth();

  return [
    {
      title: "Home",
      slug: "home",
      url: "/browse",
    },
    {
      title: "Movies",
      slug: "movies",
      url: "/movies",
    },
  ].map((link) => {
    return (
      <li key={link.slug} className="header-link">
        <LinkButton
          href={link.url}
          className={pathname !== link.url ? "text-gray-400" : ""}
        >
          {link.title}
        </LinkButton>
      </li>
    );
  });
}

export default Header;
