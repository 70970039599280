import React, { useMemo } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { BiPlay } from "react-icons/bi";
import { LinkButton, Footer, Loader } from "../../imports/components";
import { toDate, toArray, API, baseURL } from "../../imports/utils";
import { useAuth } from "../../imports/hooks";
import * as Icons from "react-icons/fa";
import { Images } from "../../imports/statics";
import { ManageAccountLayout } from "../../imports/layouts";

function Account() {
  const { data: user = {} } = useAuth();
  const { data: subscription = {}, isLoading } = API.Get({
    path: "subscriptions/current",
    key: "subscription",
  });
  const { data: profiles = [], isLoading: profiling } = API.Get({
    path: "accounts/get-profiles",
  });

  const paymentMethod = useMemo(() => {
    let method = {};

    if (subscription?.payment?.methods?.find) {
      const { methods } = subscription.payment;
      method = methods[0]?.is_default
        ? methods.find((p) => p.is_default)
        : methods[0];
      const Icon = Icons?.[method?.card?.icon];
      if (Icon) {
        method.card.icon = <Icon className="text-2xl text-black" />;
      }
    }

    return method;
  }, [subscription]);

  return isLoading || profiling ? (
    <Loader />
  ) : (
    <>
      <ManageAccountLayout showReturn={false}>
        <div className="sm:border-b sm:flex w-full border-zinc-400 pb-3 space-y-2 space-x-2 sm:space-x-5">
          <h2 className="text-4xl">Account</h2>

          <div className="flex space-x-2 items-center">
            <div className="relative flex text-xl sm:text-2xl text-primary">
              <BiPlay className="-rotate-12 border-2 rounded border-primary -translate-y-1 sm:-translate-y-2 -translate-x-1 sm:-translate-x-2" />
              <BiPlay className="absolute border-2 rounded border-primary left-0 bg-gray-200" />
            </div>
            <span className="font-bold text-zinc-600">
              Member Since{" "}
              {toDate(user?.created_at, {
                year: "numeric",
                month: "long",
              })}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:border-b sm:gap-y-8 border bg-white sm:bg-inherit px-4 sm:px-0 py-6">
          <div className="col-span-1 md:col-span-2 space-y-3">
            <h4 className="text-xl text-zinc-500 text-truncate uppercase">
              Membership & Billing
            </h4>

            <CancelButton className="hidden sm:block" />
          </div>

          <div className="col-span-1 md:col-span-5 space-y-3 md:pl-12 text-black">
            <section
              id="account"
              className="border-b border-zinc-300 !pt-0 pb-4 !px-0 space-y-2"
            >
              <div className="flex justify-between items-start">
                <span className="font-extrabold text-black">{user?.phone}</span>

                <LinkButton
                  href="/account/verify-phone"
                  className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                >
                  Change phone number
                </LinkButton>
              </div>

              <div className="flex justify-between items-start">
                <span className="text-zinc-500">
                  Password:{" "}
                  {toArray(8).map((v, i) => {
                    return <span key={`hash_${i}`}>&lowast;</span>;
                  })}
                </span>

                <LinkButton
                  href="/account/change-password"
                  className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                >
                  Change password
                </LinkButton>
              </div>

              <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
                <span className="text-zinc-500">
                  Email: {user?.email ?? "no email account"}
                </span>

                <LinkButton
                  href="email-account"
                  state={{ email: user?.email }}
                  className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                >
                  {user?.email ? "Change" : "Add"} email account
                </LinkButton>
              </div>

              <div className="sm:hidden !m-0">
                {[
                  {
                    title: "Change phone",
                    url: "verify-phone",
                  },
                  {
                    title: "Change password",
                    url: "change-password",
                  },
                  {
                    title: `${user?.email ? "Change" : "Add"} email account`,
                    url: "email-account",
                    state: { email: user?.email },
                  },
                ].map((prop, index) => {
                  return (
                    <LinkButton
                      state={prop?.state}
                      href={`/account/${prop.url}`}
                      key={`acc_prop_${index}`}
                      className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0"
                    >
                      <span>{prop.title}</span>
                      <ChevronRightIcon className="h-5 text-zinc-400" />
                    </LinkButton>
                  );
                })}
              </div>
            </section>

            <section id="payment" className="!pt-0 pb-4 !px-0 space-y-2">
              <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
                <div className="flex flex-col space-y-1">
                  <div className="flex space-x-2 items-center">
                    {paymentMethod?.card?.icon ? (
                      paymentMethod.card.icon
                    ) : (
                      <img
                        src={baseURL + subscription?.payment?.images?.[0]}
                        alt="payment_icon"
                        className="w-7 h-5 rounded"
                      />
                    )}

                    <span className="font-extrabold text-black">
                      •••• •••• ••••{" "}
                      {paymentMethod?.card
                        ? paymentMethod?.card?.last_four
                        : paymentMethod?.last_four}
                    </span>
                  </div>

                  <span className="text-zinc-600 text-base lg:text-[1rem]">
                    Your next billing date is{" "}
                    {toDate(subscription?.expires_at, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>

                <div className="space-y-2 flex flex-col items-end">
                  <LinkButton
                    href="/account/payment-info"
                    className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                  >
                    Manage payment info
                  </LinkButton>

                  <LinkButton
                    href="/account/billing-details"
                    className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                  >
                    Billing details
                  </LinkButton>
                </div>
              </div>

              <div className="sm:hidden !m-0">
                {[
                  {
                    title: "Manage payment info",
                    url: "payment-info",
                  },
                  {
                    title: "Billing details",
                    url: "billing-details",
                  },
                ].map((prop, index) => {
                  return (
                    <LinkButton
                      state={prop?.state}
                      href={`/account/${prop.url}`}
                      key={`acc_prop_${index}`}
                      className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0"
                    >
                      <span>{prop.title}</span>
                      <ChevronRightIcon className="h-5 text-zinc-400" />
                    </LinkButton>
                  );
                })}
              </div>
            </section>
          </div>

          <div className="col-span-1 sm:hidden px-4">
            <CancelButton className="w-full !py-3 !text-xl" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:border-b sm:gap-y-6 border bg-white sm:bg-inherit px-4 sm:px-0 pt-2 pb-6">
          <div className="col-span-1 md:col-span-2">
            <h4 className="text-xl text-zinc-500 text-truncate uppercase">
              plan details
            </h4>
          </div>

          <div className="col-span-1 md:col-span-5 md:pl-12 text-black">
            <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
              <div className="flex items-center font-extrabold space-x-2">
                <span className="text-black sm:text-lg">
                  {subscription?.plan?.title}
                </span>

                <span className="border-2 tracking-widest rounded bg-zinc-300 text-[1rem] border-zinc-800 px-2">
                  {subscription?.plan?.meta?.resolution?.quality}
                </span>
              </div>

              <LinkButton
                href="/account/payment-plan"
                className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
              >
                Change plan
              </LinkButton>
            </div>

            <LinkButton
              href="/account/payment-plan"
              className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0 sm:hidden !m-0"
            >
              <span>Change plan</span>
              <ChevronRightIcon className="h-5 text-zinc-400" />
            </LinkButton>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:border-b sm:gap-y-6 border bg-white sm:bg-inherit px-4 sm:px-0 pt-2 pb-6">
          <div className="col-span-1 md:col-span-2">
            <h4 className="text-xl text-zinc-500 text-truncate uppercase">
              security & privacy
            </h4>
          </div>

          <div className="col-span-1 md:col-span-5 md:pl-12 text-black">
            <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
              <span className="sm:text-[1rem] max-w-sm text-zinc-700">
                Control access to this account, view the most recently active
                devices and more.
              </span>

              <div className="space-y-2 flex flex-col items-end">
                <LinkButton
                  href="/account/manage-access"
                  className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                >
                  Manage access
                </LinkButton>

                <LinkButton
                  href="/account/profiles-signout"
                  className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                >
                  Sign out of profiles
                </LinkButton>

                <LinkButton
                  href="/account/signout-all"
                  className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                >
                  Sign out all session
                </LinkButton>
              </div>
            </div>

            <div className="sm:hidden !m-0">
              {[
                {
                  title: "Manage access",
                  url: "manage-access",
                },
                {
                  title: "Sign out of profiles",
                  url: "profiles-signout",
                },
                {
                  title: "Sign out all sessions",
                  url: "signout-all",
                },
              ].map((prop, index) => {
                return (
                  <LinkButton
                    state={prop?.state}
                    href={`/account/${prop.url}`}
                    key={`acc_prop_${index}`}
                    className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0"
                  >
                    <span>{prop.title}</span>
                    <ChevronRightIcon className="h-5 text-zinc-400" />
                  </LinkButton>
                );
              })}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:gap-y-6 border bg-white sm:bg-inherit pt-2 pb-6 px-4 sm:px-0">
          <div className="col-span-1 md:col-span-2">
            <h4 className="text-xl text-zinc-500 uppercase">
              PROFILES{/* PROFILE & PARENTAL CONTROLS */}
            </h4>
          </div>

          <div className="col-span-1 md:col-span-5 md:pl-12 text-black space-y-4">
            {profiles?.map &&
              profiles.map((profile) => {
                return (
                  <div
                    key={profile.id}
                    className="flex cursor-pointer items-center justify-between border-b border-zinc-300 pb-4 last:border-0"
                  >
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded"
                        style={{
                          backgroundColor: profile?.avatar?.color,
                        }}
                      >
                        <img
                          src={Images.Profile}
                          alt="profile-icon"
                          width={56}
                          heigh={56}
                          className="profile-img object-contain"
                        />
                      </div>

                      <div className="flex flex-col">
                        <span className="text-[1rem] font-bold">
                          {profile.name}
                        </span>
                        <span className="text-sm text-zinc-500">
                          {profile.is_kid
                            ? "7+ and below"
                            : "All Maturity Ratings"}
                        </span>
                      </div>
                    </div>

                    <ChevronDownIcon className="w-7 sm:w-5 text-zinc-400" />
                  </div>
                );
              })}
          </div>
        </div>
      </ManageAccountLayout>
      {/* <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
        <AccountNavigation bg="black" showButton={false} showProfile={true} />

        <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
          <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
            <div className="w-full space-y-4 max-w-5xl md:px-12 pt-8">
              <div className="sm:border-b sm:flex w-full border-zinc-400 pb-3 space-y-2 space-x-2 sm:space-x-5">
                <h2 className="text-4xl">Account</h2>

                <div className="flex space-x-2 items-center">
                  <div className="relative flex text-xl sm:text-2xl text-primary">
                    <BiPlay className="-rotate-12 border-2 rounded border-primary -translate-y-1 sm:-translate-y-2 -translate-x-1 sm:-translate-x-2" />
                    <BiPlay className="absolute border-2 rounded border-primary left-0 bg-gray-200" />
                  </div>
                  <span className="font-bold text-zinc-600">
                    Member Since{" "}
                    {toDate(user?.created_at, {
                      year: "numeric",
                      month: "long",
                    })}
                  </span>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:border-b sm:gap-y-8 border bg-white sm:bg-inherit px-4 sm:px-0 py-6">
                <div className="col-span-1 md:col-span-2 space-y-3">
                  <h4 className="text-xl text-zinc-500 text-truncate uppercase">
                    Membership & Billing
                  </h4>

                  <CancelButton className="hidden sm:block" />
                </div>

                <div className="col-span-1 md:col-span-5 space-y-3 md:pl-12 text-black">
                  <section
                    id="account"
                    className="border-b border-zinc-300 !pt-0 pb-4 !px-0 space-y-2"
                  >
                    <div className="flex justify-between items-start">
                      <span className="font-extrabold text-black">
                        {user?.phone}
                      </span>

                      <LinkButton
                        href="/account/verify-phone"
                        className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                      >
                        Change phone number
                      </LinkButton>
                    </div>

                    <div className="flex justify-between items-start">
                      <span className="text-zinc-500">
                        Password:{" "}
                        {toArray(8).map((v, i) => {
                          return <span key={`hash_${i}`}>&lowast;</span>;
                        })}
                      </span>

                      <LinkButton
                        href="/account/change-password"
                        className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                      >
                        Change password
                      </LinkButton>
                    </div>

                    <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
                      <span className="text-zinc-500">
                        Email: {user?.email ?? "no email account"}
                      </span>

                      <LinkButton
                        href="email-account"
                        state={{ email: user?.email }}
                        className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                      >
                        {user?.email ? "Change" : "Add"} email account
                      </LinkButton>
                    </div>

                    <div className="sm:hidden !m-0">
                      {[
                        {
                          title: "Change phone",
                          url: "change-phone",
                        },
                        {
                          title: "Change password",
                          url: "change-password",
                        },
                        {
                          title: `${
                            user?.email ? "Change" : "Add"
                          } email account`,
                          url: "email-account",
                          state: { email: user?.email },
                        },
                      ].map((prop, index) => {
                        return (
                          <LinkButton
                            state={prop?.state}
                            href={`/account/${prop.url}`}
                            key={`acc_prop_${index}`}
                            className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0"
                          >
                            <span>{prop.title}</span>
                            <ChevronRightIcon className="h-5 text-zinc-400" />
                          </LinkButton>
                        );
                      })}
                    </div>
                  </section>

                  <section id="payment" className="!pt-0 pb-4 !px-0 space-y-2">
                    <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
                      <div className="flex flex-col space-y-1">
                        <div className="flex space-x-2">
                          {paymentMethod?.card?.icon && paymentMethod.card.icon}

                          <span className="font-extrabold text-black">
                            •••• •••• •••• {paymentMethod?.card?.last_four}
                          </span>
                        </div>

                        <span className="text-zinc-600 text-base lg:text-[1rem]">
                          Your next billing date is{" "}
                          {toDate(subscription?.expires_at, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </span>
                      </div>

                      <div className="space-y-2 flex flex-col items-end">
                        <LinkButton
                          href="/account/payment-info"
                          className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                        >
                          Manage payment info
                        </LinkButton>

                        <LinkButton
                          href="/account/billing-details"
                          className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                        >
                          Billing details
                        </LinkButton>
                      </div>
                    </div>

                    <div className="sm:hidden !m-0">
                      {[
                        {
                          title: "Manage payment info",
                          url: "payment-info",
                        },
                        {
                          title: "Billing details",
                          url: "billing-details",
                        },
                      ].map((prop, index) => {
                        return (
                          <LinkButton
                            state={prop?.state}
                            href={`/account/${prop.url}`}
                            key={`acc_prop_${index}`}
                            className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0"
                          >
                            <span>{prop.title}</span>
                            <ChevronRightIcon className="h-5 text-zinc-400" />
                          </LinkButton>
                        );
                      })}
                    </div>
                  </section>
                </div>

                <div className="col-span-1 sm:hidden px-4">
                  <CancelButton className="w-full !py-3 !text-xl" />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:border-b sm:gap-y-6 border bg-white sm:bg-inherit px-4 sm:px-0 pt-2 pb-6">
                <div className="col-span-1 md:col-span-2">
                  <h4 className="text-xl text-zinc-500 text-truncate uppercase">
                    plan details
                  </h4>
                </div>

                <div className="col-span-1 md:col-span-5 md:pl-12 text-black">
                  <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
                    <div className="flex items-center font-extrabold space-x-2">
                      <span className="text-black sm:text-lg">
                        {subscription?.plan?.title}
                      </span>

                      <span className="border-2 tracking-widest rounded bg-zinc-300 text-[1rem] border-zinc-800 px-2">
                        {subscription?.plan?.meta?.resolution?.quality}
                      </span>
                    </div>

                    <LinkButton
                      href="/account/payment-plan"
                      className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                    >
                      Change plan
                    </LinkButton>
                  </div>

                  <LinkButton
                    href="/account/payment-plan"
                    className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0 sm:hidden !m-0"
                  >
                    <span>Change plan</span>
                    <ChevronRightIcon className="h-5 text-zinc-400" />
                  </LinkButton>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:border-b sm:gap-y-6 border bg-white sm:bg-inherit px-4 sm:px-0 pt-2 pb-6">
                <div className="col-span-1 md:col-span-2">
                  <h4 className="text-xl text-zinc-500 text-truncate uppercase">
                    security & privacy
                  </h4>
                </div>

                <div className="col-span-1 md:col-span-5 md:pl-12 text-black">
                  <div className="flex justify-between items-start border-b border-zinc-300 pb-4 sm:pb-0 sm:border-0">
                    <span className="sm:text-[1rem] max-w-sm text-zinc-700">
                      Control access to this account, view the most recently
                      active devices and more.
                    </span>

                    <div className="space-y-2 flex flex-col items-end">
                      <LinkButton
                        href="/account/manage-access"
                        className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                      >
                        Manage access
                      </LinkButton>

                      <LinkButton
                        href="/account/profiles-signout"
                        className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                      >
                        Sign out of profiles
                      </LinkButton>

                      <LinkButton
                        href="/account/signout-all"
                        className="text-truncate hover:underline text-blue-500 hidden sm:block md:text-[1rem]"
                      >
                        Sign out all session
                      </LinkButton>
                    </div>
                  </div>

                  <div className="sm:hidden !m-0">
                    {[
                      {
                        title: "Manage access",
                        url: "manage-access",
                      },
                      {
                        title: "Sign out of profiles",
                        url: "profiles-signout",
                      },
                      {
                        title: "Sign out all sessions",
                        url: "signout-all",
                      },
                    ].map((prop, index) => {
                      return (
                        <LinkButton
                          state={prop?.state}
                          href={`/account/${prop.url}`}
                          key={`acc_prop_${index}`}
                          className="flex w-full text-black items-center justify-between py-3 border-b border-zinc-300 last:pb-0 last:border-0"
                        >
                          <span>{prop.title}</span>
                          <ChevronRightIcon className="h-5 text-zinc-400" />
                        </LinkButton>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 border-gray-500 sm:border-0 sm:gap-y-6 border bg-white sm:bg-inherit pt-2 pb-6 px-4 sm:px-0">
                <div className="col-span-1 md:col-span-2">
                  <h4 className="text-xl text-zinc-500 uppercase">
                    PROFILE & PARENTAL CONTROLS
                  </h4>
                </div>

                <div className="col-span-1 md:col-span-5 md:pl-12 text-black space-y-4">
                  {profiles?.map &&
                    profiles.map((profile) => {
                      return (
                        <div
                          key={profile.id}
                          className="flex cursor-pointer items-center justify-between border-b border-zinc-300 pb-4 last:border-0"
                        >
                          <div className="flex items-center space-x-4">
                            <div
                              className="rounded"
                              style={{
                                backgroundColor: profile?.avatar?.color,
                              }}
                            >
                              <img
                                src={Images.Profile}
                                alt="profile-icon"
                                width={56}
                                heigh={56}
                                className="profile-img object-contain"
                              />
                            </div>

                            <div className="flex flex-col">
                              <span className="text-[1rem] font-bold">
                                {profile.name}
                              </span>
                              <span className="text-sm text-zinc-500">
                                {profile.is_kid
                                  ? "7+ and below"
                                  : "All Maturity Ratings"}
                              </span>
                            </div>
                          </div>

                          <ChevronDownIcon className="w-7 sm:w-5 text-zinc-400" />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

function CancelButton(props) {
  return (
    <LinkButton
      href="/account/cancel"
      className={`px-8 py-2 text-base shadow-md sm:text-sm text-zinc-700 bg-zinc-300 ${props.className}`}
    >
      Cancel Membership
    </LinkButton>
  );
}

export default Account;
