import { useMemo } from "react";
import { API, toMovie } from "../imports/utils";
const args = {
  type: "any",
  genre: "all",
};

function useBanner(props = { ...args }) {
  props = { ...args, ...props };

  const { data, isLoading } = API.Get({
    key: ["banner-movie", props.type, props.genre],
    path: `movies/banner-movie?type=${props.type}&genre=${props.genre}`,
  });

  const banner = useMemo(() => {
    return toMovie(data?.message ? {} : data);
  }, [data]);

  return { banner, fetching: isLoading };
}

export default useBanner;
