import React from "react";

const args = {
  formRef: null,
  noValidate: true,
  onSubmit: () => {},
  className: "",
};

const Form = (props = { ...args }) => {
  props = { ...args, ...props };

  function onSubmit(e) {
    if (props.noValidate) {
      let valid = true;
      e.preventDefault();
      const form = e.target;

      for (const element of form.elements) {
        const is_required = element.getAttribute("required") !== null;

        if (is_required && (element.value.element <= 0 || !element.value)) {
          valid = false;
          const feedback = element.parentNode.querySelector(".feedback");
          if (feedback) {
            element.classList.add("peer");
            feedback.classList.add("peer-invalid:block");
          }
        }
      }

      if (!valid) {
        return valid;
      }
    }

    props.onSubmit(e);
  }

  return (
    <form
      ref={props?.formRef}
      noValidate={props.noValidate}
      className={props.className}
      onSubmit={onSubmit}
    >
      {props.children}
    </form>
  );
};

export default Form;
