import React, { useState } from "react";
import { LinkButton } from "../../imports/components";
import { BiCheckSquare, BiSquare } from "react-icons/bi";
import { usePost } from "../../imports/hooks";
import { ManageAccountLayout } from "../../imports/layouts";

function SignOutProfiles() {
  const [includeProfile, setIncludeSession] = useState(false);
  const { post } = usePost("accounts/profiles-logout", {
    showProcessing: true,
    toggleOnSuccess: false,
    redirect: true,
    processingText: "Signing out of all profiles...",
  });

  function handleSignOutProfile() {
    post(null, {
      includeProfile,
    });
  }

  return (
    <ManageAccountLayout showReturn={false}>
      <h2 className="text-3xl">Sign Out of Profiles</h2>
      <p className="tracking-normal">
        Are you sure you want to sign out all profiles for this Sierraflixx
        account?
      </p>

      <button
        onClick={() => setIncludeSession((pre) => !pre)}
        className={`flex items-center space-x-1 text-xl text-secondary opacity-${
          includeProfile ? "100" : "50"
        } font-semibold`}
      >
        {includeProfile ? <BiCheckSquare /> : <BiSquare />}

        <span className="text-sm">Include this profile</span>
      </button>

      <div className="flex space-x-2 pt-4">
        <button
          onClick={handleSignOutProfile}
          className="text-xs shadow-md bg-green-500 px-5 py-2 rounded-sm text-white"
        >
          Sign Out Profiles
        </button>
        <LinkButton
          href="/account"
          className="text-xs shadow-md bg-zinc-300 px-5 py-2 rounded-sm"
        >
          Cancel
        </LinkButton>
      </div>
    </ManageAccountLayout>

    // <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
    //   <AccountNavigation bg="black" showButton={false} showProfile={true} />

    //   <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
    //     <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
    //       <div className="w-full space-y-4 max-w-5xl md:px-12 pt-6">
    //         <h2 className="text-3xl">Sign Out of Profiles</h2>
    //         <p className="tracking-normal">
    //           Are you sure you want to sign out all profiles for this
    //           Sierraflixx account?
    //         </p>

    //         <button
    //           onClick={() => setIncludeSession((pre) => !pre)}
    //           className={`flex items-center space-x-1 text-xl text-secondary opacity-${
    //             includeProfile ? "100" : "50"
    //           } font-semibold`}
    //         >
    //           {includeProfile ? <BiCheckSquare /> : <BiSquare />}

    //           <span className="text-sm">Include this profile</span>
    //         </button>

    //         <div className="flex space-x-2 pt-4">
    //           <button
    //             onClick={handleSignOutProfile}
    //             className="text-xs shadow-md bg-green-500 px-5 py-2 rounded-sm text-white"
    //           >
    //             Sign Out Profiles
    //           </button>
    //           <LinkButton
    //             href="/account"
    //             className="text-xs shadow-md bg-zinc-300 px-5 py-2 rounded-sm"
    //           >
    //             Cancel
    //           </LinkButton>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default SignOutProfiles;
