import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { API } from "../../imports/utils";
import { CheckOutForm, Loader } from "../../imports/components";
import { useLocation } from "react-router-dom";

function Card() {
  const { state } = useLocation();
  const [loaded, setLoaded] = useState(false);
  const { data: credential } = API.Get({
    path: `subscriptions/card/init?plan=${state?.plan.id}`,
  });

  return (
    <div className="w-full max-w-xl flex flex-col justify-center items-center py-8 default-padding">
      {!loaded ? (
        <Loader />
      ) : (
        <>
          <small>STEP 3 OF 3</small>
          <h2 className="font-bold mb-4 text-xl sm:text-2xl lg:text-3xl">
            Set up your credit or debit card
          </h2>
        </>
      )}

      {credential?.key && (
        <Elements
          stripe={loadStripe(credential?.key)}
          options={{
            clientSecret: credential?.client,
            appearance: {
              theme: "stripe",
              variables: {
                borderRadius: "0.15rem",
                focusBoxShadow: "none",
                colorPrimary: "#00862d",
              },
            },
          }}
        >
          <CheckOutForm
            region={0}
            data={state}
            setLoaded={(val) => setLoaded(val)}
          />
        </Elements>
      )}
    </div>
  );
}

export default Card;
