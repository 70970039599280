import React from "react";
import { API, toArray } from "../../imports/utils";
import {
  Alert,
  FloatingInput,
  LinkButton,
  SubmitButton,
} from "../../imports/components";
import { useAuth, usePost } from "../../imports/hooks";
import { UserIcon, UserAddIcon } from "@heroicons/react/outline";

function Profiles() {
  const { data: member } = useAuth();
  const { data: profiles } = API.Get({
    path: "accounts/get-max-profiles",
  });

  const { post, processing, state } = usePost("profiles", {
    redirect: true,
    successCodes: [201],
    delay: 4000,
  });

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full md:max-w-3xl lg:max-w-6xl default-padding gap-3">
      <div className="cols-span-1 lg:col-span-2">
        <small>STEP 3 OF 4</small>
        <h2 className="font-semibold text-3xl">
          Who will be watching Sierraflixx?
        </h2>
        <p className="lg:max-w-lg">
          People living in your home can enjoy recommendations tailored to their
          tastes and watching preferences. Great for kids.
        </p>
      </div>

      <div className="col-span-1">
        <form onSubmit={post}>
          <div className="gap-y-6 flex w-full flex-col">
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-black/75">Your profile</p>
              <div className="flex w-full">
                <UserIcon className="w-6" />
                <div className="flex-1">
                  <FloatingInput
                    name="profiles[0][name]"
                    id="profile_0"
                    inputClassName="bg-zinc-200 rounded-[.15rem]"
                    placeholder="Name"
                    defaultValue={
                      member?.first_name &&
                      `${member?.first_name} ${member?.last_name}`
                    }
                  />
                  <input
                    type="hidden"
                    name="profiles[0][master]"
                    defaultValue={"true"}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-black/75">Add profiles</p>
              <div className="flex flex-col gap-y-3">
                {profiles &&
                  toArray(profiles?.max - 1).map((profile, index) => {
                    return (
                      <div
                        key={`profile_${profile}_${index}`}
                        className="flex w-full"
                      >
                        <UserAddIcon className="w-6" />
                        <div className="flex-1">
                          <FloatingInput
                            name={`profiles[${index + 1}][name]`}
                            id={`profile_${index + 1}`}
                            inputClassName="bg-zinc-200 rounded-[.15rem]"
                            placeholder="Name"
                          />
                        </div>
                        <label
                          htmlFor={`kid_${index}`}
                          className="flex items-center space-x-1 pr-2 rounded-r bg-zinc-200 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            defaultValue={"true"}
                            className="accent-emerald-600/60"
                            id={`kid_${index}`}
                            name={`profiles[${index + 1}][is_kid]`}
                          />
                          <span className="text-sm text-black/80">Kid?</span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="sm:pl-5 w-full space-y-4">
              <Alert color="secondary" className="bg-blue-100 w-full">
                Only people who live with you may use your account.{" "}
                <LinkButton
                  href="/account/help"
                  className="text-blue-700 hover:underline"
                >
                  Learn more
                </LinkButton>
              </Alert>

              {state && (
                <Alert>
                  <p
                    className="m-0"
                    dangerouslySetInnerHTML={{ __html: state.message }}
                  ></p>
                </Alert>
              )}

              <SubmitButton isProcessing={processing} className="text-white">
                Next
              </SubmitButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profiles;
