import React from "react";
import { Outlet } from "react-router-dom";
import { AccountNavigation, Footer } from "../imports/components";
import { Images } from "../imports/statics";

const Login = () => {
  return (
    <div className="flex flex-col min-h-screen w-full">
      <AccountNavigation showButton={false} />

      <div className="flex flex-col min-w-full max-w-full min-h-screen relative bg-black sm:!bg-radial-gradient">
        <img
          src={Images.Cover}
          alt="bg_image"
          loading="eager"
          className="absolute -z-10 min-h-full max-h-full min-w-full top-0 object-cover"
        />
        <div className="min-w-full max-w-full flex-1 flex flex-col justify-center items-center pt-20 pb-5">
          <Outlet />
        </div>
        <Footer className="mt-auto sm:bg-black-50 max-w-full" />
      </div>
    </div>
  );
};

export default Login;
