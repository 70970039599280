import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef } from "react";

const args = {
  show: false,
  title: "",
  className: "",
  bg: "bg-black",
  showClose: true,
  setShow: () => {},
  dialogClassName: "",
};

const CommonDialog = (props = { ...args }) => {
  props = { ...args, ...props };
  const dialogRef = useRef(null);

  function hide() {
    props.setShow();
  }

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        onClose={hide}
        initialFocus={dialogRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 ${props.bg} transition-opacity`} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-md ${props.bg} text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-md ${props.dialogClassName} mx-8 lg:mx-0`}
              >
                <div ref={dialogRef} className={props.className}>
                  {props.title.length > 0 && (
                    <div className="flex items-center justify-between w-full py-1 px-3">
                      <h5 className="font-bold text-lg text-inherit">
                        {props?.title}
                      </h5>
                      {props.showClose && (
                        <button onClick={hide} className="rotate-45">
                          <PlusIcon className="w-8" />
                        </button>
                      )}
                    </div>
                  )}

                  <div className="p-3">{props?.children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CommonDialog;
