import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef } from "react";
import { useModalContext } from "../imports/hooks";

const ProcessDialog = () => {
  const dialogRef = useRef(null);
  const { show, content, slug, reference, dispatch } = useModalContext();

  const setOpen = () => {
    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  return (
    <Transition.Root
      show={show && slug?.toLocaleLowerCase() === "processing"}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-[60]"
        onClose={setOpen}
        initialFocus={dialogRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-md">
                <div
                  ref={dialogRef}
                  className="flex items-center justify-center"
                >
                  {content === null ? (
                    <div className="text-center">
                      <div
                        className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {reference?.text}...
                        </span>
                      </div>
                      <p className="text-xl mt-3 font-light animate-pulse">
                        {reference?.text}
                      </p>
                    </div>
                  ) : (
                    show && (
                      <div className="max-w-full flex flex-col items-center py-3 px-6 space-y-1 text-rose-700 bg-rose-200 rounded">
                        <div className="space-x-4 flex items-center w-full">
                          <ExclamationIcon className="w-10" />

                          <p className="text-black text-lg">
                            {content?.message}
                          </p>
                        </div>

                        <button
                          onClick={setOpen}
                          className="text-secondary max-w-fit hover:underline"
                        >
                          Close
                        </button>
                      </div>
                    )
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProcessDialog;
