import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/auth-hook";
import usePost from "../hooks/post-hook";
import OTPInput from "otp-input-react";
import {
  AccountNavigation,
  Alert,
  Footer,
  LinkButton,
  SubmitButton,
} from "../imports/components";
import { Images } from "../imports/statics";

function VerifyOTP() {
  const [code, setCode] = useState("");
  const { state: data } = useLocation();
  const { redirect } = useAuth();

  const { post, processing, state } = usePost("static/verify-otp", {
    delay: 8000,
    setState: true,
    redirect: true,
    toggleOnSuccess: false,
    successCodes: [201],
  });

  const handlePost = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    post(e, data);
  };

  return !data?.message ? (
    <Navigate to={redirect} replace={true} />
  ) : (
    <div className="flex flex-col min-h-screen w-full">
      <AccountNavigation
        bg="transparent"
        className="sm:bg-gradient-top text-primary sm:!border-0"
        useLink={true}
      />

      <div className="flex flex-col min-w-full max-w-full min-h-screen relative bg-white text-black sm:text-white sm:!bg-radial-gradient">
        <img
          src={Images.ForgotPassword}
          alt="bg_image"
          className="absolute -z-10 min-h-full top-0 object-cover max-h-full min-w-full"
        />
        <div className="min-w-full max-w-full flex-1 flex flex-col justify-center items-center pt-20 pb-5">
          <div className="px-4 py-6 sm:px-14 sm:py-12 max-w-[30rem] w-full sm:bg-white text-black">
            <div className="mb-8 text-center">
              <h4 className="text-4xl font-semibold mb-6">Verify OTP</h4>
              <p className="text-gray-600">
                OTP has been sent to{" "}
                <span className="text-black">
                  {`${data?.message?.slice(0, 4)}xxxx${data?.message?.slice(
                    data?.message?.length - 2
                  )}`}
                </span>
                <br />
                It is <span className="text-black">valid for 15 minutes.</span>
              </p>
            </div>

            {state && (
              <Alert color="rose" className="mb-2">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: state.message }}
                ></p>
              </Alert>
            )}

            <form onSubmit={handlePost} className="space-y-6">
              <input type="hidden" name="otp" defaultValue={code} />

              <OTPInput
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                value={code}
                onChange={setCode}
                className="min-w-full h-12 space-x-1 sm:space-x-2 justify-between"
                inputClassName="border flex-1 !mr-0 text-3xl min-h-full border-black rounded"
              />

              <div className="text-center flex flex-col">
                <span>Don't have received OTP?</span>
                <LinkButton href={-1} className="font-bold text-secondary">
                  Resend OTP
                </LinkButton>
              </div>

              <SubmitButton
                disabled={code?.length < 6}
                isProcessing={processing}
                className="border w-full border-primary px-3 py-1 rounded bg-primary text-white"
              >
                Submit
              </SubmitButton>
            </form>
          </div>
        </div>
        <Footer className="mt-auto sm:bg-black-50 max-w-full" />
      </div>
    </div>
  );
}

export default VerifyOTP;
