import React from "react";
import { useAuth, useSignOut } from "../../imports/hooks";
import { ProfileDropdown } from "../../imports/components";
import { Images } from "../../imports/statics";
import LinkButton from "./LinkButton";

const args = {
  position: "absolute",
  showButton: true,
  bg: "gradient-top",
  useLink: false,
  className: "",
  showProfile: false,
};

const AccountNavigation = (props = { ...args }) => {
  props = { ...args, ...props };
  const { authenticated, data: user, redirect } = useAuth();
  const { signOut } = useSignOut();

  const handleClick = () => {
    if (authenticated) {
      signOut();
    }
  };

  return (
    <nav
      className={`${props.position} py-2 bg-${props.bg} ${props.className} ${
        props.useLink && "border-b"
      }`}
    >
      <LinkButton as="a" href={user?.profile ? redirect : "/"}>
        <img
          src={Images.Logo}
          alt="app_logo"
          width={174}
          loading="eager"
          className="cursor-pointer object-contain max-h-min"
        />
      </LinkButton>

      {props.showButton && (
        <LinkButton
          type="button"
          href={authenticated ? null : "/login"}
          as={authenticated ? "button" : "a"}
          onClick={handleClick}
          replaceClick={authenticated}
          className={`${
            props.useLink
              ? "text-inherit hover:underline text-[1rem]"
              : "bg-primary px-4 py-[.3rem] rounded-[.15rem] text-md"
          } font-semibold text-truncate tracking-wider transition-all min-w-fit`}
        >
          Sign {authenticated ? "Out" : "In"}
        </LinkButton>
      )}

      {props.showProfile && <ProfileDropdown showIcons={false} />}
    </nav>
  );
};

export default AccountNavigation;
