import React, { useMemo } from "react";
import { CheckIcon, LockClosedIcon } from "@heroicons/react/solid";
import { Navigate, useLocation } from "react-router-dom";
import { LinkButton, PaymentMethods } from "../../imports/components";

function ChoosePaymentMethod() {
  const { state } = useLocation();
  const plan = useMemo(() => state?.plan, [state]);

  const getWatchOn = () => {
    const devices = plan?.meta?.devices || [];

    let str = devices
      ?.map((d) => d.name)
      .join(devices?.length > 2 ? ", " : " or ");

    if (devices.length > 2) {
      const last = devices[devices.length - 1];
      str = str.replace(last?.name, `and ${last?.name}`);
    }

    return str?.toLowerCase();
  };

  return !plan?.meta ? (
    <Navigate to="/signup/plan" />
  ) : (
    <div className="flex items-center flex-col w-full max-w-md default-padding sm:px-0 sm:py-8">
      <LockClosedIcon className="w-16 text-primary" />
      <p className="mt-4 mb-0">
        <small>STEP 3 OF 3</small>
      </p>
      <h2 className="font-bold text-3xl">Choose how to pay.</h2>
      <p className="text-xl">
        You're getting the{" "}
        <small className="font-semibold">{plan?.title} Plan</small>
      </p>

      <div
        direction="horizontal"
        className="flex min-w-full p-3 bg-gray-200 justify-between text-start mt-5"
      >
        <span className="text-xl fw-bold">
          <span className="font-semibold">${plan?.price?.amount}</span>
          <small>/{plan?.price?.period?.toLowerCase()}</small>
        </span>
        <LinkButton
          href="/signup/plan/choose"
          state={state}
          className="font-bold text-primary hover:underline"
        >
          Change
        </LinkButton>
      </div>

      <div className="flex flex-col justify-center mt-3 text-xl mb-5 items-start ps-5">
        <div
          direction="horizontal"
          className="flex justify-start items-center w-full"
        >
          <CheckIcon className="w-7 text-primary" />
          <small>Unlimited TV shows and movies</small>
        </div>

        <div
          direction="horizontal"
          className="flex justify-start items-center w-full"
        >
          <CheckIcon className="w-7 text-primary" />
          <small>
            {plan.meta?.["video-quality"]} video quality in{" "}
            {plan.meta?.resolution?.dimension} and{" "}
            {plan.meta?.resolution?.quality}
          </small>
        </div>

        <div
          direction="horizontal"
          className="flex justify-start items-center w-full"
        >
          <CheckIcon className="w-7 text-primary" />
          <small>
            Watch on {getWatchOn()}
            {plan.meta?.devices?.length < 2 && " only"}
          </small>
        </div>

        <div
          direction="horizontal"
          className="flex justify-start items-center w-full"
        >
          <CheckIcon className="w-7 text-primary" />
          <small>Cancel easily online anytime</small>
        </div>
      </div>

      <PaymentMethods state={state} root="/signup/payments" />
    </div>
  );
}

export default ChoosePaymentMethod;
