import React, { useState, useEffect } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { usePost } from "../../imports/hooks";
import {
  Form,
  SubmitButton,
  LinkButton,
  Alert,
  PasswordInput,
  FloatingInput,
} from "../../imports/components";
import { useMemo } from "react";
import { ManageAccountLayout } from "../../imports/layouts";

function AddEditEmail() {
  const { state: data = {} } = useLocation();
  const [status, setStatus] = useState(null);
  const hasEmail = useMemo(() => {
    if (data) {
      return data.email !== null;
    }

    return false;
  }, [data]);

  const { post, processing, state } = usePost(`accounts/email-account`, {
    delay: 8000,
    redirect: false,
    toggleOnSuccess: true,
  });

  useEffect(() => {
    if (state && state?.message) {
      setStatus(state);
    }
  }, [state]);

  return (
    <ManageAccountLayout className="max-w-lg">
      {status && status.success ? (
        <div className="flex flex-col items-center justify-center bg-white shadown-md px-3 py-5 rounded-md">
          <BiCheckDouble className="text-primary rounded-full border-primary border-4 p-1 text-6xl" />
          <div className="mt-6 text-center">
            <p className="text-xl font-semibold">{status?.message}</p>
            <p className="mb-12">
              Signing in with email will require your new email
            </p>

            <LinkButton
              href="/account"
              state={data?.uid}
              className="text-sm uppercase border px-4 py-2 border-secondary font-semibold bg-secondary text-white rounded"
            >
              return to account
            </LinkButton>
          </div>
        </div>
      ) : (
        <>
          <div className="py-3">
            <h4 className="font-extrabold text-2xl">
              {hasEmail ? "Change" : "Add"} Email
            </h4>
            <p className="text-base">
              {hasEmail
                ? "Complete the field to change your email account"
                : "Add an email account to enable email access"}
            </p>
          </div>

          {state && (
            <Alert color="rose" className="mb-2">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: state.message }}
              ></p>
            </Alert>
          )}

          <Form onSubmit={post} noValidate={true} className="space-y-4">
            <div className="flex space-y-2 flex-col">
              {hasEmail && (
                <FloatingInput
                  required={true}
                  type="email"
                  autoFocus={true}
                  inputClassName="bg-zinc-200 border border-gray-300 focus-within:border-primary"
                  id="old-email"
                  name="old_email"
                  feedback="Enter a valid email address"
                  defaultValue={data?.email}
                  placeholder="Enter old email account"
                />
              )}

              <FloatingInput
                required={true}
                type="email"
                autoFocus={true}
                inputClassName="bg-zinc-200 border border-gray-300 focus-within:border-primary"
                id="new-email"
                name="new_email"
                feedback="Enter a valid email address"
                placeholder="Enter new email account"
              />

              <PasswordInput
                type="email"
                required={true}
                inputClassName="bg-zinc-200 border border-gray-300 focus-within:border-primary"
                placeholder="Enter account password"
              />
            </div>

            <SubmitButton isProcessing={processing} className="text-white py-3">
              Submit
            </SubmitButton>
          </Form>
        </>
      )}
    </ManageAccountLayout>
    // <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
    //   <AccountNavigation bg="black" showButton={false} showProfile={true} />

    //   <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
    //     <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
    //       <div className="w-full space-y-4 max-w-lg md:px-12 pt-8">
    //         {status && status.success ? (
    //           <div className="flex flex-col items-center justify-center bg-white shadown-md px-3 py-5 rounded-md">
    //             <BiCheckDouble className="text-primary rounded-full border-primary border-4 p-1 text-6xl" />
    //             <div className="mt-6 text-center">
    //               <p className="text-xl font-semibold">{status?.message}</p>
    //               <p className="mb-12">
    //                 Signing in with email will require your new email
    //               </p>

    //               <LinkButton
    //                 href="/account"
    //                 state={data?.uid}
    //                 className="text-sm uppercase border px-4 py-2 border-secondary font-semibold bg-secondary text-white rounded"
    //               >
    //                 return to account
    //               </LinkButton>
    //             </div>
    //           </div>
    //         ) : (
    //           <>
    //             <ReturnToAccount />

    //             <div className="py-3">
    //               <h4 className="font-extrabold text-2xl">
    //                 {hasEmail ? "Change" : "Add"} Email
    //               </h4>
    //               <p className="text-base">
    //                 {hasEmail
    //                   ? "Complete the field to change your email account"
    //                   : "Add an email account to enable email access"}
    //               </p>
    //             </div>

    //             {state && (
    //               <Alert color="rose" className="mb-2">
    //                 <p
    //                   className="m-0"
    //                   dangerouslySetInnerHTML={{ __html: state.message }}
    //                 ></p>
    //               </Alert>
    //             )}

    //             <Form onSubmit={post} noValidate={true} className="space-y-4">
    //               <div className="flex space-y-2 flex-col">
    //                 {hasEmail && (
    //                   <FloatingInput
    //                     required={true}
    //                     type="email"
    //                     autoFocus={true}
    //                     inputClassName="bg-zinc-200 border border-gray-300 focus-within:border-primary"
    //                     id="old-email"
    //                     name="old_email"
    //                     feedback="Enter a valid email address"
    //                     defaultValue={data?.email}
    //                     placeholder="Enter old email account"
    //                   />
    //                 )}

    //                 <FloatingInput
    //                   required={true}
    //                   type="email"
    //                   autoFocus={true}
    //                   inputClassName="bg-zinc-200 border border-gray-300 focus-within:border-primary"
    //                   id="new-email"
    //                   name="new_email"
    //                   feedback="Enter a valid email address"
    //                   placeholder="Enter new email account"
    //                 />

    //                 <PasswordInput
    //                   type="email"
    //                   required={true}
    //                   inputClassName="bg-zinc-200 border border-gray-300 focus-within:border-primary"
    //                   placeholder="Enter account password"
    //                 />
    //               </div>

    //               <SubmitButton
    //                 isProcessing={processing}
    //                 className="text-white py-3"
    //               >
    //                 Submit
    //               </SubmitButton>
    //             </Form>
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default AddEditEmail;
