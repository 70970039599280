import { Dialog, Transition } from "@headlessui/react";
import { PlayIcon, PlusIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef, useState } from "react";
import { useAuth, useModalContext, useWatched } from "../imports/hooks";
import { API, baseURL, toDuration } from "../imports/utils";
import { MovieBanner, PlayMovieButton } from "../imports/components";

const MovieInfoDialog = () => {
  const { data: user = {} } = useAuth();
  const cancelButtonRef = useRef(null);
  const [playerRef, setPlayerRef] = useState({});
  const { show, content: movie, slug, reference, dispatch } = useModalContext();
  const { data: related = [] } = API.Get(
    {
      key: movie?.id,
      path: `movies/related?movie=${movie?.id}`,
    },
    show && slug === "movie-info"
  );
  const { data: episodes = [] } = API.Get(
    {
      key: movie?.id,
      path: `movies/episodes?movie=${movie?.id}`,
    },
    show && movie?.of_type === "series" && slug === "movie-info"
  );
  const { watched } = useWatched({ movieId: movie?.id, enabled: show });

  const setOpen = () => {
    if (user?.profile?.autoplay_browsing) {
      const sameSource = reference?.current?.src === playerRef?.current?.src;

      if (sameSource && reference?.current?.paused) {
        reference.current.currentTime = playerRef.current.currentTime;
      }
    }

    dispatch({
      type: "HIDE",
      payload: {},
    });
  };

  return (
    <Transition.Root show={show && slug === "movie-info"} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#111b] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-4xl border border-[#161616f6]">
                <MovieBanner
                  movie={{ ...movie, is_watched: watched }}
                  shown={show}
                  reference={reference}
                  setState={setPlayerRef}
                />

                <button
                  onClick={setOpen}
                  className="absolute text-shadow-md opacity-75 hover:opacity-100 top-2 right-2 rounded-button text-lg"
                >
                  <PlusIcon className="rotate-45" />
                </button>

                <div className="p-4 sm:px-8 sm:py-6 md:px-12 md:py-8 space-y-8">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-0 gap-y-6 sm:gap-x-4 sm:gap-y-0">
                    <div className="cols-span-1 sm:col-span-2 space-y-8">
                      <div className="flex text-sm sm:text-md md:text-lg text-shadow-md items-center space-x-3 font-semibold">
                        <div className="text-primary">
                          {related?.match}% Match
                        </div>
                        <div className="text-[#e5e5e5]">
                          {movie?.year_of_release}
                        </div>
                        <div className="border border-gray-400 text-gray-400 px-3 py-0.5 text-sm">
                          {movie?.maturity?.age}+
                        </div>
                        <div className="">
                          {toDuration(movie?.duration).words}
                        </div>
                        <div className="border border-gray-400 text-gray-400 px-1 rounded text-sm">
                          HD
                        </div>
                      </div>

                      <p className="max-w-md text-shadow-md text-sm font-light sm:text-md">
                        {movie?.extract}
                      </p>
                    </div>
                    <div className="sm:col-span-1 space-y-3 sm:space-y-6 max-w-full">
                      <div className="flex items-start text-shadow-md space-x-2">
                        <span className="text-gray-500">Cast: </span>
                        <span className="text-md sm:text-base">
                          {movie?.casts?.map((c) => c.name)?.join(", ")}
                        </span>
                      </div>
                      <div className="flex text-shadow-md space-x-2">
                        <span className="text-gray-500">Genres: </span>
                        <span className="text-md sm:text-base">
                          {movie?.tags?.map((tag) => tag.title)?.join(", ")}
                        </span>
                      </div>
                    </div>
                  </div>

                  {movie?.of_type === "series" && (
                    <div id="related" className="space-y-8">
                      <h4 className="font-bold sm:text-xl md:text-2xl">
                        Episodes
                      </h4>

                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4">
                        {episodes?.map((episode) => {
                          return (
                            <div
                              key={`episode_${episode.id}`}
                              className="col-span-1 group bg-[#141414] rounded border border-[#161616f6]"
                            >
                              <div className="rounded">
                                <div className="relative cursor-pointer">
                                  <img
                                    src={`${baseURL}images/${episode.poster}`}
                                    alt={`episode_${episode?.id}`}
                                    className="object-fill rounded-t w-full"
                                  />
                                  <PlayMovieButton
                                    movieId={episode.id}
                                    className="absolute top-0 left-0 opacity-0 hover:opacity-100 group-hover:opacity-100 transition duration-200 rounded-button !border-0 h-full w-full justify-center !rounded-none focus:!rounded-none active:!rounded-none flex items-center group-hover:bg-[#14141444]"
                                    removeDefaultClass={true}
                                  >
                                    <PlayIcon className="h-[3.5rem] w-[3.5rem] text-gray-200 text-shadow-md" />
                                  </PlayMovieButton>
                                </div>
                              </div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-3">
                                  <span className="border px-3">
                                    {movie?.maturity?.age}+
                                  </span>
                                  <span className="font-semibold">
                                    {movie?.year_of_release}
                                  </span>
                                </div>

                                <p className="text-sm sm:text-base">
                                  {episode?.extract?.split(".")[0]}...
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div id="related" className="space-y-8">
                    <h4 className="font-bold sm:text-xl md:text-2xl">
                      More Like This
                    </h4>

                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4">
                      {related.data?.map((related) => {
                        return (
                          <div
                            key={`related_${related.id}`}
                            className="col-span-1 group bg-[#141414] rounded border border-[#161616f6]"
                          >
                            <div className="rounded">
                              <div className="relative cursor-pointer">
                                <img
                                  src={baseURL + related.poster}
                                  alt={`related_${related?.id}`}
                                  className="object-fill rounded-t w-full"
                                />
                                <PlayMovieButton
                                  movieId={related?.id}
                                  className="absolute top-0 left-0 opacity-0 hover:opacity-100 group-hover:opacity-100 transition duration-200 rounded-button !border-0 h-full w-full justify-center !rounded-none focus:!rounded-none active:!rounded-none flex items-center group-hover:bg-[#14141444]"
                                  removeDefaultClass={true}
                                >
                                  <PlayIcon className="h-[3.5rem] w-[3.5rem] text-gray-200 text-shadow-md" />
                                </PlayMovieButton>
                              </div>
                            </div>
                            <div className="p-4">
                              <div className="flex items-center space-x-2 mb-3">
                                <span className="border px-3">
                                  {movie?.maturity?.age}+
                                </span>
                                <span className="font-semibold">
                                  {movie?.year_of_release}
                                </span>
                              </div>

                              <p className="text-sm sm:text-base">
                                {related?.extract?.split(".")[0]}...
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <section id="about">
                    <h4 className="font-bold sm:text-xl md:text-2xl mb-3">
                      About {movie?.title?.text}
                    </h4>

                    <div className="space-y-2">
                      <div className="flex space-x-2">
                        <span className="text-gray-400">Director:</span>
                        <span>
                          {
                            movie?.casts?.find((c) => c.is_director === "true")
                              ?.name
                          }
                        </span>
                      </div>
                      <div className="flex space-x-2">
                        <span className="text-gray-400">Casts:</span>
                        <span>
                          {movie?.casts
                            ?.filter((c) => c.is_director !== "true")
                            ?.map((c) => c.name)
                            ?.join(", ")}
                        </span>
                      </div>
                      <div className="flex space-x-2">
                        <span className="text-gray-400">Genres:</span>
                        <span>
                          {movie?.tags?.map((tag) => tag.title)?.join(", ")}
                        </span>
                      </div>
                      <div className="flex space-x-2">
                        <span className="text-gray-400">Maturity:</span>
                        <p>
                          <span className="border px-2 text-sm !pb-0 text-gray-400 border-gray-400">
                            {movie?.maturity?.age}+
                          </span>{" "}
                          <span>
                            {movie?.maturity?.reasons?.join(", ")}: Recommended
                            for ages {movie?.maturity?.age} and up
                          </span>
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MovieInfoDialog;
