import React, { useMemo } from "react";
import {
  AccountNavigation,
  LinkButton,
  Footer,
  ReturnToAccount,
} from "../../imports/components";
import {
  BiArrowBack,
  BiArrowToLeft,
  BiCheck,
  BiCircle,
  BiLeftArrow,
  BiLeftArrowAlt,
  BiTime,
} from "react-icons/bi";
import {
  BsTv,
  BsLaptop,
  BsPeople,
  BsArrowLeft,
  BsCircleFill,
} from "react-icons/bs";
import { API } from "../../imports/utils";
import { useAuth, usePost } from "../../imports/hooks";
import { SiSpringsecurity } from "react-icons/si";
import { ManageAccountLayout } from "../../imports/layouts";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import Flag from "react-world-flags";

const BillingActivity = () => {
  const { data: auth } = useAuth();
  const { data: sessions } = API.Get({
    path: "static/sessions",
    key: "sessions",
  });
  const { post } = usePost("profiles/signout", {
    toggleOnSuccess: false,
    showProcessing: true,
    clientKey: "sessions",
  });

  function handleSignOut(session) {
    const data = new FormData();
    data.append("id", session.id);

    post(null, data);
  }

  return (
    <>
      <ManageAccountLayout className="max-w-4xl !space-y-6">
        <div className="flex flex-col items-center justify-center space-y-4">
          <SiSpringsecurity className="text-6xl hidden sm:block sm:text-8xl text-blue-500 stroke-[.3] stroke-rose-500" />

          <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold">
            Manage Sessions and Access
          </h2>
          <p className={"sm:px-8 text-sm sm:text-lg lg:text-xl sm:text-center"}>
            See the most recently active sessions on this account. You can sign
            out any unfamiliar sessions or{" "}
            <LinkButton href={"/account/change-password"} className="underline">
              change your password
            </LinkButton>{" "}
            for added security.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {sessions?.map &&
            sessions.map((session, index) => (
              <div
                key={`session${index}`}
                className="col-span-1 flex flex-col space-y-2 shadow-md bg-zinc-100 p-4 rounded"
              >
                <div className="flex justify-between items-center pb-2 border-b border-zinc-300">
                  <div className="flex space-x-2 text-lg items-center">
                    <Flag
                      code={session.geo_location.countryCode}
                      className="h-5 object-fill"
                    />
                    <span className="text-secondary">
                      {session.geo_location.country}
                    </span>
                  </div>
                  {session.is_current ? (
                    <div className="relative">
                      <BsCircleFill className="text-xl text-primary" />
                      <BsCircleFill className="absolute left-0 top-0 text-xl text-primary animate-ping" />
                    </div>
                  ) : (
                    <button
                      onClick={() => handleSignOut(session)}
                      className="rounded border-secondary border px-2 py-1 hover:bg-zinc-200"
                    >
                      Sign Out
                    </button>
                  )}
                </div>

                <div className="flex space-x-1 items-center">
                  <span className="font-semibold">
                    {session.profile_name ?? "No profile"}
                  </span>
                  <span>({session.geo_location.city})</span>
                </div>

                <div className="flex space-x-1 items-center text-zinc-500">
                  <BiTime className="text-lg" />
                  <span>{session.last_accessed_at ?? session.created_at}</span>
                </div>
              </div>
            ))}
        </div>
      </ManageAccountLayout>

      {/* <div className="flex flex-col min-h-screen w-full bg-gray-200 justify-center">
        <AccountNavigation bg="black" showButton={false} showProfile={true} />

        <div className="animate__animated animate__fadeIn flex flex-col min-w-full max-w-full min-h-screen text-black">
          <div className="min-w-full max-w-sm flex-1 flex flex-col items-center pt-20 pb-5 space-y-4 px-4">
            <div className="w-full space-y-4 max-w-5xl md:px-12 pt-8">
              <ReturnToAccount />

              <div className="flex flex-col items-center justify-center space-y-4">
                <SiSpringsecurity className="text-6xl sm:text-8xl text-blue-500 stroke-[.3] stroke-rose-500" />

                <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold">
                  Manage Sessions and Access
                </h2>
                <p
                  className={
                    "sm:px-8 text-sm sm:text-lg lg:text-xl text-center"
                  }
                >
                  See the most recently active devices on this account. You can
                  sign out any unfamiliar devices or{" "}
                  <LinkButton href={"/change-password"} className={"underline"}>
                    change your password
                  </LinkButton>{" "}
                  for added security.
                </p>
              </div>

              <div>
                {sessions?.map &&
                  sessions.map((session, index) => (
                    <div key={`session${index}`}>
                      {session.geo_location.city}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default BillingActivity;
