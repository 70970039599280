import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/auth-hook";
import usePost from "../hooks/post-hook";
import {
  AccountNavigation,
  Alert,
  Footer,
  LinkButton,
  PasswordInput,
  SubmitButton,
} from "../imports/components";
import { Images } from "../imports/statics";

const args = {
  data: {
    auth: {},
    message: "",
  },
};

function ResetPassword(props = { ...args }) {
  const { data } = { ...args, ...props };

  const { authenticated, redirect } = useAuth();

  const { post, processing, state, toggle } = usePost(
    "accounts/reset-password",
    {
      delay: 8000,
      setState: true,
      redirect: true,
      toggleOnSuccess: false,
      successCodes: [201],
    }
  );

  const handleReset = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (formData.get("password") !== formData.get("confirm")) {
      toggle(
        {
          success: false,
          message: "Passwords do not match",
        },
        true
      );

      return;
    }

    formData.delete("confirm");

    post(e, formData);
  };

  return authenticated ? (
    <Navigate to={redirect} replace={true} />
  ) : (
    <div className="flex flex-col min-h-screen w-full">
      <AccountNavigation
        bg="transparent"
        className="sm:bg-gradient-top text-primary sm:!border-0"
        useLink={true}
      />

      <div className="flex flex-col min-w-full max-w-full min-h-screen relative bg-white text-black sm:text-white sm:!bg-radial-gradient">
        <img
          src={Images.ForgotPassword}
          alt="bg_image"
          className="absolute -z-10 min-h-full top-0 object-cover max-h-full min-w-full"
        />
        <div className="min-w-full max-w-full flex-1 flex flex-col justify-center items-center pt-20 pb-5">
          <div className="px-4 py-6 sm:px-14 sm:py-12 max-w-[30rem] w-full sm:bg-white text-black">
            {data?.message === "Reset" ? (
              <div className="flex flex-col items-center justify-center">
                <BiCheckCircle className="text-primary text-8xl" />
                <div className="mt-6 text-center">
                  <p className="text-xl font-semibold">
                    Password reset successfully
                  </p>
                  <p className="mb-12">
                    Signing in will require your new password
                  </p>

                  <LinkButton
                    href="/login"
                    state={data?.uid}
                    className="text-sm uppercase border px-4 py-2 border-secondary font-semibold bg-secondary text-white rounded"
                  >
                    go to sign in
                  </LinkButton>
                </div>
              </div>
            ) : (
              <>
                <div className="mb-12">
                  <h4 className="text-4xl font-semibold mb-2">
                    Reset Password
                  </h4>
                  <p className="text-gray-600">Create a new password</p>
                </div>

                {state && (
                  <Alert color="rose" className="mb-2">
                    <p
                      className="m-0"
                      dangerouslySetInnerHTML={{ __html: state.message }}
                    ></p>
                  </Alert>
                )}

                <form onSubmit={handleReset}>
                  <input type="hidden" name="token" defaultValue={data?.auth} />

                  <div className="space-y-4 mb-12">
                    <PasswordInput
                      name="password"
                      placeholder="New password"
                      className="border border-gray-500"
                      required
                    />
                    <PasswordInput
                      name="confirm"
                      id="confirm"
                      placeholder="Confirm new password"
                      className="border border-gray-500"
                      required
                    />
                  </div>

                  <SubmitButton
                    isProcessing={processing}
                    className=" text-white"
                  >
                    Submit
                  </SubmitButton>
                </form>
              </>
            )}
          </div>
        </div>
        <Footer className="mt-auto sm:bg-black-50 max-w-full" />
      </div>
    </div>
  );
}

export default ResetPassword;
