import Index from "../pages/Index";
import SignIn from "../pages/SignIn";
import NotFound from "../pages/NotFound";
import VerifyOTP from "../pages/VerifyOTP";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";

import Watch from "../pages/browse/Watch";
import Movies from "../pages/browse/Movies";
import Browse from "../pages/browse/Browse";
import Search from "../pages/browse/Search";
import ChooseProfile from "../pages/browse/ChooseProfile";

import PlanIntro from "../pages/signups/PlanIntro";
import ChoosePlan from "../pages/signups/ChoosePlan";
import SignUpInto from "../pages/signups/SignUpIntro";

import RegisterPhoneEmail from "../pages/signups/RegisterPhoneEmail";
import ChoosePaymentMethod from "../pages/signups/ChoosePaymentMethod";
import CreateVerifyPassword from "../pages/signups/CreateVerifyPassword";

import Pay from "../pages/payments/card/Pay";
import CardPayment from "../pages/payments/Card";
import ReviewPayment from "../pages/payments/Review";
import OrangePayment from "../pages/payments/OrangeMoney";

import Subscription from "../pages/account/Subscription";

import Profiles from "../pages/profiles/Profiles";
import ManageProfiles from "../pages/profiles/ManageProfiles";

import Account from "../pages/account/Account";
import CreateProfiles from "../pages/account/Profiles";
import VerifyPhone from "../pages/account/VerifyPhone";
import ChangePhone from "../pages/account/ChangePhone";
import ChangePassword from "../pages/account/ChangePassword";
import PaymentInfo from "../pages/account/PaymentInfo";
import PaymentPlan from "../pages/account/PaymentPlan";
import BillingDetails from "../pages/account/BillingDetails";
import ManageAccess from "../pages/account/ManageAccess";
import AddEditEmail from "../pages/account/AddEditEmail";
import SignOutAll from "../pages/account/SignOutAll";
import SignOutProfiles from "../pages/account/SignOutProfiles";
import ChoosePayment from "../pages/account/AddPaymentMethod";
import RestartMembership from "../pages/account/RestartMembership";
import CancelSubscription from "../pages/account/CancelSubscription";

const Pages = {
  Pay,
  Index,
  Watch,
  SignIn,
  Browse,
  Movies,
  Profiles,
  Account,
  Search,
  PlanIntro,
  NotFound,
  PaymentPlan,
  VerifyOTP,
  ChoosePlan,
  SignOutAll,
  AddEditEmail,
  ChoosePayment,
  ManageAccess,
  CreateProfiles,
  BillingDetails,
  PaymentInfo,
  SignUpInto,
  CardPayment,
  ChangePhone,
  VerifyPhone,
  Subscription,
  SignOutProfiles,
  ResetPassword,
  ChangePassword,
  ChooseProfile,
  ManageProfiles,
  ReviewPayment,
  OrangePayment,
  ForgotPassword,
  RegisterPhoneEmail,
  CancelSubscription,
  ChoosePaymentMethod,
  RestartMembership,
  CreateVerifyPassword,
};

export default Pages;
