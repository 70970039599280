import React from "react";
import { NextButton } from "../../imports/components";
import { Images } from "../../imports/statics";

function SignUpIntro() {
  return (
    <div className="max-w-sm flex w-full justify-center items-center flex-col text-center px-4">
      <div className="relative">
        <div className="img-cover">
          <img
            src={Images.SDevice}
            alt="devices"
            className="object-fill"
            width={260}
          />
        </div>
      </div>

      <p className="mt-4 mb-0">
        <small>STEP 1 OF 3</small>
      </p>

      <h2 className="font-bold text-3xl sm:text-4xl mb-3">
        Start by setting up your account
      </h2>

      <p className="text-xl mb-10">
        Sierraflixx is personalized for you. <br />
        Create a password to watch on any device at any time.
      </p>

      <NextButton href="/signup/registration" />
    </div>
  );
}

export default SignUpIntro;
